import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

export default {
    state() {
        return {
            calendarEvents: [],
            workload_name: '',
            username: '',
            isLoading: false,
        }
    },
    mutations,
    actions,
    getters,
};
