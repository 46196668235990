import gitCoreApi from '@/api/gitCoreApi' 
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

/* eslint-disable */
export default {
    async obtenerAreasMonitor(context,payload) {      
        context.commit('setLoading',true)

        const { status, data } = await gitCoreApi.post('/reports/route_monitor/get_routes',{}, {
            headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        // Se maneja el caso de error en la petición
        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading',false)
            throw error
        }
        
        const { rutas, zonas, resumen, tiposTrabajo, ordenes } = data
        context.commit('setZonasMonitoreo',zonas || [])
        context.commit('setRutasMonitoreo',rutas || [])
        context.commit('setResumenMonitoreo',resumen || [])
        context.commit('setTiposTrabajoMonitoreo',tiposTrabajo || [])//ordenesMonitoreo
        context.commit('setOrdenesMonitoreo',ordenes || [])

        context.commit('setLoading',false)
    },
    async seleccionarZonaMonitor(context,payload){
        context.commit('setZonaSeleccionadaMonitoreo',payload)
    },
    async cambiaZona(context, payload){
        const { token, taskName, old_zone, new_zone } = payload
        const { status, data } = await gitCoreApi.post('/reports/route_monitor/change_zone',
            {
                taskName,
                old_zone,
                new_zone
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            }
        )

        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading',false)
            throw error
        }else{
            return data
        }
    },
    async editarPuntoMonitor(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('reports/route_monitor/update_gps',
                {
                    "idPoint": payload.id,
                    "latitude": payload.latitud,
                    "longitude": payload.longitud,
                    "isForceLocation": payload.isForceLocation
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la orden')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerAreasMonitor', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async editarHora(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('reports/route_monitor/update_hour',
                {
                    "idPoint": payload.id,
                    "startHour": payload.startHour
                }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al actualizar la hora')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            
            return mensaje
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    async reRuteoMonitoreo(context,payload){
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('reports/route_monitor/re_route', {
                "fkTask": payload.fkTarea
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al actualizar la hora')
                context.commit('setLoading', false)
                throw error
            }

            await context.dispatch('obtenerAreasMonitor', payload)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    async reasignacionMasivaMonitoreo(context,payload){
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('reports/route_monitor/massive_reassignment', {
                "reassignment":payload.reasignacion,
                "type": payload.tipo
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al actualizar la hora')
                context.commit('setLoading', false)
                throw error
            }

            context.commit('setDataConfirmacion', data)
            
            return data
        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }finally{
            context.commit('setLoading', false)
        }
    },
    async confirmarReasignacionMasivaMonitoreo(context,payload){
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('reports/route_monitor/confirm_massive_reassignment', {
                "data":payload.data
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al actualizar la hora')
                context.commit('setLoading', false)
                throw error
            }

            await context.dispatch('obtenerAreasMonitor', payload)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    async obtenerLogRuta(context, payload) {
        try {

            const { status, data } = await gitCoreApi.post('reports/route_monitor/get_log_route',{
                "fechaUno":payload.fechaUno,
                "fechaDos":payload.fechaDos
            },{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListarLog', data)

        } catch (error) {
            console.error(error)
            throw error
        } 
    },
    async cerrarMapa(context, payload) {
        context.commit('setCerrarMapa', payload)
    },
    async cerrarOrdenes(context, payload){
        context.commit('setCerrarOrdenes',payload)
    },
    async creaXlsOrdenes(context, payload){
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('reports/route_monitor/ordenes_xlsx', {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            descargaBaseSesentaycuatro(data, 'Ordenes', 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading', false)
        }
    },
    async obtenerAlertasLog(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('reports/route_monitor/get_log_route_alert', {
                types: payload.types,
                status: payload.status,
                start: payload.startDate,
                end: payload.endDate,
                user: payload.user
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            if (!data.isError) {
                return data
            }
        } catch (error) {
            throw new Error(error)
        }
    },
    async cambiaStatusDeLogAlertas(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('reports/route_monitor/update_status_log_route_alert', {
                ids: payload.ids
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
        } catch (error) {
            throw new Error(error)
        }
    },
    async validaAlertasConLimiteDeContenido(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('reports/route_monitor/valid_task_with_content_limit', {taskIds: payload.idsTareas},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async subirInformacionMonitor(context, payload) {
        context.commit('setLoading',true)

        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            const { status, data } = await gitCoreApi.post('reports/route_monitor/load', solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            return data.id

        } catch (error) {
            throw new Error(error)
        }finally{
            // context.commit('setLoading',false)
        }
    },
    async eliminarInformacionMonitor(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status } = await gitCoreApi.delete('reports/route_monitor/load', {
                params: {
                    date: payload.fecha
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async rutearInformacionMonitor(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status, data } = await gitCoreApi.post('reports/route_monitor/route', {}, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            return data
        } catch (error) {
            throw new Error(error)
        }finally{
            // context.commit('setLoading',false)
        }
    },
}