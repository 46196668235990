import gitCoreApi from '@/api/gitCoreApi'

/* eslint-disable */
export default {
    async obtenerInformacionMatriz(context, payload) {
        context.commit('setLoading', true)

        const { status, data } = await gitCoreApi.get(`/matrix/matrix/information?cluster=${payload.region}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        // Se maneja el caso de error en la petición
        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading', false)
            throw error
        }

        const { points, pointsNeo } = data

        context.commit('setPuntosMatriz', points)
        context.commit('setPuntosNeo4jMatriz', pointsNeo)

        context.commit('setLoading', false)
    },

    async obtenerRelaciones(context, payload) {
        context.commit('setLoading', true)

        const { status, data } = await gitCoreApi.get(`/matrix/matrix/relations?id=${payload.id}&type=${payload.type}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading', false)
            throw error
        }

        console.log(data)

        context.commit('setRelacionesNeo4jMatriz', data)
        context.commit('setLoading', false)
    },

    async crearRelacion(context, payload) {
        context.commit('setLoading', true)

        const { status, data } = await gitCoreApi.post(
            '/matrix/create_relation',
            {
                id: payload.idcodigo
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            }
        )

        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading', false)
            throw error
        }

        context.commit('setRelacionesNeo4jMatriz', data)
        context.commit('setLoading', false)
    },

    async obtenerInformaciongITXcaretClusters(context, payload){
        
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('/matrix/clusters', {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setClustersXcaret', data)
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    
    async obtenerInformaciongITXcaretHoteles(context, payload){
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('/matrix/clusters/hotels', {
                params: {
                    cluster: payload.cluster,
                    configuractionZone: payload.configuracionZona
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.commit('setHotelesXcaret', data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async obtenerInformaciongITXcaretConfiguracionZonas(context, payload){        
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('/matrix/clusters/configurationZones', {
                params: {
                    cluster: payload.cluster
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setConfiguracionZonasXcaret', data)
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async actualizarHotelesgITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.put('/matrix/clusters/hotels', 
            {
                idZone: payload.idZone,
                idsHotels: payload.idsHotels,
                cluster: payload.cluster,
                configurationZones: payload.configuracionZonas
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.dispatch('obtenerInformaciongITXcaretHoteles', { cluster: payload.cluster, token: payload.token, configuracionZona: payload.configuracionZonas })
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    
    async actualizarZonagITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.put('/matrix/clusters/zones', 
            {
                listZonesEdit: payload.listaZonasEditar,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async guardarZonagITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.post('/matrix/clusters/zones', 
            {
                'listZonesSave': payload.listaZonasGuardar,
                'options': payload.options
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            } )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async obtenerConfiguracionPuntoDetalle(context, { idPunto, token, removeNullFields = false, excludeDetalles = [] }) {
        try {
            const { data } = await gitCoreApi.get(`/configuration/points/details`, {
                params: {
                    idPoint: idPunto,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            let listadoDetalles = data ?? []

            if (removeNullFields) {
                listadoDetalles = listadoDetalles.filter(({ detalle_punto }) => detalle_punto)
            }

            if (excludeDetalles.length > 0) {
                listadoDetalles = listadoDetalles.filter(({ nombre_detalle }) => !excludeDetalles.includes(nombre_detalle))
            }

            return listadoDetalles
        } catch (error) {
            console.error(error)
            return []
        }
    },
    async subirClustersgITXcaret(context, payload) {
        context.commit('setLoading',true)

        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            solicitud.append('configurationZone', payload.configuracionZonas)
            solicitud.append('cluster', payload.cluster)
            const { status } = await gitCoreApi.post('xcaret/load/paxs', solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.dispatch('obtenerInformaciongITXcaretConfiguracionZonas', { cluster: payload.cluster, token: payload.token })
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async subirEditClustersgITXcaret(context, payload) {
        
        context.commit('setLoading',true)

        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            solicitud.append('configurationZone', payload.configuracionZonas)
            const { status } = await gitCoreApi.post('xcaret/load/paxs/edit', solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async guardarConfiguraciongITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.post('/matrix/clusters/configurationZone', 
            {
                'configurationZone': payload.configuracionNuevo,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            } )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async guardarEditarConfiguraciongITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.put('/matrix/clusters/configurationZone',
            {
                'configurationZone': payload.configuracionNuevo,
                'editConfigurationZone': payload.editarConfiguracionNuevo
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            } )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.dispatch('obtenerInformaciongITXcaretConfiguracionZonas', { cluster: payload.cluster, token: payload.token })
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async importConfiguracionZona(context, payload) {
        context.commit('setLoading',true)

        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            const { status } = await gitCoreApi.post('/matrix/clusters/configurationZoneXls', solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
     
    async eliminarConfiguracionZonagITXcaret(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status } = await gitCoreApi.delete(`/matrix/clusters/configurationZone`, {
                params: {
                    configurationZone: payload.configuracionZona
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.dispatch('obtenerInformaciongITXcaretConfiguracionZonas', { cluster: payload.cluster, token: payload.token })
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async obtenerInformaciongITXcaretZonas(context, payload){
        
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get(`/matrix/clusters/zones`, {
                params: {
                    cluster: payload.cluster
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setZonasXcaret', data)
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

}
