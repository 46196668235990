import gitCoreApi from '@/api/gitCoreApi' 

export default {
    async obtenerListaUsuarios(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('gitlogistics/get_list_of_users', 
            { "onlyMobile": payload.onlyMobile, },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setListaUsuarios', data)
        } catch (error) {
            throw new Error(error)
        }
    }
}