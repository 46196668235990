import { removerElementoArray } from '@/helpers/helpers.js'
import gitLogisticsApi from '@/api/gitLogisticsApi'
import {format} from 'date-fns'

/* eslint-disable */
export default {
    /**
     *
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async obtenerCargas(context, payload) {
        try {
            context.commit('setLoading', true)

            //Crar array de las promesas a ejecutar
            const promises = [
                //Promesa obtenerCargas
                gitLogisticsApi.post('/gitlogistics2/obtenerCargas', {
                    fecha: format(new Date(),"yyyy-MM-dd"),
                    gruposActivos: payload.gruposActivos
                }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    const {listaCargas,conteoUsuario} = response.data
                    context.commit('setCargas', listaCargas)
                    context.commit('setCargaActual', null)
                    context.commit('setConteoUsuarioCargas', conteoUsuario.filter(({puntos}) => puntos > 0))
                }),
                //Promesa obtenerCargasActivas
                gitLogisticsApi.post('/gitlogistics2/obtenerCargasActuales', {
                    fecha: new Date(),
                    gruposActivos: payload.gruposActivos
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setCargasActivas', response.data.listaCargas)
                }),
            ]

            //Ejecutar todas las promesas
            await Promise.all(promises)

            context.commit('setLoading', false)
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },

    async obtenerCarga(context, payload) {
        try {

            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/gitlogistics2/obtenerCarga', {
                idCarga: payload.idCarga
            }, 
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener las cargas')
                context.commit('setLoading', false)
                throw error
            }

            const carga = {
                id: data.carga.id,
                archivo: JSON.parse(data.carga.archivo_json),
                puntos: data.carga.puntos,
                vehiculos: data.carga.vehiculos,
                fechaInicio: data.carga.fecha_inicio,
                fechaFin: data.carga.fecha_fin,
                respuestas: {
                    planCluster: data.peticiones.filter(elemento => elemento.tipo_peticion === 'gitCluster'),
                    planVisit: data.peticiones.filter(elemento => elemento.tipo_peticion === 'gitVisits'),
                    planTour: data.peticiones.filter(elemento => elemento.tipo_peticion === 'gitTour'),
                },
                conteoCarga: data.conteoCarga
            }
            context.commit('setLoading', false)
            context.commit('setCargaActual', carga)

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },

    reasignarArea(context, payload) {
        // context.commit('setLoading', true)

        const { puntoId, vehiculoAnterior, vehiculoNuevo } = payload
        const { archivo, respuestas } = context.state.cargaActual
        const { puntos, vehiculos, opciones } = archivo
        const { planCluster, planVisit, planTour } = respuestas
        const planClusterResponse = JSON.parse(planCluster[0].respuesta)

        const punto = puntos.find(({ id }) => id === puntoId)
        const actividad = parseFloat(punto.actividad)

        const indexVehiculoAnterior = vehiculos.findIndex(({ id }) => id === vehiculoAnterior)
        const indexVehiculoNuevo = vehiculos.findIndex(({ id }) => id === vehiculoNuevo)
        let { clusteredLocations, clusterReports } = planClusterResponse.response

        //Remover el punto del cluster anterior
        const planClusterN = []
        removerElementoArray(clusteredLocations[indexVehiculoAnterior].locationIds, puntoId)
        clusterReports[indexVehiculoAnterior].totalActivity = clusterReports[indexVehiculoAnterior].totalActivity - actividad
        clusterReports[indexVehiculoAnterior].numberOfLocations = clusterReports[indexVehiculoAnterior].numberOfLocations - 1

        //Agregar el punto al cluster nuevo
        clusteredLocations[indexVehiculoNuevo].locationIds.push(puntoId)
        clusterReports[indexVehiculoNuevo].totalActivity = clusterReports[indexVehiculoNuevo].totalActivity + actividad
        clusterReports[indexVehiculoNuevo].numberOfLocations = clusterReports[indexVehiculoNuevo].numberOfLocations + 1
        planClusterN.push({
            respuesta: JSON.stringify({ response: planClusterResponse.response, id: planCluster[0].id }),
            id: planCluster[0].id,
            tipo_peticion: planCluster[0].tipo_peticion
        })

        //Remover de planVisit
        const planVisitN = []
        const diasSet = new Set()
        planVisit.forEach(({ respuesta, id, tipo_peticion }) => {
            let respuestaJson = JSON.parse(respuesta)
            if (respuestaJson.id !== vehiculoAnterior) {
                planVisitN.push({ respuesta, id, tipo_peticion })
                return
            }

            const visits = respuestaJson.response.visits.filter(({ orderId }) => orderId === puntoId)
            visits.forEach(visit => {
                diasSet.add(visit.visitDate)
                removerElementoArray(respuestaJson.response.visits, visit)
            })

            planVisitN.push({
                respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                id,
                tipo_peticion
            })
        })
        const dias = [...diasSet]
        
        //Sustituir store
        const carga = {
            id: context.state.cargaActual.id,
            archivo: archivo,
            puntos: context.state.cargaActual.puntos,
            vehiculos: context.state.cargaActual.vehiculos,
            fechaInicio: context.state.cargaActual.fechaInicio,
            fechaFin: context.state.cargaActual.fechaFin,
            respuestas: {
                planCluster: planClusterN,
                planVisit: planVisitN,
                planTour: planTour,
            }
        }
        context.commit('setCargaActual', carga)

        //Replanificar dias
        context.dispatch('replanificarDatosNoPlanPlanVisitGitTour', {
            puntos,
            vehiculos,
            opciones,
            planVisitsResponse: planVisitN.map(({ respuesta }) => {
                return JSON.parse(respuesta)
            }),
            diasReplan: dias,
            vehiculo: vehiculoAnterior,
        })
    },
    reasignarAreaRealizado(context, payload) {
        const {punto,vehiculo,responsePlanTourMod} = payload
        const { archivo, respuestas } = context.state.cargaActual
        const { planCluster,planVisit,planTour } = respuestas

        let puntosNoPlaneados = responsePlanTourMod.length
        //Remover de planVisit
        const planTourN = []
        planTour.forEach(({ respuesta, id, tipo_peticion }) => {
            let respuestaJson = JSON.parse(respuesta)

            const planTourMod = responsePlanTourMod.find(responsePlanTour => responsePlanTour.id === respuestaJson.id)
            if(!planTourMod){
                const indexToursReport = respuestaJson.response.tourReports.findIndex(({ vehicleId }) => vehicleId === vehiculo)

                if(indexToursReport !== -1){
                    const indexTourEvent = respuestaJson.response.tourReports[indexToursReport].tourEvents.findIndex(({ orderId }) => orderId === punto)
                    if(indexTourEvent !== -1){
                        removerElementoArray(respuestaJson.response.tourReports, respuestaJson.response.tourReports[indexToursReport])
                        removerElementoArray(respuestaJson.response.tours, respuestaJson.response.tours[indexToursReport])
                        puntosNoPlaneados++
                    }
                }
                planTourN.push({
                    respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                    id,
                    tipo_peticion
                })
                return
            }
            const {tourReports,tours} = planTourMod.response
            let indexTours = respuestaJson.response.tours.findIndex(({ vehicleId }) => vehicleId === vehiculo)
            let indexToursReports = respuestaJson.response.tourReports.findIndex(({ vehicleId }) => vehicleId === vehiculo)

            if(indexTours != -1) respuestaJson.response.tours[indexTours] = tours[0]
            if(indexToursReports != -1) respuestaJson.response.tourReports[indexToursReports] = tourReports[0]

            planTourN.push({
                respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                id,
                tipo_peticion
            })
        })

        //Agregar ordenes no planeadas
        let respuestasJson = JSON.parse(planTourN[0].respuesta)
        if(!Object.prototype.hasOwnProperty.call(respuestasJson.response, "orderIdsNotPlanned")){
            respuestasJson.response["orderIdsNotPlanned"] = []
        }
        for(let i = 0; i < puntosNoPlaneados; i++){
            respuestasJson.response.orderIdsNotPlanned.push(punto)
        }
        planTourN[0].respuesta = JSON.stringify(respuestasJson)

        //Sustituir store
        const carga = {
            id: context.state.cargaActual.id,
            archivo: archivo,
            puntos: context.state.cargaActual.puntos,
            vehiculos: context.state.cargaActual.vehiculos,
            fechaInicio: context.state.cargaActual.fechaInicio,
            fechaFin: context.state.cargaActual.fechaFin,
            respuestas: {
                planCluster: planCluster,
                planVisit: planVisit,
                planTour: planTourN,
            }
        }
        context.commit('setCargaActual', carga)

        context.dispatch('limpiarPlanTourMod')
    },
    async guardarInformmacionReasignacion(context,payload) {
        context.commit('setLoading', true)

        const cargaActual = context.state.cargaActual
        const {token} = payload
        const { status, data } = await gitLogisticsApi.post('/gitlogistics2/actualizarPlaneacion', cargaActual, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error al actualizar la planeacion')
            context.commit('setLoading', false)
            throw error
        }

        context.commit('setLoading', false)
    },
    cambiarDiaReasignacion(context, {modificaciones,vehiculo}) {
        const { archivo, respuestas } = context.state.cargaActual
        const { puntos, vehiculos, opciones } = archivo
        const { planVisit, planCluster, planTour } = respuestas

        const diasSet = new Set()
        const planVisitN = []
        planVisit.forEach(({ respuesta, id, tipo_peticion }) => {
            let respuestaJson = JSON.parse(respuesta)
            if (respuestaJson.id !== vehiculo) {
                planVisitN.push({ respuesta, id, tipo_peticion })
                return
            }
            modificaciones.forEach(({idPunto,fecha,agregar}) => {
                if(agregar){
                    const {tiempo_de_servicio} = puntos.find(punto => punto.id === idPunto)
                    respuestaJson.response.visits.push({orderId: idPunto, visitDate: fecha, serviceTime: parseInt(tiempo_de_servicio) * 60 ,visitNumber: 0})
                }else{
                    const visit = respuestaJson.response.visits.find(({ orderId, visitDate }) => orderId === idPunto && visitDate === fecha)
                    removerElementoArray(respuestaJson.response.visits, visit)
                }
                diasSet.add(fecha)
            })

            planVisitN.push({
                respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                id,
                tipo_peticion
            })
        })
        
        //Sustituir store
        const carga = {
            id: context.state.cargaActual.id,
            archivo: archivo,
            puntos: puntos,
            vehiculos: vehiculos,
            fechaInicio: context.state.cargaActual.fechaInicio,
            fechaFin: context.state.cargaActual.fechaFin,
            respuestas: {
                planCluster: planCluster,
                planVisit: planVisitN,
                planTour: planTour,
            }
        }
        context.commit('setCargaActual', carga)

        //Replanificar dias
        context.dispatch('replanificarDatosNoPlanPlanVisitGitTour', {
            puntos, 
            vehiculos, 
            opciones,
            planVisitsResponse: planVisitN.map(({ respuesta }) => {
                return JSON.parse(respuesta)
            }),
            diasReplan: [...diasSet],
            vehiculo: vehiculo,
        })
    },
    reasignarDiaRealizado(context,payload){
        const {vehiculo,responsePlanTourMod} = payload
        const { archivo, respuestas } = context.state.cargaActual
        const { planCluster,planVisit,planTour } = respuestas

        //Remover de planVisit
        const planTourN = []
        
        planTour.forEach(({ respuesta, id, tipo_peticion }) => {
            let respuestaJson = JSON.parse(respuesta)

            const planTourMod = responsePlanTourMod.find(responsePlanTour => responsePlanTour.id === respuestaJson.id)
            if(!planTourMod){
                planTourN.push({
                    respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                    id,
                    tipo_peticion
                })
                return
            }
            const {tourReports,tours} = planTourMod.response
            let indexTours = respuestaJson.response.tours.findIndex(({ vehicleId }) => vehicleId === vehiculo)
            let indexToursReports = respuestaJson.response.tourReports.findIndex(({ vehicleId }) => vehicleId === vehiculo)

            if(indexTours != -1) respuestaJson.response.tours[indexTours] = tours[0]
            if(indexToursReports != -1) respuestaJson.response.tourReports[indexToursReports] = tourReports[0]

            planTourN.push({
                respuesta: JSON.stringify({ response: respuestaJson.response, id: respuestaJson.id }),
                id,
                tipo_peticion
            })
        })

        //Sustituir store
        const carga = {
            id: context.state.cargaActual.id,
            archivo: archivo,
            puntos: context.state.cargaActual.puntos,
            vehiculos: context.state.cargaActual.vehiculos,
            fechaInicio: context.state.cargaActual.fechaInicio,
            fechaFin: context.state.cargaActual.fechaFin,
            respuestas: {
                planCluster: planCluster,
                planVisit: planVisit,
                planTour: planTourN,
            }
        }
        context.commit('setCargaActual', carga)

        context.dispatch('limpiarPlanTourMod')
    }
}
