export default {
    setListarPuntos(state, payload) {
        state.listaPuntos = payload
    },
    setListarDetalles(state, payload) {
        state.listaDetalles = payload
    },
    setListarDetallesCompania(state, payload) {
        state.listaDetallesCompania = payload
    },
    setContadorCuentas(state, payload) {
        state.contadorCuentas = payload
    },
    setPermisosConfGral(state, payload) {
        state.permisosConfGral = payload
    },
    setListaTiposVehiculos(state, payload) {
        state.listaTiposVehiculos = payload
    }
}