import gitCoreApi from '@/api/gitCoreApi'

export default {

    /**
     * Devuelve las lista de permisos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarRoles(context, payload) {

        try{

            context.commit('setLoading', true)
            context.dispatch('ListarRolTipo', payload)
            context.dispatch('catalogoListarMenuSubMenus', payload)
            context.dispatch('catalogoListarMenusSubMenus', payload)
            const { status, data } = await gitCoreApi.post(`configuration/roles/get_list_roles`,{},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListarRoles', data) 
            


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    


    /**
     * Crear una nuevo Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async nuevoRol(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/roles/insert_role',
                {
                    "nombre":payload.nombre,
                    "descripcion":payload.descripcion,
                    "estatus_id":payload.estatus_id,
                    "roltipo_id":payload.roltipo_id,
                    "rutaInicio": payload.rutaInicio
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Rol')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarRoles', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async EditarRol(context, payload) {
        console.log(payload);
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/roles/update_role',
                {
                    "id": payload.id,
                    "nombre":payload.nombre,
                    "descripcion":payload.descripcion,
                    "estatus_id":payload.estatus_id,
                    "roltipo_id":payload.roltipo_id,
                    "rutaInicio":payload.rutaInicio
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Rol')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarRoles', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },


    /**
     * Cambia el estatus de un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */


    async CambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/roles/update_role',{ 
                    "id": payload.id,
                    "estatus_id": payload.estatus_id
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus_id) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El rol se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El rol se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El rol se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarRoles', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },

    async ListarRolTipo(context, payload) {

        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/roles/get_rol_type`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaRolTipo', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

    async abrirPermiso(context, payload) {
        context.commit('setInfoRol', payload) 
    },
    async regresarRoles(context, payload){
        const data = payload
        context.commit('setIsRol', data) 
    }

}
