import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            planClusterRequest: null,
            planClusterResponse: null,
            clustersProcesados: [],
            erroresgITCluster: [],
            respuestaClusters: [],
            centroidesClusters: []
        }
    },
    mutations,
    actions,
    getters
}