import { removerElementoArray, descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import gitCoreApi from '@/api/gitCoreApi'
import { clusterCercanoAAlmacen } from "@/helpers/gITCluster"

/* eslint-disable */
export default {
    /**
     *
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async startPlanClusters(context, payload) {
        try {
            const { puntos, vehiculos, xDima, configuracion } = payload

            let matrizDistanicia = null
            if(xDima){
                matrizDistanicia = xDima.response.summary.id
            }

            const { status, data } = await gitCoreApi.post('/gitlogistics/git_cluster/create_request', {
                points: puntos, 
                vehicles: vehiculos, 
                xDima: matrizDistanicia, 
                configuration: configuracion 
            },{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al calcular los cluster')
                throw error
            }
            return data
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
        }
    },
    async responseClusters(context, { jobs, puntos, configuracion, token }) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/gitlogistics/git_cluster/create_response',{
                jobs: jobs,
                points: puntos,
                configuration: configuracion
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al calcular los cluster')
                throw error
            }

            return data
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
        }
    },
    async normalizaClusters(context, payload) {
        const {respuestas, vehiculos, almacenes, generarNoAsignado } = payload
        let listaAreas = []
        let centroidesClusters = []
        for(const {region, job} of respuestas) {
            const vehiculosRegion = vehiculos.filter(vehiculo => vehiculo.region == region)
            const clustersDisponibles = [...job.data.clusters]
            for(const vehiculo of vehiculosRegion) {
                let almacen
                if(vehiculo.almacen_comienzo && almacenes){
                    almacen = almacenes.find(({id}) => id == vehiculo.almacen_comienzo)
                }
                const cluster = await clusterCercanoAAlmacen(almacen, clustersDisponibles)
                const clusterLocation = job.data.clusteredLocations.find(clusterLocation => clusterLocation.clusterId == cluster.id)
                const areas = clusterLocation.locationIds.map(lugar => {
                    return {
                        codigoPunto: lugar,
                        vehiculo: vehiculo.id
                    }
                })
                const coordenadas = cluster.cluster.referenceLocation.offRoadCoordinate
                centroidesClusters.push({
                    vehiculo: vehiculo.id,
                    coordenadas: {
                        latitud: coordenadas.y,
                        longitud: coordenadas.x
                    }
                })
                listaAreas = listaAreas.concat(areas)
                removerElementoArray(clustersDisponibles, cluster.cluster)
            }

            // En caso de haber puntos sin planificar se genera un nuevo vehiculo ("no asignado")
            if (generarNoAsignado) {
                job.data.locationIdsNotPlanned?.forEach(idPunto => {
                    listaAreas.push({
                        codigoPunto: idPunto,
                        vehiculo: 'no asignado'
                    })
                })
                centroidesClusters.push({
                    vehiculo: 'no asignado',
                    coordenadas: {
                        latitud: 0,
                        longitud: 0
                    }
                })
            }
        }
        context.commit('setCentroidesClusters', centroidesClusters)
        context.commit('setRespuestaClusters', listaAreas)
        context.commit('setLoading', false)
    },
    async setLoadingClusters(context, payload) {
        context.commit('setLoading', payload)
    },
    async guardarRespuestaPlanCluster(context, payload) {
        context.commit('setPlanClusterResponse', payload)
    },
    async guardarRespuestaPlanClusterError(context, payload) {
        context.commit('addErroresgITCluster', payload)
    },
    async cambiarArea(context, payload) {
        const { puntoId, vehiculoAnterior, vehiculoNuevo, clusters } = payload
        const areas = [...clusters]
        const areaIndex = areas.findIndex(area => area.codigoPunto == puntoId && area.vehiculo == vehiculoAnterior )
        areas[areaIndex].vehiculo = vehiculoNuevo
        context.commit('setRespuestaClusters', areas)
    },
    async actualizaProcesoJson(context, payload) {
        const { status, data } = await gitCoreApi.post('gitlogistics/load_file/update_load_automatic_vehicles', {
            fkProcess: payload.idProceso,
            vehicles: payload.vehiculos
        }, {
            headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-Type': 'application/json'
            }
        })

        if (status !== 200) {
            throw new Error('!Ups ocurrio un error')
        }
    },
    async creaXlsCluster(context, payload){
        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/git_cluster/export_XLSCluster', {
                data: payload.data,
                fkProcess: payload.idProceso
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            const { data_base64, file_name } = data
            descargaBaseSesentaycuatro(data_base64, file_name, 'zip')
        } catch (error) {
            throw new Error(error)
        }
    },
}
