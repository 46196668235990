import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            listaRoles:[],
            listaTipoRoles:[],
            infoRol:[],
            isRol:[],
        }
    },
    mutations,
    actions,
    getters
}