import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    async cargarActividades({ commit }, payload) {
        try {
            const response = await gitCoreApi.post('/galery/activities', {}, {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                }
            })
            commit('setActividades', response.data)
        } catch (error) {
            console.error(error)
        }
    },
    async cargarPreguntas({ commit }, payload) {
        try {
            const response = await gitCoreApi.get('/questions/general', {
                params: {
                    activity: payload.actividad,
                    includeAnswers: true,
                },
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                }
            })
            commit('setPreguntas', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async consultarRespuestas({ commit }, payload) {
        commit('setLoadingRespuestas', true)
        const {
            pregunta: question,
            respuesta: answer,
            actividad: activity,
            usuarios: users,
            inicio: startDate,
            fin: endDate
        } = payload
        try {
            const response = await gitCoreApi.post('/query_answer/get_info', {
                question, answer, activity, users, startDate, endDate
            }, {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            commit('setListadoRespuestas', response.data)
        } catch (error) {
            console.error(error)
        } finally {
            commit('setLoadingRespuestas', false)
        }
    },

    async descargarXlsRespuestas(context, payload) {
        const {
            pregunta: question,
            respuesta: answer,
            actividad: activity,
            usuarios: users,
            inicio: startDate,
            fin: endDate
        } = payload
        try {
            const { status, data } = await gitCoreApi.post('query_answer/get_info/create_xls', 
            {
                question, answer, activity, users, startDate, endDate
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

        } catch (error) {
            throw new Error(error)
        }
    },
}