import gitCoreApi from '@/api/gitCoreApi'

export default {
    
    /**
     * Envía una petición hacia la API para obtener la configuracion general,
     * si la respuesta es exitosa se guarda dicha información en localStorage y en
     * el estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     */
    async obtenerConfiguracionGral(context,payload) {
        const { status, data } = await gitCoreApi.post(`/process/obtenerConfiguracion`, {
            idCompany: payload.idCompany
        }, {
            headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-Type': 'application/json'
            }
        })

        // Se maneja el caso de error en la petición
        if (status !== 200) {
            console.error(data)
            throw new Error(data.error || 'Error al obtener la configuracion')
        }
        const configuracionGral = data.list

        //Guardar configuracion de los XServer PTV
        const tokenPtv = configuracionGral.find(({propiedad}) => propiedad=="TOKEN_XSERVER")
        if(tokenPtv){
            localStorage.setItem('TOKEN_XSERVER', tokenPtv.valor)
        }
        
        //Guardar configuracionGral
        localStorage.setItem('configuracionGral', JSON.stringify(configuracionGral))
        context.commit('configuracionGral', {
            configuracionGral: configuracionGral
        })
    },

    /**
     * Obtiene la configuracion general a travez del localStorage y lo guarda en el estado de la aplicación,
     * si la respuesta es exitosa se guarda dicha información en localStorage y en
     * el estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     */
    llenarEstadoConfiguracionGral(context){
        const configuracionGral = JSON.parse(localStorage.getItem('configuracionGral'))
        
        context.commit('configuracionGral', {
            configuracionGral: configuracionGral
        })
    },
    /**
     * Elimina la configuracion general del localStorage y del estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     */
    eliminarConfiguracionGral(context){
        localStorage.removeItem('configuracionGral')
        //Eliminar configuracion de los XServer del LocalStorage
        localStorage.removeItem('TOKEN_XSERVER')

        context.commit('configuracionGral', {
            configuracionGral: null
        })
    },

    async obtenerListadoMenu(context,payload) {
        // Petición hacia la API para obtener el menu
        try{

            const { status, data } = await gitCoreApi.post(`configuration/menu/get_menu`, {}, {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })

            // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                throw new Error(data.error || 'Error al obtener el menu')
            }else{
                const listaMenu = data || []
                localStorage.setItem('setMenu', JSON.stringify(listaMenu))
                context.commit('setMenu', listaMenu)
            }

        } catch (error) {
            localStorage.setItem('setMenu', "[]")
            console.error(error)
        } 

    },
    llenarMenu(context){
        const storageMenu = localStorage.getItem('setMenu')||"[]"
        const listaMenu = JSON.parse(storageMenu)
        context.commit('setMenu', listaMenu)
    },
    eliminarMenu(context){
        localStorage.removeItem('setMenu')
        context.commit('setMenu', [])
    },
//------------------------------------------------------------------------------------------------------------
    async obtenerListadoPermisos(context,payload) {
        // Petición hacia la API para obtener el menu
        try{

            const { status, data } = await gitCoreApi.post(`/configuration/permissions_aplication/get_list_permissions_aplication_by_rol`, {
                isActive:true
            }, {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })

            // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                throw new Error(data.error || 'Error al obtener el listado de permisos')
            }
            const listaPermisos = data || []
            localStorage.setItem('setPermisos', JSON.stringify(listaPermisos))
            context.commit('setPermisos', listaPermisos)
            
        } catch (error) {
            localStorage.setItem('setPermisos', "[]")
            console.error(error)
        } 

    },
    llenarPermisos(context){
        const storagePermisos = localStorage.getItem('setPermisos')||"[]"
        const listaPermisos = JSON.parse(storagePermisos)
        context.commit('setPermisos', listaPermisos)
    },
    eliminarPermisos(context){
        localStorage.removeItem('setPermisos')
        context.commit('setPermisos', [])
    },
//------------------------------------------------------------------------------------------------------------
    async obtenerRutaInicio(context,payload) {
        // Petición hacia la API para obtener el menu
        try{

            const { status, data } = await gitCoreApi.post(`/configuration/menu/get_ruta_inicio`, {}, {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })

            // Se maneja el caso de error en la petición
            if (status !== 200) {
                throw new Error(data.error || 'Error al obtener la ruta de inicio')
            }else{
                const rutaInicio = data|| 'resumen'
                localStorage.setItem('rutaInicio', JSON.stringify(rutaInicio))
                context.commit('setRutaInicio', rutaInicio)
            }

        } catch (error) {
            localStorage.setItem('rutaInicio','resumen')
            console.error(error)
            throw error
        } 
    },

    llenarRutaInicio(context){
        const storageRutaInicio = localStorage.getItem('rutaInicio')||'resumen'
        const rutaInicio = JSON.parse(storageRutaInicio)
        context.commit('setRutaInicio', rutaInicio)
    },
    eliminaRutaInicio(context){
        localStorage.removeItem('rutaInicio')
        context.commit('setRutaInicio', [])
    },

    //------------------------------------------------------------------------------------------------------------
    async obtenerCompanias(context,payload) {
        // Petición hacia la API para obtener el menu
        try{
            const { status, data } = await gitCoreApi.get(`/configuration/menu/get_companies`, {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            // Se maneja el caso de error en la petición
            if (status !== 200) {
                throw new Error(data.error || 'Error al obtener lista de companias')
            }

            return data || []
        } catch (error) {
            console.error(error)
        } 
    },

    async companiaSeleccionada (context, payload){
        try {
            const { status, data } = await gitCoreApi.post('auth/add_company', {token:payload.token, idCompania:payload.idCompania} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
            localStorage.setItem('token', data.token)
            localStorage.setItem('tokenExpirationDate', data.expiresAt)
            localStorage.setItem('companiaIdSel', data.company)
            context.commit('setToken', {
                token: data.token,
                tokenExpirationDate: data.expiresAt,
            })
            context.commit('setCompaniaSel',  data.company)
            context.dispatch('obtenerConfiguracionGral', {
                token: data.token,
                idCompany: data.company
            })
            context.dispatch('obtenerListadoPermisos', {
                token: data.token
            })
        } catch (error) {
            throw new Error(error)
        }
    },
    eliminarCompaniaSeleccionada(context){
        localStorage.removeItem('companiaIdSel')
        context.commit('setCompaniaSel', [])
    },
    setCompaniaSeleccionada(context, payload) {
        context.commit('setCompaniaSeleccionada', payload)
    }
}
