import gitCoreApi from '@/api/gitCoreApi' 
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    async obtenerInformacionSeguimientoPuntos(context, payload) {
        context.commit('setLoading',true, { root: true })

        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/points_status_tracking', 
            {
                "date": payload.date,
                "access": payload.acceso ? payload.acceso : 0,
                "users": payload.usuario ? payload.usuario : [],
                "advancedFilters": payload.filtrosAvanzados ? payload.filtrosAvanzados : [],
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.commit('setListaSeguimientoPuntos',data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false, { root: true })
        }
    },

    async descargarXlsPuntos(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('gitlogistics/points_status_tracking/create_xls', 
            {
                "date": payload.date,
                "access": payload.acceso ? payload.acceso : 0,
                "users": payload.usuario ? payload.usuario : [],
                "advancedFilters": payload.filtrosAvanzados ? payload.filtrosAvanzados : [],
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

        } catch (error) {
            throw new Error(error)
        }
    },

    async buscarValoresDetallePunto(context, payload) {
        context.commit('setLoading',true, { root: true })

        try {
            const { data } = await gitCoreApi.get('/point_detail', 
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                },
                params: {
                    idCompanyDetail: payload.idCompanyDetail
                }
            })
            return data

        } catch (error) {
            context.commit('setLoading',false, { root: true })
            throw new Error(error)
        }finally{
            context.commit('setLoading',false, { root: true })
        }
    }
}