import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'
import {format} from 'date-fns'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

/* eslint-disable */
export default {
    /**
     *
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async obtenerCargasActivasResumen(context, payload) {
        try {
            context.commit('setCargandoCargasActivas', true)
            const { status, data } = await gitLogisticsApi.post('/resumen/obtenerProcesos', {
                fecha: format(new Date(),"yyyy-MM-dd"),
                gruposActivos: payload.gruposActivos,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error(data.error || 'Failed to logout, try later')
            }

            context.commit('setCargasActivasResumen', data)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setCargandoCargasActivas', false)
        }
    },
    async obtenerCargaSeleccionadaResumen(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.get(`/gitlogistics/resume`,
                {
                    params: {
                        idProcess: payload.idCarga
                    },
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                throw new Error(data.error || 'Failed to logout, try later')
            }
            context.commit('setCargaSeleccionadaResumen', data)
            context.commit('setListaVehiculosResumen', data.vehiculos)
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    guardarVehiculoResumen(context, payload) {
        context.commit('setVehiculoResumen', payload)
    },
    guardarFechaResumen(context, payload) {
        context.commit('setVehiculoResumen', null)
        context.commit('setFechaResumen', payload)
    },
    borrarVehiculoResumen(context) {
        context.commit('setVehiculoResumen', null)
    },
    async replicarRutaMonitoreo(context, payload){
        try {
            context.commit('setLoading', true)
            let { request, token } = payload
            request.dayApply = format(request.dayApply, 'yyyy-MM-dd 00:00:00')
            const newRangoAplicar = []
            request.daysReplicate.forEach(date => {
                newRangoAplicar.push(date.getTime())
            })
            request.daysReplicate = newRangoAplicar
            const { status, data } = await gitCoreApi.post('/gitlogistics/summary/replicate_route',request,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error('Error al guardar')
                throw error
            }
            await context.dispatch("obtenerCargasActivasResumen", {
                token,
                gruposActivos: request.activeGroups
            })
            context.commit('setLoading', false)
            return data
        } catch (error) {
            throw error
        }  
    },
    async creaXlsRuta(context, payload){
        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/resume/create_xls', 
            {
                idProcess: payload.cargaSeleccionada,
                type: payload.tipo,
                isDivided: payload.isDivided,
                infoFile: {
                    name: payload.archivoInfo.nombre,
                    startDate: payload.archivoInfo.horaInicio,
                    endDate: payload.archivoInfo.horaFin
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)
        } catch (error) {
            throw new Error(error)
        }
    },
    async creaIcsRuta(context, payload){
        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/resume/createIcsRoute', 
            {
                idProcess: payload.cargaSeleccionada,
                infoFile: {
                    name: payload.archivoInfo.nombre,
                    startDate: payload.archivoInfo.horaInicio,
                    endDate: payload.archivoInfo.horaFin
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            const { data_base64, file_name } = data
            descargaBaseSesentaycuatro(data_base64, file_name, 'zip')
        } catch (error) {
            throw new Error(error)
        }
    },
    guardarIdsTarea(context, payload) {
        context.commit('setIdsTarea', payload)
    },
}
