import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            fecha: new Date(),
            configurationActiveXcaret: null,
            setCargaSeleccionadaXcaret: null
        }
    },
    mutations,
    actions,
    getters
}