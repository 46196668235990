// import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'

export default {
    /**
     * Forma un objeto con toda la información generada durante el proceso
     * de planeación. Envía este objeto hacia la API de gIT Logistics para
     * que los datos sean guardados y procesados. En caso de que la operación
     * resulte exitosa se guarda la respuesta en el estado de la app
     * @param {*} context - Métodos y propiedades del store
     */
    async guardarPlaneacion(context) {

        try {
            context.commit('setLoading', true)
            const id = context.getters.idPlaneacion || null
            const token = context.getters.token || null
            if (!id) {
                throw new Error('El id del proceso no fue indicado')
            }

            const gitCluster = context.getters.getRespuestaClusters

            const gitVisits = context.getters.getGitVisitResponse

            const gitTour = await context.dispatch('obtenerGitTourGuardado')

            const infoPuntosConFrecuenciasNoPlanificables = context.getters.getInfoPuntosConFrecuenciasNoPlanificables

            const requestData = {
                id,
                puntos: context.getters.infoPuntos || [],
                tareas: context.getters.infoVehiculos || [],
                almacenes: context.getters.infoAlmacenes || [],
                gitCluster,
                gitVisits,
                gitTour,
                infoPuntosConFrecuenciasNoPlanificables
            }
            const { status, data } = await gitCoreApi.post('/gitlogistics/save_planning', requestData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            if (status !== 200) {
                throw new Error (data.error || data || 'No fue posible guardar la información')
            }

            context.commit('setResultadoPlaneacion', requestData)
            context.commit('setStatusProceso', 'guardado')
            context.commit(
                'setInfoArchivo',{
                    infoPuntos: [],
                    infoVehiculos: [],
                    infoOpciones: null,
                    infoAlmacenes: [],
                    infoNoPlanificados: []
                }
            )
        } catch (error) {
            throw new Error (error || 'No fue posible guardar la información')
        } finally {
            context.commit('setLoading', false)
        }

        
    },
    /**
     * Forma un objeto con toda la información generada del plan de visitas
     * @param {*} context - Métodos y propiedades del store
     */
    obtenerGitVisitGuardado(context){
        let gitVisits = []
        const requestPlanVisits = context.getters.planVisitsRequest
        const responsePlanVisits = context.getters.planVisitsResponse
        if(requestPlanVisits && responsePlanVisits) {
            requestPlanVisits.forEach(requestPlanVisit => {
                const responsePlanVisit = responsePlanVisits.find(response => response.id === requestPlanVisit.vehiculo)
                if(responsePlanVisit) {
                    gitVisits.push({
                        request: requestPlanVisit,
                        response: responsePlanVisit
                    })
                }
            })
        }
        return gitVisits
    },
    /**
     * Forma un objeto con toda la información generada del plan de ruteo
     * @param {*} context - Métodos y propiedades del store
     */
    obtenerGitTourGuardado(context){
        let gitTours = []
        const requestPlanTours = context.getters.solicitudPlanTour
        const responsePlanTours = context.getters.respuestaPlanTour
        if(requestPlanTours && responsePlanTours) {
            requestPlanTours.forEach(requestPlanTour => {
                const responsePlanTour = responsePlanTours.find(response => response.id === requestPlanTour.fecha)
                if(responsePlanTour) {
                    gitTours.push({
                        request: requestPlanTour,
                        response: responsePlanTour
                    })
                }
            })
        }
        return gitTours
    }
}