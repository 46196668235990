import gitCoreApi from '@/api/gitCoreApi'

export default {
    async obtenerListaRespuestas({ commit }, data) {
        commit('setLoading', true)
        const { token, id } = data
        try {
            const { status, data } = await gitCoreApi.get(`configuration/answers/get_answers`, {
                params: {
                    id: id
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }else{
                commit('setListaRespuestas', data)
                commit('setFkPregunta', id)
            }

        } catch (error) {
            console.log(error)
            throw error
        } finally {
            commit('setLoading', false)
        }
    },
    async cambiarEstatusRespuesta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put('configuration/answer/update_status_answer',
                {
                    idrespuesta: payload.idrespuesta,
                    id_status: payload.id_status
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.id_status) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La respuesta se activo correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La respuesta se desactivo correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La respuesta se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaRespuestas', { token: payload.token, id: payload.fkpregunta })
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async agregarRespuesta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/answer/add_answer',
                {
                    idrespuesta: payload.datosRespuesta.id,
                    fkpregunta: payload.datosRespuesta.fkpregunta,
                    idderivado: payload.datosRespuesta.idderivado,
                    respuesta: payload.datosRespuesta.respuesta,
                    orden: payload.datosRespuesta.orden,
                    valor: payload.datosRespuesta.valor,
                    valor_2: payload.datosRespuesta.valor_2,
                    actividades: payload.datosRespuesta.actividades,
                    derivado: payload.datosRespuesta.derivado,
                    deriva_tarea_actividad: payload.datosRespuesta.deriva_tarea_actividad,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al agregar la respuesta')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.datosRespuesta.id) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La respuesta se agrego correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La respuesta se edita correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La respuesta se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaRespuestas', { token: payload.token, id: payload.datosRespuesta.fkpregunta })
            }
            return mensaje
                
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async editarRespuesta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put('configuration/answer/update_answer',
                {
                    idrespuesta: payload.datosRespuesta.id,
                    fkpregunta: payload.datosRespuesta.fkpregunta,
                    idderivado: payload.datosRespuesta.idderivado,
                    respuesta: payload.datosRespuesta.respuesta,
                    orden: payload.datosRespuesta.orden,
                    valor: payload.datosRespuesta.valor,
                    valor_2: payload.datosRespuesta.valor_2,
                    actividades: payload.datosRespuesta.actividades,
                    derivado: payload.datosRespuesta.derivado,
                    deriva_tarea_actividad: payload.datosRespuesta.deriva_tarea_actividad,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar la respuesta')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaRespuestas', { token: payload.token, id: payload.datosRespuesta.fkpregunta })
                mensaje = {
                    tipo: 'success',
                    titulo: 'La respuesta se ha editado correctamente'
                }
            }
            return mensaje
                
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
}