import gitCoreApi from '@/api/gitCoreApi'

/* eslint-disable */
export default {
    async getClusterRegions(context, payload) {
        context.commit('setLoading', true)

        try {
            const { status, data } = await gitCoreApi.get('xcaret/monitor/regions', {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setRegionsList', data)
        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    async getVehiclesInfo(context, payload) {
        try {
            context.commit('setLoadingVehicles', true)
            const { status, data } = await gitCoreApi.get(
                `xcaret/adjustment/vehicles?date=${payload.date}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setVehiclesList', data)
            context.commit('setLoadingVehicles', false)
        } catch (error) {
            throw new Error(error)
        }
    },
    async getHotelsInfo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/monitor/list_hotels?date=${payload.date}&region=${payload.region}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setHotelsList', data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async getOnlyHotelsInfo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/monitor/list_only_hotels?date=${payload.date}&region=${payload.region}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setHotelsList', data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async getOnlyParksInfo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/monitor/list_only_parks?date=${payload.date}&region=${payload.region}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setParksList', data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async getRoute(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/monitor/route?date=${payload.date}&idTask=${payload.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            let parsedPendingStops = []
            if(payload.pending) {
                const newStopsPending = payload.pending.filter(pending => {
                    return data.filter(stop => stop.x === pending.longitud && stop.y === pending.latitud).length === 0
                })

                parsedPendingStops = newStopsPending.map(stop => {
                    return {
                        description: stop.hotel,
                        id: stop.id,
                        name: stop.code,
                        quantity: stop.pax,
                        startTime: stop.hour,
                        type: 'Hotel',
                        x: stop.latitude,
                        y: stop.longitude,
                        pending: true
                    }
                })
            }



            const stopsAndPending = [...data, ...parsedPendingStops]
            context.commit('setStops', stopsAndPending)
            return data
        } catch (error) {
            throw new Error(error)
        }
    }
}
