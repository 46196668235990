import gitCoreApi from '@/api/gitCoreApi'

export default {
    async getListaVehiculos(context, payload) {
        context.commit('setLoading', true, {root: true})
        try {
            const { data } = await gitCoreApi.get('/gitRoute/vehicles',{
                headers: {'Authorization': `Bearer ${payload.token}`},
                params: { warehouseName: payload.warehouseName },
            },)
            context.commit('setListaVehiculos', data)

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false, {root: true})
        }
    },

    async updateVespertino(context, payload) {
        context.commit('setLoading', true, {root: true})
        try {
            const { data } = await gitCoreApi.put(
                `/gitRoute/vehicles/vespertino`,
                { vespertino: payload.vespertino, idTask: payload.idTask },
                { headers: {'Authorization': `Bearer ${payload.token}` },
            })
            
            if (data.isError) {
                context.commit('setLoading', false, {root: true})
                return {
                    tipo: 'error',
                    mensaje: data.message,
                }
            }

            return {
                tipo: 'success',
                mensaje: data.message,
            }

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },  
}