export default {
    setActividades(state, payload) {
        state.actividades = payload
    },
    setPreguntas(state, payload) {
        state.preguntas = payload
    },
    setRespuestas(state, payload) {
        state.respuestas = payload
    },
    setListadoRespuestas(state, payload) {
        state.listadoRespuestas = payload
    },
    setLoadingRespuestas(state, payload) {
        state.loadingRespuestas = payload
    },

    limpiarDatos(state) {
        state.listadoRespuestas = []
        state.loadingRespuestas = false
        state.actividades = []
        state.preguntas = []
        state.respuestas = []
    }
}
