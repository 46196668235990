import gitCoreApi from '@/api/gitCoreApi' 
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    async descargarXlsUsuario(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('gitlogistics/user_status_tracking/create_xls', 
            {
                "date": payload.fecha,
                "access": payload.acceso ? payload.acceso : 0,
                "listOfUsers": payload.usuario ? payload.usuario : [],
                "onlyMobile": true,
                "groupDetails": true,
                "addBoxesData": payload.addBoxesData ?? false
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

        } catch (error) {
            throw new Error(error)
        }
    },

    async obtenerInformacionSeguimientoUsuario(context, payload) {
        context.commit('setLoading',true, { root: true })
        try {
            const { status, data } = await gitCoreApi.post('gitlogistics/user_status_tracking', 
            {
                "date": payload.fecha,
                "access": payload.acceso ? payload.acceso : 0,
                "listOfUsers": payload.usuario ? payload.usuario : [],
                "onlyMobile": true,
                "addBoxesData": payload.addBoxesData ?? false
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setListaSeguimientoUsuarios',data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false, { root: true })
        }
    },

    async obtenerInformacionReportes(context, payload) {
        context.commit('setLoading',true, { root: true })
        context.commit('setListaReportes', [])

        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/user_status_capture_tracking', 
            {
                "userId": payload.userId,
                "date": payload.fecha,
                "acceso": payload.acceso,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setListaReportes',data)
            
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false, { root: true })
        }
    },

    async descargarXlsInformacionReportes(context, payload) {
        let usuarios = payload.userId
        if (Array.isArray(payload.userId)) {
            usuarios = payload.userId.map(usuario => typeof usuario === "object" ? usuario.id : usuario)
        }

        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/user_status_capture_tracking/create_xls', 
            {
                "userId": usuarios,
                "date": payload.fecha,
                "acceso": payload.acceso,
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false, { root: true })
        }
    },
}