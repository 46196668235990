export class Zona {
    constructor({id, clave, estatus, fk_almacen, almacen, nombre, colonia, geocerca, cp}) {
        this.id = id
        this.clave = clave
        this.nombre = nombre
        this.estatus = estatus
        // Puede venir solo el id o el nombre del almacen
        this.fkAlmacen = fk_almacen
        this.almacen = almacen
        this.cp = cp
        this.geocerca = geocerca
        this.colonia = colonia
    }

    toMap() {
        return {
            id: this.id,
            clave: this.clave,
            nombre: this.nombre,
            descripcion: this.descripcion,
            estatus: this.estatus,
            fk_almacen: this.fkAlmacen,
            cp: this.cp,
            geocerca: this.geocerca,
            colonia: this.colonia,
        }
    
    }
}

export class ZonaPFA extends Zona {
    constructor({id, clave, estatus, fk_almacen, almacen, nombre, colonia, geocerca, cp, opciones}) {
        super({id, clave, estatus, fk_almacen, almacen, nombre, colonia, geocerca, cp})
        this.tipoZona = opciones?.tipoZona
        this.horaInicio = opciones?.horaInicio
        this.horaFin = opciones?.horaFin
        this.dias = opciones?.dias ?? []
    }

    get opciones() {
        return {
            tipoZona: this.tipoZona,
            horaInicio: this.horaInicio,
            horaFin: this.horaFin,
            dias: this.dias
        }
    }

    toMap() {
        return {
            ...super.toMap(),
            opciones: this.opciones
        }
    }
}