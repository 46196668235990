export default {
    getListaActividadesConf(state) {
        return state.listaActividades
    },
    getListaClasificaciones(state) {
        return state.listaClasificaciones
    },
    getListaPreguntas(state) {
        return state.listaPreguntas
    },
    getFkActividad(state) {
        return state.fkActividad
    },
}