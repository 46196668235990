import gitCoreApi from '@/api/gitCoreApi'

export default {
    async buscarGeocercas(context, payload) {
        context.commit('setCargando', true)
        try {
            const { status, data } = await gitCoreApi.get(
                `geofences`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setGeocercas', data)
        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setCargando', false)
        }
    },

    async crearGeocerca(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post(
                `geofences`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            return data
        } catch (error) {
            throw new Error(error)
        }
    },

    async actualizarGeocerca(context, payload) {
        try {
            const { status, data } = await gitCoreApi.put(
                `geofences/${payload.id}`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            return data
        } catch (error) {
            throw new Error(error)
        }
    }
}