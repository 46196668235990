export default {
    getInformationDashboard(state) {
        return state.informationDashboard
    },
    getResumenDashboard(state) {
        return state.resumengDashboard
    },
    getConteoVehiculos(state) {
        return state.conteoVehiculos
    },
}