import actions from './actions'
import mutations from './mutations'
import getters from './getters'
export default {
    namespaced: true,
    state() {
        return {
            actividades: [],
            preguntas: [],
            respuestas: [],
            // Los usuarios se obtienen del getter global getListaUsuarios
            // usuarios: [],
            listadoRespuestas: [],
            loadingRespuestas: false,
        }
    },
    mutations,
    actions,
    getters
}
