export default {
    async setListaRatreoUsuarios(state, payload) {
        let indexCaptura = 1
        state.rastreoUsuarios = payload.map(rastreoUsuario => {
            if (rastreoUsuario.evento === 'Captura') {
                return { ...rastreoUsuario, indexCaptura: indexCaptura++ }
            }
            return rastreoUsuario
        })
    },
    async setListaUsuariosByRol(state, payload) {
        state.listaUsuariosRol = payload
    },
    setLoadingListaUsuariosRol(state, payload) {
        state.loadingListaUsuariosRol = payload
    },
    setLoadingXlsListaUsuariosRol(state, payload) {
        state.loadingXlsListaUsuariosRol = payload
    },
}