export default {
    setSucursales(state, data) {
        state.sucursales = [...data]
    },

    setCargando(state, cargando) {
        state.cargando = cargando
    },
}

