export default {
    getRegionsList(state) {
        return state.regionsList
    },
    getRegionSelected(state) {
        return state.regionSelected
    },
    getVehiclesList(state) {
        return state.vehiclesList
    },
    getHotelsList(state) {
        return state.hotelsList
    },
    getParksList(state) {
        return state.parksList
    },
    getLoadingVehicles(state) {
        return state.loadingVehicles
    }
}
