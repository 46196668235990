export default {
    getListarPuntos(state) {
        return state.listaPuntos
    },
    getListarDetalles(state) {
        return state.listaDetalles
    },
    getListarDetallesCompania(state) {
        return state.listaDetallesCompania
    },
    getContadorCuentas(state) {
        return state.contadorCuentas
    },
    getPermisosConfGral(state) {
        return state.permisosConfGral
    },
    getListaTiposVehiculos(state) {
        return state.listaTiposVehiculos
    }
}