import gitCoreApi from '@/api/gitCoreApi'

export default {
    async cambiarEstatusPregunta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put('configuration/question/update_status_question',
                {
                    idpregunta: payload.idpregunta,
                    id_status: payload.id_status
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.id_status) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La pregunta se activo correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La pregunta se desactivo correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La pregunta se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaPreguntas', { token: payload.token, id: payload.fkactividad })
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async agregarPregunta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/question/add_question',
                {
                    dataQuestion: payload.datosPregunta,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al agregar la pregunta')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaPreguntas', { token: payload.token, id: payload.datosPregunta.fkactividad })
                mensaje = {
                    tipo: 'success',
                    titulo: 'La pregunta se ha agregado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async listaTiposPregunta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get('configuration/question/tipo_pregunta',
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener la lista de tipos de pregunta')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaTiposPregunta', data)
            return data

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async editarPregunta(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put('configuration/question/update_question',
                {
                    idpregunta: payload.datosPregunta.id,
                    dataQuestion: payload.datosPregunta,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar la pregunta')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaPreguntas', { token: payload.token, id: payload.datosPregunta.fkactividad })
                mensaje = {
                    tipo: 'success',
                    titulo: 'La pregunta se ha editado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
}