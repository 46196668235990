export default {
    getInformationgITXcaretRuteo(state) {
        return state.informationgITXcaretRuteo
    },
    getResumengITXcaretRuteo(state) {
        return state.resumengITXcaretRuteo
    },
    getDataSteps(state) {
        return state.dataSteps
    },
    getOpcionesgITXcaretRuteo(state) {
        return state.opcionesgITXcaretRuteo
    },
    getWarehousesgITXcaretRuteo(state) {
        return state.warehousesgITXcaretRuteo
    },
    getListaConfiguraciones(state) {
        return state.listaConfiguraciones
    },
    getInfoConfiguracion(state) {
        return state.infoConfiguracion
    },
    getListaProcesos(state) {
        return state.listaProcesos
    }
}