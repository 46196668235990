/**
 * Busca el reporte de costo de un vehículo (el único que se encuentra en los datos
 * que son indicados como argumento) y lo retorna en conjunto con el Id del vehículo
 * y la fecha para la que se realizó el proceso de ruteo
 * @param {Object} gitTourResponse
 * @return {Object}
 */
const obtenerReporteCostoVehiculo = (gitTourResponse) => {
    const vehicleReport = gitTourResponse.response.tourReports[0]
    const { vehicleId, costReport } = vehicleReport
    return {
        vehicleId,
        costReport,
        fecha: gitTourResponse.id
    }
}

export {
    obtenerReporteCostoVehiculo
}