export default {
    setCalendarEvents(state, payload) {
        state.calendarEvents = payload
    },
    setWorkloadName(state, payload) {
        state.workload_name = payload
    },
    setUsername(state, payload) {
        state.username = payload
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    }
}