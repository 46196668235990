export default {
    async setListaCapturas( state, payload ) {
        state.listaCapturas = payload
    },
    async setListaUsuariosByCompania( state, payload ) {
        state.listaUsuariosByCompania = payload
    },
    async setListaActividadesByCompania( state, payload ) {
        state.listaActividadesByCompania = payload
    },
    async setListaPictures( state, payload ){
        state.listaPictures = payload
    }
}