import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            cargasActivasResumen: [],
            cargaSeleccionadaResumen: null,
            vehiculoResumen: null,
            fechaResumen: null,
            listaVehiculosResumen: [],
            listaPuntoNuevo: [],
            idsTarea: [],

            cargandoCargasActivas: false
        }
    },
    mutations,
    actions,
    getters
}