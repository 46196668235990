import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            resumengITXcaretRuteo: [],
            informationgITXcaretRuteo: [],
            dataSteps: [],
            opcionesgITXcaretRuteo: [],
            warehousesgITXcaretRuteo: [],
            listaConfiguraciones: [],
            infoConfiguracion: [],
            listaProcesos: []
        }
    },
    mutations,
    actions,
    getters
}