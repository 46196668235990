export default {
    setListaActividades(state, payload) {
        state.listaActividades = payload
    },
    setListaClasificaciones(state, payload) {
        state.listaClasificaciones = payload
    },
    setListaPreguntas(state, payload) {
        state.listaPreguntas = payload
    },
    setFkActividad(state, payload) {
        state.fkActividad = payload
    },
}
