import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'

/** 
 * Almacena una función para cerrar sesión automáticamente
 * una vez que el token haya expirado
 */
let expirationTokenTimer = null;

export default {
    /**
     * Envía una petición hacia la API para obtener un token de acceso y otros datos,
     * si la respuesta es exitosa se guarda dicha información en localStorage y en
     * el estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     */
    async login(context, payload) {
        try {
            let requestData = {
                username: payload.user,
                password: payload.password,
                expiryTime: payload.rememberSession ? 72 : 6
            }
            // Si se recibe un id de usuario de GitLogistics, se modifica el cuerpo de la petición.
            // Esto permite el inicio de sesión automático de los usuarios que usan la app de forma embebida, en MyGeotab
            if (payload.gitLogisticsUserId) {
                requestData = {
                    gitLogisticsUserId: payload.gitLogisticsUserId
                }
            }
            // Petición hacia la API 
            const { status, data } = await gitCoreApi.post('/auth/login', requestData)
            // Se maneja el caso de error en la petición
            if (status !== 200) {
                throw new Error(data.error || 'Failed to authenticate, try later')
            }
            const currentDate = new Date().getTime()
            const expiresIn   = data.expiresAt - currentDate
            // Se define un timeout para ejecutar la función de autologout
            expirationTokenTimer = setTimeout(() => {
                context.dispatch('autoLogout')
            }, expiresIn)
            // Se almacena el contenido de la respuesta en local Storage para utilizarse después
            localStorage.setItem('token', data.token)
            localStorage.setItem('userName', `${data.user.nombre} ${data.user.apellidos}`)
            localStorage.setItem('userEmail', data.user.email)
            localStorage.setItem('userId', data.user.id)
            localStorage.setItem('companyId', data.user.fkcompania)
            localStorage.setItem('expirationDate', data.expiresAt)
            localStorage.setItem('geotabDatabase', data.geotabDatabase)
            localStorage.setItem('primeraVistaAlertaVeoliaRutreo', false)
            localStorage.setItem('regresaCargaArchvo', true)
            context.commit('setUser', {
                token: data.token,
                tokenExpirationDate: data.expiresAt,
                userName: `${data.user.nombre} ${data.user.apellidos}`,
                userEmail: data.user.email,
                userId: data.user.id,
                authState: 'authenticated',
                companyId: data.user.fkcompania,
                geotabDatabase: data.geotabDatabase,
            })
            //Se obtiene la configuracion general
            const payloadData = { "token": data.token, 'idCompany': data.user.fkcompania }
            await context.dispatch('obtenerRutaInicio', payloadData)
            await context.dispatch('obtenerConfiguracionGral',payloadData)
            await context.dispatch('obtenerListadoMenu',payloadData)
            await context.dispatch('obtenerListadoPermisos', payloadData)
        } catch (error) {
            throw new Error(error || 'Failed to authenticate, try later')
        }
    },
    /**
     * Evalúa si el token aún es válido (en términos de tiempo) y si los elementos almacenados
     * no son indefinidos, es decir, que no se ha cerrado sesión, si esto se cumple actualiza 
     * el timer para cerrar sesión automáticamente además del estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     */
    async autoLogin(context) {
        /** Token almacenado en localStorage */
        const storagedToken = localStorage.getItem('token')
        /** Nombre de usuario almacenado en localStorage */
        const storagedUserName = localStorage.getItem('userName')
        /** Id de usuario almacenado en localStorage */
        const storagedUserId = localStorage.getItem('userId')
        /** Id de compania almacenado en localStorage */
        const storagedCompanyId= localStorage.getItem('companyId')
        /** Email del usuario almacenado en localStorage */
        const storagedUserEmail = localStorage.getItem('userEmail')
        /** Base de datos a la que está relacionada el usuario, almacenada en localStorage */
        const storagedGeotabDatabase = localStorage.getItem('geotabDatabase')
        /** Fecha de expiración del token almacenado, expresada en milisegundos */
        const tokenExpirationDate = localStorage.getItem('expirationDate')
        /** Diferencia de tiempo (en ms) entre la fecha de expiración del token y la fecha actual */
        const expiresIn = Number(tokenExpirationDate) - new Date().getTime()
        const storagedCompaniaIdSel = localStorage.getItem('companiaIdSel')

        // Evaluación de las condiciones
        if (expiresIn > 0 && storagedToken && storagedUserName) {
            // Se actualiza el timer para cerrar sesión automáticamente
            expirationTokenTimer = setTimeout(() => {
                context.dispatch('autoLogout')
            }, expiresIn)
            // Se actualiza el estado de la app
            context.commit('setUser', {
                token: storagedToken,
                userName: storagedUserName,
                userEmail: storagedUserEmail,
                userId: storagedUserId,
                authState: 'authenticated',
                tokenExpirationDate: tokenExpirationDate,
                companyId: storagedCompanyId,
                geotabDatabase: storagedGeotabDatabase,
            })
            context.commit('setCompaniaSel', parseInt(storagedCompaniaIdSel))

            //LLenamos el estado de la app con la configuracion general
            context.dispatch('llenarEstadoConfiguracionGral')
           //LLenamos el estado de la app con los permisos de la configuracion general
            context.dispatch('llenarMenu')
            //LLenamos el estado de la app con el menu
            context.dispatch('llenarPermisos')
            // Si existen grupos activos actualmente, los coloca en el estado 
            let activeGroups = [] 
            const activeGroupsLocalStorage = localStorage.getItem('activeGroups')
            if(activeGroupsLocalStorage != 'undefined' && activeGroupsLocalStorage != 'null'){
                activeGroups = JSON.parse(activeGroupsLocalStorage)
            }
            context.dispatch('changeUserDataAccess', activeGroups)
        }
    },
    /**
     * Envía una petición hacia el servidor para refrescar el token de acceso e
     * invalidar el token previo, si la respuesta es exitosa, actualiza los datos
     * en localStorage y en el estado de la app, en caso de error se llama a la
     * acción para cerrar sesión
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload 
     */
    async refreshToken (context, payload) {
        try {
            const { status, data } = await gitLogisticsApi.post('/auth/refresh', {}, {
                headers: { 
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                }
            })
            
            if (status !== 200) {
                throw new Error(data.error || 'Failed to logout, try later')
            }

            const userName = localStorage.getItem('userName')
            const userId = localStorage.getItem('userId')
            const companyId = localStorage.getItem('companyId')
            const userEmail = localStorage.getItem('userEmail')
            const geotabDatabase = localStorage.getItem('geotabDatabase')
            /** Tiempo en milisegundos que el token es válido */
            const expiresIn = Number(data.expires_in) * 1000
            /** Fecha de expiración del nuevo token, expresada en milisegundos */
            const newTokenExpirationDate = new Date().getTime() + expiresIn
            
            // Se resetea el timer para cerrar sesión en automático
            expirationTokenTimer = setTimeout(() => {
                context.dispatch('autoLogout')
            }, expiresIn)

            // Se actualiza el Token en localStorage y en el estado de la app
            localStorage.setItem('token', data.access_token)
            context.commit('setUser', {
                userName,
                userId,
                geotabDatabase,
                userEmail,
                token: data.access_token,
                authState: 'authenticated',
                tokenExpirationDate: newTokenExpirationDate,
                companyId
            })
        } catch (error) {
            context.dispatch('logout')
            throw new Error(error || "Failed to refresh token, you'll be logged out")
        }
    },
    /**
     * Envía una petición hacia la API para invalidar el token que se envía como
     * encabezado
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload 
     */
    async logout(context, payload) {
        try {
            context.commit('setTheme', true)
            // Se reinicia el timer
            clearTimeout(expirationTokenTimer)
            // Se actualiza el estado de la app
            context.commit('setUser', {
                token: null,
                tokenExpirationDate: null,
                userName: null,
                userEmail: null,
                userId: 0,
                authState: 'unauthenticated',
                companyId: 0,
                geotabDatabase: null
            })
            //Eliminamos la configuracionGeneral
            context.dispatch('eliminarConfiguracionGral')
            context.dispatch('eliminarMenu')
            context.dispatch('eliminarPermisos')
            context.dispatch('eliminaRutaInicio')
            context.dispatch('eliminarCompaniaSeleccionada')
            context.dispatch('setLogOutUser', payload)
            context.commit('setCargasActivasResumen', [])
            context.commit('setCargaSeleccionadaResumen', null)
            context.commit('setActiveGroups', [])
            context.commit('setCompaniaSel', 0)
            // Se borran los elementos de localstorage
            localStorage.clear()
            return
        } catch (error) {
            throw new Error(error || 'Failed to logout, try later')
        }
    },
    /** Invoca a la acción para cerrar sesión */
    autoLogout(context) {
        context.dispatch('logout')
    },
    /**
     * Compara la diferencia de tiempo en milisegundos entre la fecha de expiracion establecida
     * y la fecha actual, si dicha diferencia es menor a la cantidad equivalente a una hora,
     * se invoca la acción para refrescar el token
     * @param {*} context - Métodos y propiedades del store
     */
    checkTokenValidity(context) {
        /** Fecha de expiración del token */
        const tokenExpirationDate = context.getters.tokenExpirationDate
        /** Fecha actual en milisegundos */
        const currentDate = new Date().getTime()
        /** Diferencia de tiempo en milisegundos */
        const msDifference = tokenExpirationDate - currentDate
        // Comparación de la diferencia de tiempo
        if (msDifference < 3600000) {
            context.dispatch('refreshToken')
        }
    },
    async redirectedLogin(context, payload) {
        const { params } = payload
        if (Object.keys(params).length > 0 && !Object.values(params).includes(undefined)) {
            const gitLogisticsUserId = params.userId
            if (gitLogisticsUserId && gitLogisticsUserId.length === 16) {
                const requestData = { gitLogisticsUserId }
                return context.dispatch('login', requestData)
            } else {
                throw new Error('No se pudo realizar el login automático')
            }
        }
    },

    changeUserDataAccess(context, payload) {
        localStorage.setItem('activeGroups', JSON.stringify(payload))
        context.commit('setActiveGroups', payload)
    },
    async validateCaptchaToken(_, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/auth/verifyTurnstileToken', { token: payload })
            if (status !== 200) {
                throw new Error(data.error || 'Failed to validate captcha token')
            }
            return data.success
        } catch (error) {
            throw new Error(error || 'Failed to validate captcha token')
        }
    },
    async setLogOutUser(context, payload) {
        try {
            const { status } = await gitCoreApi.post('/auth/setLogOutUser', {
                token: payload.token
            })
            
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    }
}