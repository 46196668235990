import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/index'

const MainLayout = () => import(/* webpackChunkName: "Layout-Principal" */ '@/modules/layouts/MainLayout')
/** Importación del componente DashboardPrincipal */
const DashboardPrincipal = () => import(/* webpackChunkName: "Dashboard-Principal" */ '@/modules/dashboardGitLogistics/pages/DashboardPrincipal')
/** Importación del componente Resumen */
const ResumenPage = () => import(/* webpackChunkName: "resumen-page" */ '@/modules/resumenGitLogistics/pages/ResumenPage')
/** Importación del componente para el flujo principal */
const Reasignacion = () => import(/* webpackChunkName: "reasignacion-page" */ '@/modules/reasignacionGitLogistics/pages/ReasignacionPage')
/** Importación del componente para el flujo principal */
const ProcesoPage = () => import(/* webpackChunkName: "ProcesoPage" */ '@/modules/gitLogistics/pages/ProcesoPage')
/** Importación del componente NotFoundPage */
const NotFoundPage = () => import(/* webpackChunkName: "NotFoundPage" */ '@/modules/shared/components/NotFoundPage')
/** Importación del componente Login */
const Login = () => import(/* webpackChunkName: "Login" */ '@/modules/login/components/Login')
/** Importación del componente Logout */
const Logout = () => import(/* webpackChunkName: "Logout" */ '@/modules/login/components/Logout')
/** Importación del componente CargaArchivo*/
const CargaArchivo = () => import(/* webpackChunkName: "CargaArchivo" */ '@/modules/gitLogistics/cargaArchivo/components/CargaArchivo')
/** Importación del componente MatrizDistancia*/
const MatrizDistancia = () => import(/* webpackChunkName: "MatrizDistancia" */ '@/modules/gitLogistics/xDima/pages/MatrizDistancia')
/** Importación del componente GitCluster*/
const GitClusterPage = () => import(/* webpackChunkName: "Gitcluster" */ '@/modules/gitLogistics/gitCluster/pages/GitClusterPage')
/** Importación del componente GitTour*/
const GitTourPage = () => import(/* webpackChunkName: "GitTour" */ '@/modules/gitLogistics/gitTour/pages/GitTourPage')
/** Importación del componente GitVisits*/
const GitVisitsPage = () => import(/* webpackChunkName: "GitVisits" */ '@/modules/gitLogistics/gitVisits/pages/GitVisitsPage')
/** Importación del componente ConsultaPuntosPage */
const ConsultaPuntosPage = () => import(/* webpackChunkName: "ConsultaPuntosPage" */ '@/modules/consultaPuntos/pages/ConsultaPuntosPage')
/** Importación del componente ConsultaRespuestassPage */
const ConsultaRespuestasPage = () => import(/* webpackChunkName: "ConsultaRespuestasPage" */ '@/modules/consultaRespuestas/pages/ConsultaRespuestaPage')
/** Importación del componente DetallePuntoPage */
const DetallePuntoPage = () => import(/* webpackChunkName: "ConsultaPuntosPage" */ '@/modules/consultaPuntos/pages/DetallePuntoPage')

///Ruta telematics
//const TelematicsCompanySetup = () => import('@/modules/telematics/pages/CompanySetupPage')
/** Importacion del componente configuracionGITLogistics */
const ConfiguracionGITLogistics = () => import(/* webpackChunkName: "ConsultaPuntosPage" */ '@/modules/configuracion/pages/ConfiguracionGitLogisticsPage')
/** Importación del componente Configuracion Almacenes */
const AlmacenesPage = () => import(/* webpackChunkName: "AlmacenesPage" */ '@/modules/configuracion/almacenes/pages/AlmacenesPage')
/** Importación del componente Configuracion Almacenes */
const ColoniasCpsPage = () => import(/* webpackChunkName: "ColoniasPage" */ '@/modules/configuracion/coloniasCps/pages/ColoniasCpsPage')
/** Importación del componente Configuracion UsuariosPage */
const UsuariosPage = () => import(/* webpackChunkName: "UsuariosPage" */ '@/modules/configuracion/usuarios/pages/UsuariosPage')
/** Importación del componente Configuracion ZonasPage */
const ZonasPage = () => import(/* webpackChunkName: "ZonasPage" */ '@/modules/configuracion/zonas/pages/ZonasPage')
/** Importación del componente Configuracion ActividadesTiposPage */
const ActividadesTiposPage = () => import(/* webpackChunkName: "ActividadesTiposPage" */ '@/modules/configuracion/tiposTrabajo/pages/ActividadesTiposPage')
/** Importación del componente Configuracion TareasPage */
const TareasPage = () => import(/* webpackChunkName: "TareasPage" */ '@/modules/configuracion/tareas/pages/TareasPage')
/** Importación del componente Configuracion TareasAppPage */
const TareasAppPage = () => import(/* webpackChunkName: "TareasAppPage" */ '@/modules/configuracion/tareasApp/pages/TareasAppPage')
/** Importación del componente Configuracion TareasAppPage */
const PtvPaquetesPage = () => import(/* webpackChunkName: "TareasAppPage" */ '@/modules/configuracion/ptvPaquetes/pages/PaquetesPage')
/** Importación del componente Configuracion PtvTipoServicio */
const PtvTipoServicioPage = () => import(/* webpackChunkName: "PtvTipoServicioPage" */ '@/modules/configuracion/ptvTipoServicio/pages/PtvTipoServicioPage')
/** Importación del componente Configuracion PermisosMenus */
//const PermisosMenusPage = () => import(/* webpackChunkName: "MenusPage" */ '@/modules/configuracion/permisosMenu/pages/PermisosMenusPage')
/** Importación del componente Configuracion Menu SubMenus */
const MenusSubMenusPage = () => import(/* webpackChunkName: "MenusPage" */ '@/modules/configuracion/menusSubMenus/pages/MenusSubMenusPage')
/** Importación del componente Configuracion UsuarioGlobal */
const UsuariosGlobalPage = () => import(/* webpackChunkName: "UsuariosPage" */ '@/modules/configuracion/usuariosGlobal/pages/UsuariosGlobalPage')
/** Importación del componente Configuracion UsuarioGlobal */
//const RolesPage = () => import(/* webpackChunkName: "UsuariosPage" */ '@/modules/configuracion/roles/pages/RolesPage.vue')
/** Importación del componente Roles Permisosl */
const RolesPermisosPage = () => import(/* webpackChunkName: "RolesPermisos" */ '@/modules/configuracion/rolesPermisos/pages/RolesPermisosPage')
/** Importación del componente Configuracion RolesPermisos/PermisosAplicacion */
const AplicacionesPermisosPage = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/permisosAplicaciones/pages/AplicacionesPermisosPage')
/** Importación del componente Configuracion Puntos */
const Puntos = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/puntos/pages/PuntosPage')
/** Importación del componente Configuracion Actividades */
const Actividades = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/actividades/pages/ActividadesPage')
/** Importación del componente Configuracion Preguntas */
const Preguntas = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/preguntas/pages/PreguntasPage')
/** Importación del componente Respuestas */
const Respuestas = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/respuestas/pages/RespuestasPage')
/** Importación del componente Configuracion Listado de configuraciones */
const ListadoConfiguraciones = () => import(/* webpackChunkName: "ListadoConfiguraciones" */ '@/modules/configuracion/actividades/components/ClasificacionesLista')
/** Importación del componente Configuracion Listado de actividades */
const ActividadesLista = () => import(/* webpackChunkName: "ActividadesLista" */ '@/modules/configuracion/actividades/components/ActividadesLista')
/** Importación del componente Configuracion RolesPermisos/PermisosMenu */
//const PermisosMenuPage = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/configuracion/rolesPermisos/permisosMenu/pages/PermisosMenusPage')
/** Importación del componente Configuracion RolesPermisos/PermisosAplicacion */
//const PermisosAplicacionPage = () => import(/* webpackChunkName: "PermisosAplicacion" */ '@/modules/configuracion/rolesPermisos/permisosMenu/pages/PermisosAplicacionPage')
/** Importación del componente Configuracion Geocercas */
const GeocercasPage = () => import(/* webpackChunkName: "ColoniasPage" */ '@/modules/configuracion/geocercas/pages/GeocercasPage')
/** Importación del componente Configuracion Sucursales */
const SucursalesPage = () => import(/* webpackChunkName: "ColoniasPage" */ '../modules/configuracion/sucursales/pages/SucursalesPage')


/** Importación del componente MonitorRuteoPage */
const MonitorRuteoPage = () => import(/* webpackChunkName: "ActividadesTiposPage" */ '@/modules/monitorRuteo/pages/MonitorRuteoPage')
const SeguimientoEventosLogisticos = () => import(/* webpackChunkName: "SeguimientoEventosLogisticos" */ '@/modules/seguimientoEventosLogisticos/SeguimientoEventosLogisticos')

const EmpresasTelematics = () => import(/* webpackChunkName: "EmpresasTelematics" */ '@/modules/empresasTelematics/pages/SeguimientoEmpresas')
/** Importación del componente Seguimiento Tiempo Real */
const SeguimientoTiempoReal = () => import(/* webpackChunkName: "PermisosMenu" */ '@/modules/seguimientoTiempoReal/SeguimientoTiempoReal')
/** Arreglo con los objetos de rutas */
const Uniparques = () => import(/* webpackChunkName: "Uniparques" */ '@/modules/seguimientoTiempoReal/components/Uniparques')
/** Arreglo con los objetos de rutas */
const Biparques = () => import(/* webpackChunkName: "Biparques" */ '@/modules/seguimientoTiempoReal/components/Biparques')
/** Arreglo con los objetos de rutas */
const Triparques = () => import(/* webpackChunkName: "Triparques" */ '@/modules/seguimientoTiempoReal/components/Triparques')
/** Arreglo con los objetos de rutas */

/** Importacion del componente localizacions */
const Localizacion = () => import(/* webpackChunkName: "ConsultaPuntosPage" */ '@/modules/localizacion/pages/LocalizacionPage.vue')
/** Importación del componente RastreoUsuarios */
const RastreoUsuarios = () => import(/* webpackChunkName: "RastreoUsuarios" */ '@/modules/localizacion/rastreoUsuarios/pages/RastreoUsuariosPage.vue')
/** Importación del componente Galeria */
const Galeria = () => import(/* webpackChunkName: "Galeria" */ '@/modules/galeria/pages/GaleriaPage')
/** Importación del componente Fotos */
const Fotos = () => import(/* webpackChunkName: "Fotos" */ '../modules/galeria/fotos/pages/Fotos.vue')
/** Importacion del componente configuracionGITRoute */
const configuracionGITRoute = () => import(/* webpackChunkName: "configuracionGITRoute" */ '@/modules/configuracion/pages/ConfiguracionGitRoute.vue')

const Calendar = () => import(/* webpackChunkName: "Calendar" */ "@/modules/calendar/pages/CalendarPage")


const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            { path: 'logout', name: 'logout', component: Logout },
            { path: 'dashboard', name: 'dashboard', component: DashboardPrincipal },
            { path: 'resumen', name: 'resumen', component: ResumenPage },
            { path: '', redirect: { name: 'resumen' } },
            {
                path: 'proceso',
                name: 'proceso',
                component: ProcesoPage,
                children: [
                    { path: 'cargaArchivo', name: 'cargaArchivo', component: CargaArchivo },
                    { path: 'xDima', name: 'xDima', component: MatrizDistancia },
                    { path: 'gitCluster', name: 'gitCluster', component: GitClusterPage },
                    { path: 'gitTour', name: 'gitTour', component: GitTourPage },
                    { path: 'gitVisits', name: 'gitVisits', component: GitVisitsPage },
                    { path: 'proceso', redirect: { name: 'cargaArchivo' }, alias: ['', 'cargaArchivo', 'xDima', 'gitCluster', 'gitTour', 'gitVisits'] }
                ]
            },
            { path: 'consultaPuntos', name: 'consultaPuntos', component: ConsultaPuntosPage },
            { path: 'consultaRespuestas', name: 'consultaRespuestas', component: ConsultaRespuestasPage },
            { path: 'detallePuntoPage', name: 'detallePuntoPage', component: DetallePuntoPage },
            { path: 'reasignacion', name: 'reasignacion', component: Reasignacion },
            {
                path: 'configuraciongITLogistics',
                name: 'configuraciongITLogistics',
                component: ConfiguracionGITLogistics,
                children: [
                    { path: 'usuarios', name: 'usuarios', component: UsuariosPage },
                    { path: 'tareas', name: 'tareas', component: TareasPage },
                    { path: 'tiposTrabajo', name: 'tiposTrabajo', component: ActividadesTiposPage },
                    { path: 'geocercas', name: 'geocercas', component: GeocercasPage },
                    { path: 'permisos_aplicacion', name: 'permisos_aplicacion', component: AplicacionesPermisosPage },
                    { path: 'menus_sub_menus', name: 'menus_sub_menus', component: MenusSubMenusPage },
                    { path: 'usuarios_global', name: 'usuarios_global', component: UsuariosGlobalPage },
                    { path: 'roles_permisos', name: 'roles_permisos', component: RolesPermisosPage },
                    { path: 'puntos', name: 'puntos', component: Puntos },
                    { path: 'configuraciongITLogistics', redirect: { name: 'tareas'}, alias: ['tareas', 'usuarios', 'zonas', 'almacenes', 'tiposTrabajo', 'colonias','menus_sub_menus','usuarios_global','roles_permisos','puntos', 'tareasApp', 'geocercas', 'ptvTipoServicio']},
                    { path: 'actividades', name: 'actividades', component: Actividades,
                        children: [
                            { path: 'listado_configuraciones', name: 'listado_configuraciones', component: ListadoConfiguraciones },
                            { path: 'listado_actividades', name: 'listado_actividades', component: ActividadesLista },
                        ]
                    },
                    { path: 'tareasApp', name: 'tareasApp', component: TareasAppPage },
                    { path: 'sucursales', name: 'sucursales', component: SucursalesPage },
                    { path: 'ptvPaquetes', name: 'ptvPaquetes', component: PtvPaquetesPage },
                    { path: 'ptvTipoServicio', name: 'ptvTipoServicio', component: PtvTipoServicioPage },
                    { path: 'preguntas', name: 'preguntas', component: Preguntas },
                    { path: 'respuestas', name: 'respuestas', component: Respuestas },
                ]
            },
            {
                path: 'configuraciongITRoute', 
                name: 'configuraciongITRoute', 
                component: configuracionGITRoute,
                children: [
                    { path: 'zonas', name: 'zonas', component: ZonasPage },
                    { path: 'almacenes', name: 'almacenes', component: AlmacenesPage },
                    { path: 'colonias', name: 'colonias', component: ColoniasCpsPage },
                    { path: 'tareasApp', name: 'tareasApp', component: TareasAppPage },
                    { path: 'sucursales', name: 'sucursales', component: SucursalesPage },
                    { path: 'ptvPaquetes', name: 'ptvPaquetes', component: PtvPaquetesPage },
                    { path: 'ptvTipoServicio', name: 'ptvTipoServicio', component: PtvTipoServicioPage },
                ]
            },
            { path: 'monitorRuteo', name: 'monitorRuteo', component: MonitorRuteoPage },
            {
                path: 'resumenPage', name: 'resumenPage', component: ResumenPage,
                children: [
                    { path: 'resumen', name: 'resumen', component: () => import('@/modules/resumenGitLogistics/components/Resumen') },
                    { path: 'ruta_programada', name: 'ruta_programada', component: () => import('@/modules/resumenGitLogistics/components/VehiclesTime') },
                ]
            },
            { path: 'reasignacion', name: 'reasignacion', component: Reasignacion },
            { path: 'forzarProceso', name: 'forzarProceso', component: () => import('@/modules/reasignacionGitLogistics/pages/ForzarProceso') },
            { path: 'seguimientoEventos', name: 'seguimientoEventos', component: SeguimientoEventosLogisticos },
            { path: 'empresasTelematics', name: 'empresasTelematics', component: EmpresasTelematics },
            {
                path: 'gITXcaret', name: 'gITXcaret', component: () => import('@/modules/gITXcaret/pages/GitXcaretPage'),
                children: [
                    { path: 'torre_control', name: 'torre_control', component: () => import('@/modules/gITXcaret/torre/pages/TorrePage') },
                    { path: 'carga', name: 'carga', component: () => import('@/modules/gITXcaret/carga/pages/CargaPage') },
                    { path: 'ruteo', name: 'ruteo', component: () => import('@/modules/gITXcaret/ruteo/pages/RuteoPage') },
                    { path: 'ajustes', name: 'ajustes', component: () => import('@/modules/gITXcaret/ajustes/pages/AjustesPage') },
                    // { path: 'monitoreo', name: 'monitoreo', component: () => import('@/modules/gITXcaret/monitoreo/pages/MonitoreoPage') },
                    { path: 'monitoreo', name: 'monitoreo', component: () => import('@/modules/gITXcaret/monitoreo/pages/MonitoreoPageV2') },
                ]
            },
            { path: 'kpisAlertas', name: 'kpisAlertas', component: () => import('@/modules/kpisAlertas/page/kpisAlertasPage') },
            {
                path: 'dashboard', name: 'dashboard', component: () => import('@/modules/gITXcaret/dashboard/pages/Dashboard'),
                children: [
                    { path: 'dashboardVehiculos', name: 'dashboardVehiculos', component: () => import('@/modules/gITXcaret/dashboard/components/DashboardVehiculos') },
                    { path: 'dashboardPax', name: 'dashboardPax', component: () => import('@/modules/gITXcaret/dashboard/components/DashboardPax') },
                ]
            },
            {
                path: 'matriz', name: 'matriz', component: () => import('@/modules/matrizDistancia/pages/MatrizDistanciaPage'),
                children: [
                    { path: 'clusters', name: 'clusters', component: () => import('@/modules/matrizDistancia/clusters/pages/ClustersPage') },
                    { path: 'matrizDistancia', name: 'matrizDistancia', component: () => import('@/modules/matrizDistancia/matrizDistancia/pages/MatrizDistanciaPage') },
                ]
            },
            {
                path: 'seguimientoTiempoReal',
                name: 'seguimientoTiempoReal',
                component: SeguimientoTiempoReal,
                children: [
                    { path: 'uniparques', name: 'uniparques', component: Uniparques },
                    { path: 'biparques', name: 'biparques', component: Biparques },
                    { path: 'triparques', name: 'triparques', component: Triparques },
                ]
            },
            {
                path: 'galeria', 
                name: 'galeria', 
                component: Galeria,
                children: [
                    { path: 'fotos', name: 'fotos', component: Fotos },
                    { path: '', redirect: { name: 'fotos'}, alias: ['fotos']}
                ]
            },
            {
                path: 'localizacion',
                name: 'localizacion',
                component: Localizacion,
                children: [
                    { path: 'rastreo_usuarios', name: 'rastreo_usuarios', component: RastreoUsuarios },
                    { path: 'monitoreo_usuarios', name: 'monitoreo_usuarios', component: () => import('@/modules/MonitoreoUsuarios/pages/MonitoreoUsuariosPage.vue') },
                    { path: '', redirect: { name: 'rastreo_usuarios' }, alias: ['rastreo_usuarios'] },
                ]
            },
            {
                path: 'seguimiento_estatus',
                name: 'seguimiento_estatus',
                component: () => import('@/modules/seguimientoEstatus/pages/SeguimientoEstatusPage'),
                children: [
                    { path: 'usuarios', name: 'seguimiento_usuarios', component: () => import('@/modules/seguimientoEstatus/SeguimientoUsuarios/pages/SeguimientoUsuariosPage.vue') },
                    { path: 'puntos', name: 'seguimiento_puntos', component: () => import('@/modules/seguimientoEstatus/SeguimientoPuntos/pages/SeguimientoPuntosPage.vue') },
                ]
            },
            { path: 'reportes', name: 'reportes', component: () => import('@/modules/gitLogistics/reportes/pages/ReportesPage.vue') },
            {
                path: 'gitRoute',
                name: 'gitRoute',
                component: () => import('@/modules/gitLogistics/gitRoute/pages/GitRoutePage'),
                children: [
                    { path: 'vehiculos', name: 'gitRouteVehiculos', component: () => import('@/modules/gitLogistics/gitRoute/vehiculos/pages/VehiculosPage') },
                    { path: 'carga_archivo', name: 'gitRouteCargaArchivo', component: () => import('@/modules/gitLogistics/gitRoute/cargaArchivo/pages/CargaArchivoPage') },
                    { path: 'vista_previa', name: 'gitRouteVistaPrevia', component: () => import('@/modules/gitLogistics/gitRoute/vistaPrevia/pages/VistaPreviewPage') },
                    { path: 'ruteo', name: 'gitRouteRuteo', component: () => import('@/modules/gitLogistics/gitRoute/ruteo/pages/RuteoPage') },
                ]
            },
            { path: 'auditoria_usuarios', name: 'auditoria_usuarios', component: () => import('@/modules/usuarios/pages/AuditoriaUsuariosPage.vue') },
        ],
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { requiresAuth: false },
        children: [
            {
                path: 'user/:userId',
                name: 'redirectedLogin',
                component: Login,
                beforeEnter: async (to, _, next) => {
                    try {
                        await store.dispatch('redirectedLogin', to)
                        next({ name: 'resumen' })
                    } catch (error) {
                        next()
                    }
                }
            },
        ],
    },
    {
        path: '/calendar', 
        name: 'calendar', 
        component: Calendar, 
        meta: {requiresAuth: false},
    },
    {
        path: '/detallePuntoLinkPage',
        name: 'detallePuntoLinkPage',
        component: DetallePuntoPage
    },
    { path: '/:pathMatch(.*)*' ,component: NotFoundPage }
]

/** Se realiza el intento de autologin */
store.dispatch('autoLogin')

/** Instancia del router */
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

/** Guard global de navegación */
router.beforeEach(function (to, from, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next({ name: 'login' })
    } else if (store.getters.isAuthenticated && to.name === 'detallePuntoLinkPage') {
        next({ name: 'detallePuntoPage', query: { ...to.query }})
    } else if (!to.meta.requiresAuth && store.getters.isAuthenticated && to.name !== 'redirectedLogin') {
        next(false)
    } else if (to.path === from.path || redireccionamientoConfiguracion(to, from)) {
        next(false)
    } else {
        next()
    }
})

const redireccionamientoConfiguracion = (to, from) => {
    const fromSplit = from.path.split("/")
    const toSplit = to.path.split("/")
    return toSplit.at(1) === fromSplit.at(1) && fromSplit.at(1) === 'configuraciongITLogistics' && toSplit.length <= 2
}

export default router