// import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'

/* eslint-disable */
export default {
    /**
     * Envía una petición hacia la API para obtener la configuracion general,
     * si la respuesta es exitosa se guarda dicha información en localStorage y en
     * el estado de la aplicación
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     */
    async subirArchivo(context,payload) {      
        context.commit('setLoading',true)

        const solicitud = new FormData()
        solicitud.append('file', payload.archivo)
        solicitud.append('reemplazarCarga', payload.reemplazarCarga)
        solicitud.append('forbiddenDates', payload.fechasProhibidas)
        solicitud.append('enableAutomaticAreas', payload.recordatorioAreasAutomaticas)
        solicitud.append('averageIntensity', payload.intencidadPromedio)
        solicitud.append('workDays', payload.diasLaborales)

        // Petición hacia la API para opbtener la configuracion
        const { status, data } = await gitCoreApi.post('/process/process_uploaded_file', solicitud, {
            headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        // Se maneja el caso de error en la petición
        if (status !== 200 || data.error) {
            console.error(data)
            const error = new Error(data.message || 'Error al subir el archivo')
            context.commit('setLoading',false)
            throw error
        }

        if(Object.prototype.hasOwnProperty.call(data, 'showErrorMessaje') && data.showErrorMessaje){
            context.commit('setLoading',false)
            return data
        }

        if (data.conflict_load) {
            context.commit('setLoading',false)
            return data
        }

        if (data.idProceso === -1) {
            context.commit('setLoading',false)
            return data.respuesta
        }

        const {puntos,vehiculos,opciones,almacenes,noPlanificados,puntosConFrecuenciasNoPlanificables} = data.respuesta

        context.commit(
            'setInfoArchivo',{
                infoPuntos: puntos,
                infoVehiculos: vehiculos,
                infoOpciones: opciones,
                infoAlmacenes: almacenes ? almacenes : [],
                infoNoPlanificados: noPlanificados ? noPlanificados : [],
                infoPuntosConFrecuenciasNoPlanificables: puntosConFrecuenciasNoPlanificables || []
            }
        )
        context.commit('setIdPlaneacion', data.idProceso)
        context.commit('setLoading',false)
        return 'ok'
    },

    async obtenerHistoricoPlaneacion(context,payload) {
        context.commit('setLoading',true)

        const { status, data } = await gitCoreApi.post('/gitlogistics/load_file/get_loads', {
            gruposActivos: payload.gruposActivos
        }, {
            headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-Type': 'application/json'
            }
        })

        if (status !== 200) {
            console.error(data)
            const error = new Error(data.message || 'Error al subir el archivo')
            context.commit('setLoading',false)
            throw error
        }
        
        context.commit('setCargas', data)
        context.commit('setLoading',false)
    },

     /**
     * Actualiza el estado de la variable setInfoArchivo
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     */
    async actualizaArchivo(context,payload){
        try {
            context.commit('setLoading', true)
            const {puntos,vehiculos,opciones,almacenes,noPlanificados, token, idPlaneacion} = payload

            context.commit(
                'setInfoArchivo',{
                    infoPuntos: puntos,
                    infoVehiculos: vehiculos,
                    infoOpciones: opciones,
                    infoAlmacenes: almacenes,
                    infoNoPlanificados: noPlanificados
                }
            )

            const { status , data } = await gitCoreApi.post('/gitlogistics/load_file/update_load', {
                fkProcess: idPlaneacion,
                points: puntos,
                werehouses: almacenes,
                vehicles: vehiculos
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error(data.error || data)
            }

        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },

    guardarConfiguracionGITLogistics(context, payload){
        context.commit('setConfiguracionPlaneacion', payload)
        localStorage.setItem('configuracionPlaneacion', JSON.stringify(payload))
    }
}