export default {
    getLoadingNoPlanificados(state) {
        return state.loadingNoPlanificados
    },
    getListaAreasReasignacion(state) {
        return state.listaAreasReasignacion
    },
    getListaNoPlanificadosReasignacion(state) {
        return state.listaNoPlanificadosReasignacion
    },
    getListaRutasReasignacion(state) {
        return state.listaRutasReasignacion
    },
    getListaVehiculosReasignacion(state) {
        return state.listaVehiculosReasignacion
    },
    getCargasReasignacion(state) {
        return state.cargasReasignacion
    },
    getVehiculoReasignacion(state) {
        return state.vehiculoReasignacion
    },
    getDiasModificadosReasignacion(state) {
        return state.diasModificadosReasignacion
    },
    solicitudPlanTourReasignacion(state) {
        return state.solicitudPlanTourReasignacion
    },
    respuestaPlanTourReasignacion(state) {
        return state.respuestaPlanTourReasignacion
    },
    getIsNecesitaGuardarInformacion(state) {
        return state.isNecesitaGuardarInformacion
    },
    getPuntosMarcadosReasignacion(state) {
        return state.puntosMarcadosReasignacion
    },
    getErroresReasignacion(state) {
        return state.erroresReasignacion
    },
    getListaCodigosPuntoNoPlanificables(state) {
        return state.listaCodigosPuntoNoPlanificables
    },
    reportesCostoTareas(state) {
        return state.reportesCostoTareas
    },
    almacenesReasignacion(state) {
        return state.almacenesReasignacion
    },
    getListaViajesReasignacion(state) {
        return state.listaViajesReasignacion
    },
    getFechaSinDia(state) {
        return state.fechaSinDia
    },
    getListaBreak(state) {
        return state.listaBreak
    },
    getInsertaBreak(state) {
        return state.insertaBreak
    },
    getEliminarBreak(state) {
        return state.eliminarBreak
    },
    getActualizarBreak(state) {
        return state.actualizarBreak
    },
    getIdEliminarBreak(state) {
        return state.idEliminarBreak
    },
    getIdVehiculoSeleccionadoReasignacion(state) {
        return state.idVehiculoSeleccionadoReasignacion
    },
    getTerminoAplicarCambios(state) {
        return state.terminoAplicarCambios
    },
    getSemaforoConfiguracion(state) {
        return state.semaforoConfiguracion
    }
}