export default {
    setRegionsList(state, payload) {
        state.regionsList = payload
    },
    setRegionSelected(state, payload) {
        state.regionSelected = payload
    },
    setVehiclesList(state, payload) {
        state.vehiclesList = payload
    },
    setHotelsList(state, payload) {
        state.hotelsList = payload
    },
    setParksList(state, payload) {
        state.parksList = payload
    },
    setLoadingVehicles(state, payload) {
        state.loadingVehicles = payload
    }
}
