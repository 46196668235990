export default {
    setListaTareas(state, payload) {
        state.listaTareas = payload
    },
    setListaZonas(state, payload) {
        state.listaZonas = payload
    },
    setListaAlmacenes(state, payload) {
        state.listaAlmacenes = payload
    },
    setListaTipos(state, payload) {
        state.listaTipos = payload
    }
}

