import gitCoreApi from '@/api/gitCoreApi' 

/* eslint-disable */
export default {
    async obtenerInformaciongITXcaretCarga(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status, data } = await gitCoreApi.get('xcaret/load/information', {
                params: {
                    date: payload.fecha,
                    regions: payload.region
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setInformationgITXcaretCarga',data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async subirPaxsgITXcaretCarga(context, payload) {
        context.commit('setLoading',true)
        try {
            const { status, data  } = await gitCoreApi.post('xcaret/load/interface', {
                date: payload.fecha,
                park: 'ALL'
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setLoading',false)
            return data
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    
}