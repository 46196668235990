import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            infoPuntos: [],
            infoVehiculos: [],
            infoOpciones: [],
            infoAlmacenes: [],
            infoPuntosConFrecuenciasNoPlanificables: [],
            configuracionPlaneacion: {
                gITCluster:{
                    desvalanceArea: 100,
                    superposicion: false
                },
                gITVisit:{
                    desvalanceDiario: 100,
                    desvalanceSemanal: 100
                }
            }
        }
    },
    mutations,
    actions,
    getters
}