import xServerApi from '@/api/xServerApi'
import store from "../store/index"
import { Buffer } from 'buffer'

const tiposTrabajo = {
  cluster: {
    endpoint: 'XCluster/2.31',
    prefijoRespuesta: 'fetchClustersResponse',
    obtenerError: 'guardarRespuestaPlanClusterError',
    obtenerInformacion: 'guardarRespuestaPlanCluster'
  },
  tour: {
    endpoint: 'XTour/2.31',
    prefijoRespuesta: 'fetchToursResponse',
    obtenerError: 'setPlanTourRequestError',
    obtenerInformacion: 'setPlanTourRequest'
  },
  tourMod: {
    endpoint: 'XTour/2.31',
    prefijoRespuesta: 'fetchToursResponse',
    obtenerInformacion: 'setPlanTourRequestMod'
  },
  tourReasignacion: {
    endpoint: 'XTour/2.31',
    prefijoRespuesta: 'fetchToursResponse',
    obtenerError: 'setPlanTourRequestReasignacionError',
    obtenerInformacion: 'setPlanTourRequestReasignacion'
  },
  visits: {
    endpoint: 'XCluster/2.31',
    prefijoRespuesta: 'fetchVisitsResponse',
    obtenerError: 'guardarRespuestaPlanVisitsError',
    obtenerInformacion: 'guardarRespuestaPlanVisits'
  },
  xDima: {
    endpoint: 'XDima/2.31',
    prefijoRespuesta: 'fetchDistanceMatrixResponse',
    obtenerError: 'guardarRespuestaXDimaError',
    obtenerInformacion: 'guardarRespuestaXDima'
  }
}

/** 
 * Retorna una promesa que se resuelve en la cantidad de tiempo especificada
 * @param { Number } ms - milisegundos a esperar
 */
const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default class XServerJob {
  constructor (tipoTrabajo, identificador) {
    const tipo = tiposTrabajo[tipoTrabajo]
    this.TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
    this.id = identificador
    this.endpoint = tipo.endpoint
    this.prefijoRespuesta = tipo.prefijoRespuesta
    this.obtenerInformacion = tipo.obtenerInformacion
    this.obtenerError = tipo.obtenerError
  }

  async statusJob(data) {
    const requestData = {
      id: data.id,
      progressUpdatePeriod: 3000
    }
    switch (data.status) {
      case "QUEUING":
          await this.watchJob(requestData)
        break
      case "RUNNING":
        if (data.progress) {
          if(data.progress.calculationProgress){
            store.commit('setProgresoActual', data.progress.calculationProgress)
          }
        }
        await this.watchJob(requestData)
        break
      case "STOPPING":
        store.commit('reiniciarProgreso')
        await this.deleteJob(requestData)
        break
      case "SUCCEEDED":
      case "FAILED":
        await this.fetchJobResult(requestData)
        break
      case "DELETED":
        store.commit('reiniciarProgreso')
        store.commit('setLoading', false)
        break
      case "UNKNOWN":
      default:
        console.log('Unknown status')
        break
    }
  }

  async watchJob(requestData) {
    try {
      await timeout(3000)
      const { status, data } = await xServerApi.post(`${this.endpoint}/watchJob`, requestData, {
        headers: { 
          'Authorization': `Basic ${Buffer.from(`xtok:${this.TOKEN_XSEVER}`).toString('base64')}`,
          'Content-Type': 'application/json'
        }
      })
      if (status !== 200) {
        throw new Error(data.error || data)
      }
      await this.statusJob(data)
    } catch (error) {
      throw new Error(error)
    }
  }

  async deleteJob(requestData) {
    try {
      const jobID = { id: requestData.id }
      const { status, data } = await xServerApi.post(`${this.endpoint}/deleteJob`, jobID, {
        headers: { 
          'Authorization': `Basic ${Buffer.from(`xtok:${this.TOKEN_XSEVER}`).toString('base64')}`,
          'Content-Type': 'application/json'
        }
      })
      if (status !== 200) {
        throw new Error(data.error || data)
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async fetchJobResult (requestData) {
    try {
      const jobID = { id: requestData.id }
      const { data } = await xServerApi.post(`/${this.endpoint}/${this.prefijoRespuesta}`, jobID, {
        headers: { 
          'Authorization': `Basic ${Buffer.from(`xtok:${this.TOKEN_XSEVER}`).toString('base64')}`,
          'Content-Type': 'application/json'
        }
      })
      await store.dispatch(this.obtenerInformacion, {
        id: this.id,
        response: data
      })
    } catch (error) {
      const { message } = error.response.data
      await store.dispatch(this.obtenerError, {
        id: this.id,
        response: message
      })
      console.error(error)
    }
    return this.deleteJob(requestData)
  }
}