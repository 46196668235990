import { format, isSameDay } from "date-fns"
import { definirTipoUsuario } from "../helpers/tipoUsuario"

export class MonitoreoUsuarioData {
    constructor({ idusuario_cia, nombre_usuario, user, fkmobil, visible}) {
        this.id = idusuario_cia
        this.nombre = nombre_usuario
        this.usuario = user
        this.latitud = null
        this.longitud = null
        this.tieneUbicacion = false
        this.tipoUsuario = definirTipoUsuario({ fkmobil, visible })
        this.esDeHoy = false
        this.hora = null
        this.fecha = null
    }

    get fechaHora() {
        if (!this.fecha) return ''
        return `${this.fecha} ${this.hora}`
    }

    get xy() { return `${this.latitud}, ${this.longitud}`}

    defineUbicacion({ latitud, longitud }) {
        if (!latitud || !longitud) return

        this.latitud = latitud
        this.longitud = longitud
        this.tieneUbicacion = true
    }

    defineFechaHora({fechaHora}) {
        if (!fechaHora) return
        const date = new Date(fechaHora)

        const hora = format(date, 'HH:mm:ss')
        const fecha = format(date, 'yyyy-MM-dd')

        this.esDeHoy = isSameDay(new Date(), date)
        this.fecha = fecha
        this.hora = hora
    }
}