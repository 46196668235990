import actions from './actions'
import mutations from './mutations'
import getters from './getters'

// En este store se esta usando el namespaced asi que se debe importar/usar de la siguiente forma
// ptvTipoServicio/[action, mutation, getter]
export default {
    namespaced: true,
    state() {
        return {
            tiposServicios: [],
        }
    },
    mutations,
    actions,
    getters
}