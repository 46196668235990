export default {
    configuracionGral(state) {
        return state.configuracionGral
    },
    permisoConfiguracionGral(state) {
        return (nombre) => {
            if (!nombre) return false

            return state.configuracionGral.find(configuracion => configuracion.propiedad === nombre)
        }
    },
    diasLaborales(state) {
        const configuracion = state.configuracionGral
        return {
            lunes: configuracion.find(item => item.propiedad === 'diaLunesPlanVisit'),
            martes: configuracion.find(item => item.propiedad === 'diaMartesPlanVisit'),
            miercoles: configuracion.find(item => item.propiedad === 'diaMiercolesPlanVisit'),
            jueves: configuracion.find(item => item.propiedad === 'diaJuevesPlanVisit'),
            viernes: configuracion.find(item => item.propiedad === 'diaViernesPlanVisit'),
            sabado: configuracion.find(item => item.propiedad === 'diaSabadoPlanVisit'),
            domingo: configuracion.find(item => item.propiedad === 'diaDomingoPlanVisit')
        }
    },
    diasNoLaborables(state) {
        const configuracion = state.configuracionGral
        return configuracion.find(item => item.propiedad === 'noLaborablesPlanVisit')
    },
    toleranciaEntreDistancias(state) {
        return state.configuracionGral.find(item => item.propiedad === 'toleranciaEntreDistancias')?.valor || 5
    },
    getCompaniaSeleccionada(state) {
        return state.companiaSeleccionada
    }    
}