import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
          cargas: [],
          conteoUsuarioCargas: [],
          cargasActivas: [],
          cargaActual: null
        }
    },
    mutations,
    actions,
    getters
}