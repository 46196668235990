import gitCoreApi from '@/api/gitCoreApi' 
import { format } from 'date-fns'

export default {
    /**
     * Busca los usuarios de la plataforma y los almacena en el store
     * Nota: Este método es el mismo que el método listarUsuarios del módulo configuración/usuariosGlobal,
     * se usa aparte porque los datos deben ser manipulados de forma diferente
     */
    async obtenerListadoUsuarios(context,payload) {
        context.commit('setLoadingUsuarios',true)
        try{
            const { status, data } = await gitCoreApi.post(`configuration/user/get_list_users`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
                signal: payload.signal
            })

            if (status !== 200) {
                throw new Error(data.message || 'Error al obtener el listado')
            }
            context.commit('setUsuariosUbicacion', data)
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoadingUsuarios',false)
        }
    },
    // Obtiene la ubicación mas reciente de cada usuario
    async obtenerUsuariosUbicacion(context,payload) {      
        context.commit('setLoadingUbicacion',true)

        try {
            const { data } = await gitCoreApi.post('/user_monitor/user_locations',{
                users: payload.users
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                },
                signal: payload.signal
            })

            context.commit('asignaUbicaciones', data)
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoadingUbicacion',false)
        }
    },

    // Obtiene las ubicaciones del dia actual
    async obtenerUsuariosUbicacionHoy(context,payload) {      
        context.commit('setLoadingSeguimiento',true)

        try {
            const { data } = await gitCoreApi.post('/configuration/movil_gps/get_track_many_usuarios', {
                users: payload.users,
                date: format(Date.now(), 'yyyy-MM-dd'),
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                },
                signal: payload.signal
            })

            // context.commit('asignaUbicaciones', data)
            return data
        } catch (error) {
            console.error(error)
        } finally {
            context.commit('setLoadingSeguimiento',false)
        }
    },
}