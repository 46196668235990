import gitCoreApi from '@/api/gitCoreApi'

export default {
    async buscarUltimaInformacion(context, payload) {
        context.commit('setLoading', true, {root: true})
        try {
            const { data } = await gitCoreApi.get('/gitRoute/routing/lastInfo', {
                headers: {'Authorization': `Bearer ${payload.token}`},
                params: { warehouseName: payload.warehouseName, date: payload.date, shift: payload.shift },
            })
            return data
        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false, {root: true})
        }
    },

    async crearXDima(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xDima', {
                warehouseName: payload.warehouseName,
                shift: payload.shift,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})

            const mensaje = { tipo: 'success', titulo: 'Matriz generada', data: null }
            if (data.isError) {
                mensaje.titulo = data.message
                mensaje.tipo = 'error'

                return mensaje
            }

            mensaje.data = data.data

            return mensaje

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },
    async watchXDimaJob(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xDima/watchJob',{
                    jobId: payload.jobId,
                    processId: payload.processId,
                    idProcessDetail: payload.idProcessDetail,
                }, {
                    headers: { 
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-Type': 'application/json'
                    }
                })
            return data

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },
    async fetchXDimaJobResult(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xDima/fetchJobResult', {
                jobId: payload.jobId,
                processId: payload.processId,
                idProcessDetail: payload.idProcessDetail,
                warehouseName: payload.warehouseName,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})
            return data

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },

    async crearXCluster(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xCluster', {
                idXDima: payload.idXDima,
                warehouseName: payload.warehouseName,
                processId: payload.processId,
                percentageImbalanceArea: payload.percentageImbalanceArea,
                configurationType: payload.configurationType,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})
            const mensaje = { tipo: 'success', titulo: 'Clusters generados', data: null }
            if (data.isError) {
                mensaje.titulo = data.message
                mensaje.tipo = 'error'

                return mensaje
            }

            mensaje.data = data.data

            return mensaje

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },

    async watchJobClusters(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xCluster/watchJob', {
                jobId: payload.jobId,
                processId: payload.processId,
                idProcessDetail: payload.idProcessDetail,
                warehouseName: payload.warehouseName,
                getPoints: payload.getPoints,
                getVehicles: payload.getVehicles,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})

            return data
        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },

    async crearXTour(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xTour', {
                idXDima: payload.idXDima,
                idXCluster: payload.idXCluster,
                processId: payload.processId,
                clusteredLocations: payload.clusteredLocations,
                warehouseName: payload.warehouseName,
                disableTimeRestrictions: payload.disableTimeRestrictions,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})
            const mensaje = { tipo: 'success', titulo: 'Tour generado', data: null }
            if (data.isError) {
                mensaje.titulo = data.message
                mensaje.tipo = 'error'

                return mensaje
            }

            mensaje.data = data.data

            return mensaje

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },

    async watchJobTour(context, payload) {
        try {
            const { data } = await gitCoreApi.post('/gitRoute/routing/xTour/watchJob', {
                jobId: payload.jobId,
                processId: payload.processId,
                idProcessDetail: payload.idProcessDetail,
                clusteredLocations: payload.clusteredLocations,
                warehouseName: payload.warehouseName,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})

            return data
        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        }
    },
}