export default {
    setRouteInfo(state, payload) {
        state.routeInfo = payload
    },
    setLoadingVehicles(state, payload) {
        state.loadingVehicles = payload
    },
    setStops(state, payload) {
        state.stops = payload
    },
    setUnplanned(state, payload) {
        state.unplanned = payload
    },
    setSingleSelectedReservations(state, reservaID) {
        state.selectedReservations.push(reservaID)
    },
    setSelectedReservations(state, reservations) {
        state.selectedReservations = reservations
    },
    setSelectionMode(state, payload) {
        state.selectionMode = payload
    },
    setRegion(state, payload) {
        state.region = payload
    }
}
