export default {
  setRequestPlanTourMod(state, payload) {
    state.requestPlanTourMod = payload
  },
  setResponsePlanTourMod(state, payload) {
    state.responsePlanTourMod = payload
  },
  setAddResponsePlanTourMod(state, payload) {
    state.responsePlanTourMod.push(payload)
  },
  setMostrarGitTourMod(state,payload) {
    state.mostrarGitTourMod = payload
  }
}