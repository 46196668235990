import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            zonasMonitoreo: [],
            rutasMonitoreo: [],
            tareasMonitoreo: [],
            ordenesMonitoreo: [],
            tiposTrabajoMonitoreo: [],
            listarLog:[],
            resumenMonitoreo: null,
            zonaSeleccionadaMonitoreo: null,
            dataConfirmacion: null,
        }
    },
    mutations,
    actions,
    getters
}