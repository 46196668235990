export default {
    getFecha(state){
        return state.fecha
    },
    getConfigurationActiveXcaret(state){
        return state.configurationActiveXcaret
    },
    getCargaSeleccionadaXcaret(state){
        return state.setCargaSeleccionadaXcaret
    }
}
