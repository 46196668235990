import gitCoreApi from '@/api/gitCoreApi'

export default {

    /**
     * Devuelve las lista de permisos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarActividades(context, payload) {

        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/activities/get_activities`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaActividades', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

    async listarClasificaciones(context, payload) {

        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get(`configuration/activities/get_clasifications`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaClasificaciones', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },


    /**
     * Crear una nueva actiovidad
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async nuevaActividad(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/activities/set_activity',
                {
                    "name": payload.nombre,
                    "order": payload.orden,
                    "value": payload.valor,
                    "visible_tracking_events": payload.segEventLog,
                    "graph": payload.grafica,
                    "create_detail": payload.detalle,
                    "show_tracking": payload.seguimiento,
                    "generate_activity_task":payload.actividad,
                    "generate_gps_event": payload.evento,
                    "visible_mobil": payload.visibleMovil,
                    "editable_online": payload.editable,
                    "direct_access": payload.accesoDirecto,
                    "max_captures": payload.maxActividades,
                    "print_qr": payload.qr,
                    "id_status": 1,
                    "idClas": payload.clasificacion,
                    "relId" : payload.clasificacion,
                    "is_form": payload.esformulario
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Usuario')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarActividades', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: 'La actividad se ha creado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita una Actividad
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async editarActividad(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/activities/update_activity',
                {
                    "id": payload.id,
                    "name": payload.nombre,
                    "order": payload.orden,
                    "value": payload.valor,
                    "visible_tracking_events": payload.segEventLog,
                    "graph": payload.grafica,
                    "create_detail": payload.detalle,
                    "show_tracking": payload.seguimiento,
                    "generate_activity_task":payload.actividad,
                    "generate_gps_event": payload.evento,
                    "visible_mobil": payload.visibleMovil,
                    "editable_online": payload.editable,
                    "direct_access": payload.accesoDirecto,
                    "max_captures": payload.maxActividades,
                    "print_qr": payload.qr,
                    "id_status": 1,
                    "idClas": payload.clasificacion,
                    "is_form": payload.esformulario,
                    "relId": payload.relId
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Actividades')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarActividades', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: 'La actividad se ha editado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },


    /**
     * Cambia el estatus de un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */


    async cambiarEstatusActividad(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/activities/update_activity',{ 
                    "id": payload.id,
                    "id_status": payload.id_status
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.id_status) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La actividad se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La actividad se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La actividad se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('listarActividades', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    
    async obtenerListaPreguntas(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get(`configuration/actividades/get_questions`, {
                params: {
                    id: payload.id
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaPreguntas', data)
            context.commit('setFkActividad', payload.id)
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }       
    },
    async obtenerListaClasificaciones(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/classification/get_clasifications`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaClasificaciones', data)
            }
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    async cambiarEstatusClasificacion(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put('configuration/classification/update_status_classification',
                { 
                    "id": payload.id,
                    "id_status": payload.id_status
                }, 
                {
                headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.id_status) {
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El usuario se ha activado correctamente'
                    }
                    break
                case 2:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El usuario se ha desactivado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La actividad se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaClasificaciones', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async nuevaClasificacion(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/classification/set_classification',
                {
                    dataClassification: payload.data,
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nueva Clasificación')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('obtenerListaClasificaciones', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: 'La clasificación se ha creado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async editarClasificacion(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.put('configuration/classification/update_classification',
                {
                    id: payload.data.id,
                    dataClassification: payload.data,
                }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Clasificaciones')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            data.message
            if (data.isError) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.message
                }
            } else {
                context.dispatch('obtenerListaClasificaciones', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: 'La clasificación se ha editado correctamente'
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

}