export default {
    async setInformationDashboard(state, payload) {
        state.informationDashboard = payload
    },
    async setResumenDashboard(state, payload) {
        state.resumengDashboard = payload
    },
    async setConteoVehiculos(state, payload) {
        state.conteoVehiculos = payload
    }
}