export default {
  setLoading(state, payload) {
    state.estaCargando = payload
  },
  setLoadingTwo(state, payload) {
    state.estaCargandoDos = payload
  },
  setModoProgreso(state, payload) {
    state.modoProgreso = payload
  },
  setProgresoActual(state, payload) {
    state.progresoActual = payload
  },
  setMensajeProgreso(state, payload) {
    state.mensajeProgreso = payload
  },
  reiniciarProgreso(state) {
    state.modoProgreso = 'indeterminate'
    state.progresoActual = 0
    state.mensajeProgreso = ''
  },
  setRutaActual(state, payload) {
    state.rutaActual = payload
  },
  setProgresoTareaProgramada(state, payload) {
    state.progresoTareaProgramada = payload
  },
}