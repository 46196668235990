
import xServerApi from '@/api/xServerApi'
import xServerJob from '@/models/xServerJob'
import { format } from 'date-fns'
import { Buffer } from 'buffer'
let peticionesProceso = 0
/* eslint-disable */
export default {

  /**
   * Define que tipo de entrada de informacio utilizara el ruteo (Archivo,Cluster,Visitas)
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async gitTour(context, payload) {
    context.commit('setErroresgITTour', [])
    peticionesProceso = 0
    const planClusterResponse = context.getters.planClusterResponse
    const planVisitsResponse = context.getters.getGitVisitResponse
    payload["planClusterResponse"] = planClusterResponse
    payload["planVisitsResponse"] = planVisitsResponse
    if (planVisitsResponse.length > 0) {
      //Preparar Datos desde el Plan Visits
      context.dispatch('prepararDatosVisitsGitTour', payload)
    } else if (planClusterResponse) {
      //Preparar Datos desde el Plan Cluster
      context.dispatch('prepararDatosClusterGitTour', payload)
    } else {
      //Preparar Datos desde el archivo
      context.dispatch('prepararDatosArchivoGitTour', payload)
    }
  },

  /**
   * Prepara la peticion hacia los XServer utilizando como base el planVisits
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async prepararDatosVisitsGitTour(context, payload) {
    const { puntos, vehiculos, opciones, almacenes, planVisitsResponse, xDima } = payload

    /** Procesado para obtener las visitas a planificar */
    const visitasPlan = []
    for(const {vehicle, visitDays} of planVisitsResponse){
      for(const {date, points} of visitDays){
        const indexVisitas = visitasPlan.findIndex(visita => visita.fecha === date)
        if(indexVisitas !== -1){
          visitasPlan[indexVisitas].visitas.push({
            vehiculoId: vehicle,
            ordenes: points
          })
        } else {
          visitasPlan.push({
            fecha: date,
            visitas: [{
              vehiculoId: vehicle,
              ordenes: points
            }]
          })
        }
      }
    }

    const peticiones = []

    let modoDistancia = {
      "$type": "DirectDistance"
    }
    if(xDima){
      modoDistancia = {
        "$type":  "ExistingDistanceMatrix",
        "id" : xDima.response.summary.id
      }
    }

    const opcionesSol = {
      "restrictions": {
        "maximumTravelTimePerTour": 82800,
        "singleTripPerTour": true
      },
      "orderGroupConsideration": {
        "oneGroupPerTrip": true,
        "oneTripPerGroup": true
      },
      "calculationMode": "STANDARD"
    }

    /** Iterar cada fecha para armar la peticion */
    visitasPlan.forEach(({ fecha, visitas }) => {
      const formatoFecha = new Date(fecha + "T12:00:00.000Z")
      const diaSemana = formatoFecha.getDay()
      const ubicacionesSol = []
      const ordenesSol = []
      const vehiculosSol = []
      const conductoresSol = []

      almacenes.forEach(({ id, latitud, longitud }) => {
        const ubicacionSol = {
          "$type": "DepotSite",
          "id": id,
          "routeLocation": {
            "$type": "OffRoadRouteLocation",
            "offRoadCoordinate": {
              "y": latitud,
              "x": longitud
            }
          }
        }
        ubicacionesSol.push(ubicacionSol)
      })

      visitas.forEach(({ vehiculoId, ordenes }) => {
        const { hora_inicio, hora_fin, tolerancia_entre_visita, hora_inicio_sabado, hora_fin_sabado, hora_inicio_domingo, hora_fin_domingo, almacen_comienzo, almacen_destino, capacidad } = vehiculos.find(({ id }) => id === vehiculoId)

        ordenes.forEach(orden => {
          const encuentraPunto = puntos.find(punto => punto.id == orden)
          if (!encuentraPunto) {
            return
          }
          const { latitud, longitud, tiempo_de_servicio, hora_inicio: horaInicioLugar, hora_fin: horaFinLugar, actividad } = encuentraPunto

          let ubicacionSol = {
            "$type": "CustomerSite",
            "id": orden,
            "routeLocation": {
              "$type": "OffRoadRouteLocation",
              "offRoadCoordinate": {
                "y": latitud,
                "x": longitud
              }
            }
          }

          if (horaInicioLugar != null && horaFinLugar != null && horaInicioLugar != "" && horaFinLugar != "") {
            ubicacionSol["openingIntervals"] = [{
              "$type": "StartEndInterval",
              "start": fecha + "T" + horaInicioLugar + ":00-05:00",
              "end": fecha + "T" + horaFinLugar + ":00-05:00"
            }]
          }

          ubicacionesSol.push(ubicacionSol)

          const ordenSol = {
            "$type": "VisitOrder",
            "id": orden,
            "locationId": orden,
            "serviceTime": parseInt(tiempo_de_servicio) * 60,
            "groupId": vehiculoId
          }
          
          if(capacidad != null && capacidad != ''){
            delete ordenSol.serviceTime
            delete ordenSol.locationId
            ordenSol["$type"] = "OrderWithAlternativeDeliveryDepots"
            ordenSol["quantities"] = [actividad || 1]
            ordenSol["serviceTimeForPickup"] = (parseInt(tiempo_de_servicio) * 60) + (parseInt(tolerancia_entre_visita) * 60)
            ordenSol["deliveryLocationIds"] = almacenes.map(({id}) => id)
            ordenSol["pickupLocationId"] = orden
          }
          ordenesSol.push(ordenSol)
        })

        let vehiculoSol = {
          "ids": [vehiculoId],
          "orderGroupIds": [vehiculoId]
        }
        if (tolerancia_entre_visita != "") {
          vehiculoSol["serviceTimePerStop"] = parseInt(tolerancia_entre_visita) * 60
        }
        if (almacen_comienzo != "" && almacen_comienzo != null) {
          vehiculoSol["startLocationId"] = almacen_comienzo
        }
        if (almacen_destino != "" && almacen_destino != null) {
          vehiculoSol["endLocationId"] = almacen_destino
        }

        if(capacidad != null && capacidad != ''){
          vehiculoSol["maximumQuantityScenarios"] = [{
            quantities: [parseInt(capacidad)]
          }]
          vehiculoSol["serviceTimePerStop"] = 0
        }

        let horaInicio = ""
        let horaFin = ""

        if (diaSemana >= 1 && diaSemana <= 5 && hora_inicio != null && hora_fin != null && hora_inicio != "" && hora_fin != "") {
          horaInicio = hora_inicio
          horaFin = hora_fin
        }
        if (diaSemana == 6 && hora_inicio_sabado != null && hora_fin_sabado != null && hora_inicio_sabado != "" && hora_fin_sabado != "") {
          horaInicio = hora_inicio_sabado
          horaFin = hora_fin_sabado
        }
        if (diaSemana == 0 && hora_inicio_domingo != null && hora_fin_domingo != null && hora_inicio_domingo != "" && hora_fin_domingo != "") {
          horaInicio = hora_inicio_domingo
          horaFin = hora_fin_domingo
        }

        const conductorSol = {
          "id": vehiculoId,
          "vehicleId": vehiculoId,
          "operatingIntervals": [{
            "$type": "StartEndInterval",
            "start": fecha + "T" + horaInicio + ":00-05:00",
            "end": fecha + "T" + horaFin + ":00-05:00"
          }]
        }

        if (horaInicio != "" && horaFin != "") {
          vehiculosSol.push(vehiculoSol)
          conductoresSol.push(conductorSol)
        }

      })
      const peticion = {
        "locations": ubicacionesSol,
        "orders": ordenesSol,
        "fleet": {
          "vehicles": vehiculosSol,
          "drivers": conductoresSol
        },
        "planToursOptions": opcionesSol,
        "distanceMode": modoDistancia
      }

      if (ordenesSol.length > 0 && vehiculosSol.length > 0) {
        peticiones.push({ fecha: fecha, peticion: peticion })
      }
    })

    context.dispatch('startPlanTours', peticiones)
  },
  /**
   * Prepara la peticion hacia los XServer utilizando como base el Archivo de carga
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
   async prepararDatosArchivoGitTour(context, payload) {
    const { puntos, vehiculos, opciones, xDima } = payload
    const date = format(new Date(), "yyyy-MM-dd")

    let modoDistancia = {
      "$type": "DirectDistance"
    }

    if(xDima){
      modoDistancia = {
        "$type":  "ExistingDistanceMatrix",
        "id" : xDima.response.summary.id
      }
    }

    const ubicacionesSol = []
    const ordenesSol = []
    const vehiculosSol = []
    const conductoresSol = []

    puntos.forEach(punto => {
      const { id, latitud, longitud, tiempo_de_servicio } = punto

      const ubicacionSol = {
        "$type": "CustomerSite",
        "id": id,
        "routeLocation": {
          "$type": "OffRoadRouteLocation",
          "offRoadCoordinate": {
            "y": latitud,
            "x": longitud
          }
        }
      }
      ubicacionesSol.push(ubicacionSol)

      const ordenSol = {
        "$type": "VisitOrder",
        "id": id,
        "locationId": id,
        "serviceTime": parseInt(tiempo_de_servicio) * 60
      }
      ordenesSol.push(ordenSol)
    })

    vehiculos.forEach(vehiculo => {
      const { id, hora_inicio, hora_fin, tolerancia_entre_visita } = vehiculo

      let vehiculoSol = {
        "ids": [id]
      }
      if (tolerancia_entre_visita != "") {
        vehiculoSol["serviceTimePerStop"] = parseInt(tolerancia_entre_visita) * 60
      }
      vehiculosSol.push(vehiculoSol)

      const conductorSol = {
        "id": id,
        "vehicleId": id,
        "operatingIntervals": [{
          "$type": "StartEndInterval",
          "start": date + "T" + hora_inicio + ":00-05:00",
          "end": date + "T" + hora_fin + ":00-05:00"
        }]
      }
      conductoresSol.push(conductorSol)
    })

    const opcionesSol = {
      "restrictions": {
        "maximumTravelTimePerTour": 82800,
        "singleTripPerTour": true
      },
      "calculationMode": "STANDARD"
    }

    const peticion = {
      "locations": ubicacionesSol,
      "orders": ordenesSol,
      "fleet": {
        "vehicles": vehiculosSol,
        "drivers": conductoresSol
      },
      "planToursOptions": opcionesSol,
      "distanceMode": modoDistancia
    }

    const { fecha_inicio } = opciones

    context.dispatch('startPlanTours', [{ fecha: fecha_inicio, peticion: peticion }])
  },
  /**
   * Prepara la peticion hacia los XServer utilizando como base el Archivo de carga y gITCluster
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
   async prepararDatosClusterGitTour(context, payload) {
    const { puntos, vehiculos, opciones, planClusterResponse, xDima } = payload
    const date = format(new Date(), "yyyy-MM-dd")

    let modoDistancia = {
      "$type": "DirectDistance"
    }

    if(xDima){
      modoDistancia = {
        "$type":  "ExistingDistanceMatrix",
        "id" : xDima.response.summary.id
      }
    }

    const ubicacionesSol = []
    const ordenesSol = []
    const vehiculosSol = []
    const conductoresSol = []

    planClusterResponse.clusteredLocations.forEach(({ locationIds }, index) => {
      const { id, hora_inicio, hora_fin, tolerancia_entre_visita } = vehiculos[index]

      locationIds.forEach(idpunto => {
        const { latitud, longitud, tiempo_de_servicio, capacidad } = puntos.find(punto => punto.id == idpunto)

        const ubicacionSol = {
          "$type": "CustomerSite",
          "id": idpunto,
          "routeLocation": {
            "$type": "OffRoadRouteLocation",
            "offRoadCoordinate": {
              "y": latitud,
              "x": longitud
            }
          }
        }
        ubicacionesSol.push(ubicacionSol)

        const ordenSol = {
          "$type": "VisitOrder",
          "id": idpunto,
          "locationId": idpunto,
          "serviceTime": parseInt(tiempo_de_servicio) * 60,
          "groupId": id
        }
        ordenesSol.push(ordenSol)
      })

      let vehiculoSol = {
        "ids": [id],
        "orderGroupIds": [id]
      }
      if (tolerancia_entre_visita != "") {
        vehiculoSol["serviceTimePerStop"] = parseInt(tolerancia_entre_visita) * 60
      }

      if(capacidad != ""){
        vehiculoSol["MaximumQuantityScenario"] = [parseInt(capacidad)]
      }


      vehiculosSol.push(vehiculoSol)

      const conductorSol = {
        "id": id,
        "vehicleId": id,
        "operatingIntervals": [{
          "$type": "StartEndInterval",
          "start": date + "T" + hora_inicio + ":00-05:00",
          "end": date + "T" + hora_fin + ":00-05:00"
        }]
      }
      conductoresSol.push(conductorSol)
    })

    const opcionesSol = {
      "restrictions": {
        "maximumTravelTimePerTour": 82800,
        "singleTripPerTour": true
      },
      "orderGroupConsideration": {
        "oneGroupPerTrip": true,
        "oneTripPerGroup": true
      },
      "calculationMode": "STANDARD"
    }

    const peticion = {
      "locations": ubicacionesSol,
      "orders": ordenesSol,
      "fleet": {
        "vehicles": vehiculosSol,
        "drivers": conductoresSol
      },
      "planToursOptions": opcionesSol,
      "distanceMode": modoDistancia
    }

    const { fecha_inicio } = opciones

    context.dispatch('startPlanTours', [{ fecha: fecha_inicio, peticion: peticion }])
  },
  /** 
   * Inicia la peticion hacia los XServer
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async startPlanTours(context, payload) {
    try {
      context.commit('setLoading', true)
      //limpiar estado peticiones y respuestas
      context.commit('setMostrarGitTour', false)
      context.commit('setSolicitudPlanTour', payload)
      context.commit('setRespuestaPlanTour', [])
      //Iteramos todas la peticiones
      context.dispatch('eachPeticionesTours', { index: 0, peticiones: payload })

    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Itera las peticiones hacia los XServer
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async eachPeticionesTours(context, payload) {
    const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
    let { index, peticiones } = payload
    if (peticionesProceso >= 15) {
      setTimeout(function () {
        context.dispatch('eachPeticionesTours', { index: index, peticiones: peticiones })
      },
        2000
      )
      return
    }
    try {
      if (index < peticiones.length) {
        const { peticion, fecha } = peticiones[index]
        const { status, data } = await xServerApi.post('XTour/2.31/startPlanTours', peticion, {
          headers: {
            'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
            'Content-Type': 'application/json'
          }
        })

        if (status !== 200) {
          throw new Error(data.error || data)
        }

        //Revisar status
        const job = new xServerJob('tour', fecha)
        job.statusJob(data)

        //Iteramos las peticiones restantes
        index++
        peticionesProceso++
        await context.dispatch('eachPeticionesTours', { index: index, peticiones: peticiones })
      }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Se guarda la informacion en el store
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async setPlanTourRequest(context, payload) {
    peticionesProceso--
    context.commit('setAddRespuestaPlanTour', payload)
    const solicitudPlanTour = context.getters.solicitudPlanTour
    const respuestaPlanTour = context.getters.respuestaPlanTour
    const erroresPlanTour = context.getters.getErroresgITTour
    if (solicitudPlanTour.length === (respuestaPlanTour.length + erroresPlanTour.length)) {
      context.commit('setLoading', false)
      context.commit('setMostrarGitTour', true)
    }
  },
  async setPlanTourRequestError(context, payload) {
    context.commit('addErroresgITTour', payload)
  }
}