import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    namespaced: true,
    state() {
        return {
            almacen: null,
            carga: 'Matutino',
        }
    },
    mutations,
    actions,
    getters
}