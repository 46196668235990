import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            listaActividades:[],
            listaClasificaciones:[],
            listaPreguntas:[],
            fkActividad: null,
        }
    },
    mutations,
    actions,
    getters
}