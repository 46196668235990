export default {
    setGeocercas(state, data) {
        state.geocercas = [...data]
    },

    setCargando(state, cargando) {
        state.cargando = cargando
    },
}

