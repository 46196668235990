import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/router'
import store from '@/store/index'
import { es } from './locale/primeLocale.js'
import ConfirmationService from 'primevue/confirmationservice'

//Directivas
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'

// Styles
import '@/styles/layout.scss'                                       //Css Sakai
// import 'primevue/resources/themes/md-light-deeppurple/theme.css'    //theme
// import 'primevue/resources/themes/md-light-indigo/theme.css'        //theme
import 'primevue/resources/primevue.min.css'                        //core css
import 'primeicons/primeicons.css'                                  //icons
import 'primeflex/primeflex.scss'                                   //primeflex
// import './assets/theme/theme.css'

// 3rd party libraries
import '@fullcalendar/core/vdom'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(PrimeVue, { locale: es })
app.config.errorHandler = (err) => {
    console.error(err)
}
app.use(ToastService)
app.use(ConfirmationService)
app.directive('styleclass', StyleClass)
app.directive('badge', BadgeDirective)
app.directive('ripple', Ripple)
app.component('Badge', Badge)
app.mount('#app')
    
