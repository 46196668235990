export default {
    setUser(state, payload) {
        state.userId = payload.userId
        state.token = payload.token
        state.tokenExpirationDate = payload.tokenExpirationDate
        state.userName = payload.userName
        state.companyId = payload.companyId
        state.userEmail = payload.userEmail
        state.authState = payload.authState
        state.geotabDatabase = payload.geotabDatabase
    },
    setActiveGroups(state, payload) {
        state.activeGroups = payload
    },
    async setMenu(state, payload) {
        state.obtenerMenu = payload
    },
    async setPermisos(state, payload) {
        state.obtenerPermisos = payload
    },
    async setRutaInicio(state, payload) {
        state.obtenerRutaInicio = payload
    },
    async setCompanias(state, payload) {
        state.obtenerCompanias = payload
    },
    setToken(state, payload) {
        state.token = payload.token
        state.tokenExpirationDate = payload.tokenExpirationDate
    },
    setCompaniaSel(state, payload) {
        state.companiaIdSel = payload
    },
    setTheme(state, payload){
        state.theme = payload
    }
    
}

