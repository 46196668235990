import gitCoreApi from '@/api/gitCoreApi' 

export default {
    async obtenerInformacionAuditoriaUsuarios(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status, data } = await gitCoreApi.get('/gitlogistics/user_audit/information_user', {
                params: {},
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setInformationUserAudit',data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },

    async obtenerInformacionAuditoriaUsuariosFechas(context, payload) {
        context.commit('setLoading',true)

        try {
            const { status, data } = await gitCoreApi.get('/gitlogistics/user_audit/information_user_dates', {
                params: {
                    startDate: payload.startDate,
                    endDate: payload.endDate
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setInformationUserAudit',data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
}