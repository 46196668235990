import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            planVisitsRequest: [],
            planVisitsResponse: [],
            mostrarGitVisit: false,
            erroresPlanVisit: [],
            isConfiguracion: [],
            isGitVisits: false,
            gitVisitResponse: [],
        }
    },
    mutations,
    actions,
    getters
}