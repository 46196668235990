export default {
  requestPlanTourMod(state){
    return state.requestPlanTourMod
  },
  responsePlanTourMod(state){
    return state.responsePlanTourMod
  },
  mostrarGitTourMod(state){
    return state.mostrarGitTourMod
  }
}