export default {
    getCargasActivasResumen(state) {
        return state.cargasActivasResumen
    },
    getCargaSeleccionadaResumen(state) {
        return state.cargaSeleccionadaResumen
    },
    getVehiculoResumen(state) {
        return state.vehiculoResumen
    },
    getFechaResumen(state) {
        return state.fechaResumen
    },
    getListaVehiculosResumen(state) {
        return state.listaVehiculosResumen
    },
    getListaPuntoNuevo(state) {
        return state.listaPuntoNuevo
    },
    getIdsTarea(state) {
        return state.idsTarea
    },
    getCargandoCargasActivas(state) {
        return state.cargandoCargasActivas
    }
}