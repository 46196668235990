import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'

export default {
    async getEventosLogisticos(context, payload) {
        try {
            context.commit('setLoading', true)
            const token = context.getters.token || null
            const { status, data } = await gitCoreApi.get(`/event_tracking/information?eventType=${payload.eventType}&date=${payload.startDate}`, /* payload, */ {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            if (status !== 200) {
                throw new Error(data.error || data || 'No se pudieron obtener los eventos, consulta a personal de gIT Solutions')
            }
            context.commit('setVehiculosSeguimiento', data)
        } catch (error) {
            throw new Error(error || 'No se pudieron obtener los eventos, consulta a personal de gIT Solutions')
        } finally {
            context.commit('setLoading', false)
        }
    },
    /**
     * Envía una petición para obtener los eventos de un vehículo
     * @param {Object} context
     * @param {Object} payload
     */
    async fetchVehicleEvents(context, payload) {
        try {
            context.commit('setLoading', true)
            const token = context.getters.token || null
            const { data } = await gitLogisticsApi.post('v1/gitlogistics/obtenerEventosVehiculo', payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            return data
        } catch (e) {
            console.error(e)
        } finally {
            context.commit('setLoading', false)
        }
    }
}