export default {
    userId(state) {
        return state.userId
    },
    userName(state) {
        return state.userName
    },
    companyId(state) {
        return state.companyId
    },
    companyIdNum(state) {
        return Number(state.companyId)
    },
    esPFA(state, getters) { return getters.companyIdNum === 136 },
    userEmail(state) {
        return state.userEmail
    },
    token(state) {
        return state.token
    },
    isAuthenticated(state) {
        return state.authState === 'authenticated'
    },
    tokenExpirationDate(state) {
        return state.tokenExpirationDate
    },
    activeGroups(state) {
        return state.activeGroups
    },
    geotabDatabase(state) {
        return state.geotabDatabase
    },
    getMenu(state) {
        return state.obtenerMenu
    },
    getPermisos(state) {
        return state.obtenerPermisos
    },
    getRutaInicio(state) {
        return state.obtenerRutaInicio
    },
    getCompanias(state) {
        return state.obtenerCompanias
    },
    getCompaniaIdSel(state) {
        return state.companiaIdSel
    },
    getTheme(state) {
        return state.theme
    },
    hasPermiso(state) {
        return (nombre) => {
            if (!nombre) return false

            return state.obtenerPermisos.some(permiso => permiso.nombre_perm === nombre)
        }
    }
}