export default {
    setListarRoles(state, payload) {
        state.listaRoles = payload
    },
    setListaRolTipo(state, payload) {
        state.listaTipoRoles = payload
    },
    setInfoRol(state, payload) {
        state.infoRol = payload
    },
    setIsRol(state, payload) {
        state.isRol = payload
    },
    
}

