export default {
    async setZonasMonitoreo(state, payload) {
        state.zonasMonitoreo = payload
    },
    async setRutasMonitoreo(state, payload) {
        state.rutasMonitoreo = payload
    },
    async setTareasMonitoreo(state, payload) {
        state.tareasMonitoreo = payload
    },
    async setZonaSeleccionadaMonitoreo(state, payload) {
        state.zonaSeleccionadaMonitoreo = payload
    },
    async setResumenMonitoreo(state, payload) {
        state.resumenMonitoreo = payload
    },
    async setTiposTrabajoMonitoreo(state, payload){
        state.tiposTrabajoMonitoreo = payload
    },
    async setOrdenesMonitoreo(state, payload) {
        state.ordenesMonitoreo = payload
    },
    async setListarPermisos(state, payload){
        state.listarPermisos = payload
    },
    async setListarLog(state, payload) {
        state.listarLog = payload
    },
    async setDataConfirmacion(state, payload) {
        state.dataConfirmacion = payload
    }
}