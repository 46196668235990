export default {
    async setInformationgITXcaretRuteo(state, payload) {
        state.informationgITXcaretRuteo = payload
    },
    async setResumengITXcaretRuteo(state, payload) {
        state.resumengITXcaretRuteo = payload
    },
    async setDataSteps(state, payload) {
        state.dataSteps = payload
    },
    async setOpcionesgITXcaretRuteo(state, payload) {
        state.opcionesgITXcaretRuteo = payload
    },
    async setWarehousesgITXcaretRuteo(state, payload) {
        state.warehousesgITXcaretRuteo = payload
    },
    async setListaConfiguraciones(state, payload) {
        state.listaConfiguraciones = payload
    },
    async setInfoConfiguracion(state, payload) {
        state.infoConfiguracion = payload
    },
    async setListaProcesos(state, payload) {
        state.listaProcesos = payload
    },
}