import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    async buscarSucursales(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { data } = await gitCoreApi.get(
                `branches`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            context.commit('setSucursales', data.data)
            return {
                tipo: 'success',
                titulo: data.message || ''
            }
        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false, { root: true })
        }
    },

    async crearSucursal(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { status, data } = await gitCoreApi.post(
                `branches`,
                {
                    codigo: payload.noCliente,
                    descripcion: payload.nombreCliente,
                    x: payload.latitud,
                    y: payload.longitud,
                    habilitado: payload.estatus,
                    sucursal: payload.sucursal,
                    almacen: payload.almacen,
                    dias: payload.dias,
                    hora_inicio: payload.horaInicio,
                    hora_fin: payload.horaFin,
                    hora_inicio_2: payload.horaInicio2,
                    hora_fin_2: payload.horaFin2,
                    tipo_de_servicio: payload.tipoServicio,
                },
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            return {
                tipo: 'success',
                titulo: data.message || ''
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })

            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }
            throw new Error(error)
        }
    },

    async actualizarSucursal(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { status, data } = await gitCoreApi.patch(
                `branches/${payload.id}`,
                {
                    codigo: payload.noCliente,
                    descripcion: payload.nombreCliente,
                    x: payload.latitud,
                    y: payload.longitud,
                    habilitado: payload.estatus,
                    sucursal: payload.sucursal,
                    almacen: payload.almacen,
                    dias: payload.dias,
                    hora_inicio: payload.horaInicio,
                    hora_fin: payload.horaFin,
                    hora_inicio_2: payload.horaInicio2,
                    hora_fin_2: payload.horaFin2,
                    tipo_de_servicio: payload.tipoServicio,
                },
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            switch (payload.estatus) {
                case 0:
                    return { tipo: 'success', titulo: 'La Sucursal se ha desactivado correctamente' }
                case 1:
                    return { tipo: 'success', titulo: 'La Sucursal se ha activado correctamente' }
                case 2:
                    return { tipo: 'warn', titulo: 'La Sucursal se ha eliminado' }
                default:
                    break
            }

            return {
                tipo: 'success',
                titulo: data.message || ''
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }

            throw new Error(error)
        }
    },

    async creaExcelSucursales(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('branches/export_xls', {
                getEmpty: payload.getEmpty ?? false
            } ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al subir archivo')
            }
    
            const { data_base64, type } = data

            const nombreArchivo = payload.getEmpty
                ? 'Template_Sucursales'
                : `Exporta_Sucursales_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data_base64, nombreArchivo, type)
        } catch (error) {
            throw new Error(error)
        }
    },

    async importSucursal(context, payload) {
        try {
            context.commit('setLoading', true, { root: true })
            const solicitud = new FormData()
            solicitud.append('file', payload.file)

            const { data } = await gitCoreApi.post('/branches/import_xls', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (data.isError) context.commit('setLoading', false, { root: true })

            const mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false, { root: true })
            throw error
        }
    },
}