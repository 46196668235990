import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            puntosMatriz: [],
            puntosNeo4jMatriz: [],
            relacionesNeo4jMatriz: [],
            clustersXcaret: [],
            zonasXcaret: [],
            hotelesXcaret: [],
            configuracionZonasXcaret: []
        }
    },
    mutations,
    actions,
    getters
}