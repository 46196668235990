import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    /**
     * Devuelve la lista de Almacenes
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoAlmacenesListar(context, payload) {
        try {
            if (!payload.ocultarLoading) context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.get('/configuration/warehouse', {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                if (!payload.ocultarLoading)  context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaAlmacenes', data)

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        } finally {
            if (!payload.ocultarLoading)  context.commit('setLoading', false)
        }
    },
    async catalogoAlmacenesNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('/configuration/warehouse',
                {
                    'nombre': payload.nombre,
                    'tipo': payload.tipo,
                    'hora_inicio': payload.hora_inicio,
                    'hora_fin': payload.hora_fin,
                    'latitud': payload.latitud,
                    'longitud': payload.longitud,
                    'estatus': payload.estatus,
                    'opciones': payload.opciones,
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el listado')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.isError) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.message
                }
            } else {
                context.dispatch('catalogoAlmacenesListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.message
                }
            }
            return mensaje

        } catch (error) {
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }
            console.error(error)
            throw error
        }
    },
    /**
     * Edita un nuevo Almacen
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoAlmacenesEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { id, token, nombre, tipo, hora_inicio, hora_fin, latitud, longitud, estatus, opciones } = payload
            const { status, data } = await gitCoreApi.patch(`/configuration/warehouse/${id}`,
                {
                    'nombre': nombre,
                    'hora_inicio': hora_inicio,
                    'tipo': tipo,
                    'hora_fin': hora_fin,
                    'latitud': latitud,
                    'longitud': longitud,
                    'estatus': estatus,
                    'opciones': opciones,
                }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el listado')
                context.commit('setLoading', false)
                throw error
            }

            context.dispatch('catalogoAlmacenesListar', payload)
            return {
                tipo: 'success',
                titulo: data.message
            }

        } catch (error) {
            context.commit('setLoading', false)
            
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }
            throw error
        }
    },
    async catalogoAlmacenesCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { id, estatus } = payload
            const { status, data } = await gitCoreApi.patch(`/configuration/warehouse/${id}`,
                {
                    "estatus": estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Almacen se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Almacen se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El Almacen se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoAlmacenesListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importAlmacen(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)

            const { status, data } = await gitCoreApi.post('/configuration/warehouse/import_xls_warehouse', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al subir archivo')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoAlmacenesListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async creaExcelAlmacen(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/warehouse/export_xls_warehouse', {
                getEmpty: payload.getEmpty
            } ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error(data.message || 'Error al subir archivo')
            }

            const nombreArchivo = payload.getEmpty
                ? 'Template_almacenes'
                : `Exporta_almacenes_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data, nombreArchivo, 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },
    async opcionesActualizado(context, payload) {
        context.commit('setOpciones', payload.opciones)
    }
}
