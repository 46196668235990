export default {
    async setInfoArchivo(state, payload) {
        state.infoPuntos = payload.infoPuntos
        state.infoVehiculos = payload.infoVehiculos
        state.infoOpciones = payload.infoOpciones
        state.infoAlmacenes = payload.infoAlmacenes
        state.infoNoPlanificados = payload.infoNoPlanificados
        state.infoPuntosConFrecuenciasNoPlanificables = payload.infoPuntosConFrecuenciasNoPlanificables
    },
    async setConfiguracionPlaneacion(state,payload){
        state.configuracionPlaneacion = payload
    },
    async setInfoVehiculos(state, payload) {
        state.infoVehiculos = payload
    },
    async setInfoPuntos(state, payload) {
        state.infoPuntos = payload.infoPuntos
    }
}