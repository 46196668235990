export default {
  getCargas(state) {
    return state.cargas
  },
  getConteoUsuarioCargas(state) {
    return state.conteoUsuarioCargas
  },
  getCargasActivas(state) {
    return state.cargasActivas
  },
  getCargaActual(state) {
    return state.cargaActual
  }
}