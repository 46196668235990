export default {
    setConsultaPuntosListaUsuario(state, payload) {
        state.listaUsuario = payload
    },
    setConsultaPuntosDatos(state, payload) {
        state.consultaPuntosDatos = payload
    },
    setDatosPuntoDetalle(state, payload) {
        state.datosPuntoDetalle = payload
    },
    setListaUsuarioMovil(state, payload) {
        state.listaUsuarioMovil = payload
    },
    setPreguntasTiket(state, payload) {
        state.preguntasTiket = payload
    },
    setPuntosDetalleWebyTabla(state, payload) {
        state.puntosDetalleWebyTabla = payload
    }
}