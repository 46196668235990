export default {
    getListaCapturas(state) {
        return state.listaCapturas
    },
    getListaUsuariosByCompania(state) {
        return state.listaUsuariosByCompania
    },
    getListaActividadesGaleria(state){
        return state.listaActividadesByCompania
    },
    getListaPictures(state){
        return state.listaPictures
    }
}