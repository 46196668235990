export default {
    async setPuntosMatriz(state, payload) {
        state.puntosMatriz = payload
    },
    async setPuntosNeo4jMatriz(state, payload) {
        state.puntosNeo4jMatriz = payload
    },
    async setRelacionesNeo4jMatriz(state, payload) {
        state.relacionesNeo4jMatriz = payload
    },
    async setClustersXcaret(state, payload) {
        state.clustersXcaret = payload
    },
    async setZonasXcaret(state, payload) {
        state.zonasXcaret = payload
    },
    async setHotelesXcaret(state, payload) {
        state.hotelesXcaret = payload
    },
    async setConfiguracionZonasXcaret(state, payload) {
        state.configuracionZonasXcaret = payload
    },
}