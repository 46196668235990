export default {
    async setPlanVisitRequest (state, payload) {
        state.planVisitsRequest = payload
    },
    async setPlanVisitsResponse (state, payload) {
        state.planVisitsResponse = payload
    },
    async setMostrarGitVisits(state,payload) {
        state.mostrarGitVisit = payload
    },
    async setErroresPlanVisit(state,payload) {
        state.erroresPlanVisit = payload
    },
    async addErroresPlanVisit(state,payload) {
        state.erroresPlanVisit.push(payload)
    },
    async setIsConfiguracion(state,payload) {
        state.isConfiguracion.push(payload)
    },
    async setIsGitVisits(state, payload) {
        state.isGitVisits = payload
    },
    async setGitVisitResponse(state, payload) {
        state.gitVisitResponse = payload
    },
}