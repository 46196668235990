export default {
  setResultadoPlaneacion(state, payload) {
    state.resultadoPlaneacion = payload
  },
  setIdPlaneacion(state, payload) {
    state.resultadoPlaneacion.id = payload || payload.id
  },
  sePlaneacionPlanCluster(state, payload) {
    state.resultadoPlaneacion.planCluster = payload
  },
  setPlaneacionPlanVisits(state, payload) {
    state.resultadoPlaneacion.planVisits = payload
  },
  setPlaneacionPlanTour(state, payload) {
    state.resultadoPlaneacion.planTour = payload
  },
  setStatusProceso(state, payload) {
    state.statusProceso = payload
  }
}