export default {
  setCargas(state, payload) {
    state.cargas = payload
  },
  setConteoUsuarioCargas(state, payload) {
    state.conteoUsuarioCargas = payload
  },
  setCargasActivas(state, payload) {
    state.cargasActivas = payload
  },
  setCargaActual(state, payload) {
    state.cargaActual = payload
  }
}