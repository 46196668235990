export default {
    getRouteInfo(state) {
        return state.routeInfo
    },
    getUnplanned(state) {
        return state.unplanned
    },
    getStops(state) {
        return state.stops
    },
    getSelectedReservations(state) {
        return state.selectedReservations
    },
    getSelectionMode(state) {
        return state.selectionMode
    },
    getRegion(state) {
        return state.region
    }
}
