export default {
    getConsultaPuntosListaUsuarios(state) {
        return state.listaUsuario
    },
    getConsultaPuntosDatos(state) {
        return state.consultaPuntosDatos
    },
    getDatosPuntoDetalle(state) {
        return state.datosPuntoDetalle
    },
    getListaUsuarioMovil(state) {
        return state.listaUsuarioMovil
    },
    getPreguntasTiket(state) {
        return state.preguntasTiket
    },
    getPuntosDetalleWebyTabla(state) {
        return state.puntosDetalleWebyTabla
    }
}