import gitCoreApi from '@/api/gitCoreApi'

export default {

    async catalogoListarMenusSubMenus(context, payload) {

        try {
            context.commit('setLoading', true)
            const promises = [
                gitCoreApi.post('configuration/menu/get_sub_menus',{}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaSubMenu', response.data)
                }),
                gitCoreApi.post(`configuration/menu/get_menus`,{}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaMenu', response.data)
                })
            ]
            await Promise.all(promises)

            context.commit('setLoading', false)

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }


    },

    async catalogoSubMenusCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/update_sub_menu',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El submenu se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El submenu se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El submenu se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },


    /**
     * Crear un nuevo SubMenu 
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoSubMenuNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/insert_sub_menu',
                {
                    "id": payload.id,
                    "estatus": payload.estatus,
                    "nombre": payload.nombre,
                    "ruta": payload.ruta,
                    "icono": payload.icono,
                    "fk_menu": payload.fk_menu,
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo SubMenu')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },



    /**
     * Edita un Menu
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoSubMenuEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/update_sub_menu',
                {
                    "id": payload.id,
                    "estatus": payload.estatus,
                    "nombre": payload.nombre,
                    "ruta": payload.ruta,
                    "icono": payload.icono,
                    "fk_menu": payload.fk_menu,
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar sub menu')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },


    /**
     * Crear una nuevo Menu
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoMenuNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/insert_menu',
                {
                    "nombre": payload.nombre,
                    "estatus": payload.estatus
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Menu')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },



    /**
     * Edita un Menu
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoMenuEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/update_menu',
                {
                    "id": payload.id,
                    "estatus": payload.estatus,
                    "nombre": payload.nombre,
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar menu')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

    /**
     * Cambia el estatus de un Menu
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoMenusCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('configuration/menu/update_menu',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El menu se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El submenu se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El submenu se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarMenusSubMenus', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },

}