export default {
    async setPlanClusterRequest(state, payload) {
        state.planClusterRequest = payload
    },
    async setPlanClusterResponse(state, payload) {
        state.planClusterResponse = payload
    },
    setClustersProcesados(state, payload) {
        state.clustersProcesados = [...payload]
    },
    async addErroresgITCluster(state, payload) {
        state.erroresgITCluster.push(payload)
    },
    async setErroresgITCluster(state, payload) {
        state.erroresgITCluster = payload
    },
    async setRespuestaClusters(state, payload) {
        state.respuestaClusters = payload
    },
    async setCentroidesClusters(state, payload) {
        state.centroidesClusters = payload
    }
}