import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'
import { descargaPdf } from '@/helpers/helpers.js'

export default {
    async consultaPuntosObtenListadoUsuarios(context, payload) {
        const { status, data } = await gitCoreApi.post('/query-points/get-list-of-users',
        {
            activeGroups: payload.activeGroups
        },
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            const error = new Error(data.message || 'Error al obtener el listado')
            throw error
        }

        const listadoUsuariso = [...context.getters.getConsultaPuntosListaUsuarios, ...data.usuarios]
        context.commit('setConsultaPuntosListaUsuario', listadoUsuariso)
        return data.permissions
    },
    async consultaPuntosObtenPuntos(context, payload) {
        const { status, data } = await gitCoreApi.post('/query-points/get-points',
        {
            user: payload.usuarioSeleccionado,
            searchCode: payload.codigoBusqueda,
            acces: payload.tipoAccesoSeleccionado,
            dates: payload.fechas,
            activeGroups: payload.activeGroups,
            selectedDetail: payload.detalleSeleccionado,
            selectedDetailsValue: payload.valorDetalleSeleccionado
        },
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            const error = new Error(data.message || 'Error al obtener el listado')
            throw error
        }

        context.commit('setConsultaPuntosDatos', data)
    },
    async consultaPuntoDetallePageData(context, payload) {
        try {
            let method = null
            const headers = {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
            if (payload.info) {
                method = async () => gitCoreApi.get('/query-points/get-point-details-page', { params: { info: payload.info }, headers })
            } else {
                method = async () => gitCoreApi.post('/query-points/get-point-details-page',
                    {
                        info: payload.info,
                        idCaptura: payload.idCaptura,
                        date: payload.fecha,
                    },
                    { headers }
                )
            }
            const { status, data } = await method()

            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }

            return data
        } catch (error) {
            console.error(error)
        }
    },
    async obtenDatosCaptura(context, payload) {
        const { status, data } = await gitCoreApi.post('/query-points/get-point-details',
        {
            pointId: payload.punto, 
            userId: payload.usuario,
            startDate: payload.desde,
            endDate: payload.hasta
        },
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            const error = new Error(data.message || 'Error al obtener el listado')
            throw error
        }

        context.commit('setDatosPuntoDetalle', data)
    },
    async consultaPuntosObtenPdf(context, payload) {
        let endPoint = '/query-points/get-pdf'
        if (['Mitsubishi Reporte Mantenimiento Elevadores','Mitsubishi Reporte Mantenimiento Elevadores Descarga'].includes(payload.tipo)) endPoint = '/mitsubishi-reports/get-elevator-maintenance-format'
        const { status, data } = await gitCoreApi.get(endPoint,
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json',
            },
            responseType: 'blob',
            params: {
                type: payload.tipo,
                punto: payload.datosPdf.punto,
                usuario: payload.datosPdf.usuario,
                desde: payload.datosPdf.desde,
                hasta: payload.datosPdf.hasta,
                companyId: payload.compniaId
            }
        })

        if (payload.tipo === 'Mitsubishi Reporte Mantenimiento Elevadores Descarga') {
            const codigoPunto = payload.datosModal.generales.codigoPunto.valor.replace('-', '_')
            const fechaCaptura = payload.datosModal.actividades.find(ac => ac.actividad === 'Check In').preguntas[0].fecha.replace(' ', 'T')
            const fecha = format(new Date(fechaCaptura), 'ddMMyy')
            descargaPdf(data, `${codigoPunto}_${fecha}`)
            return
        }
        
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "certificado.pdf");
        document.body.appendChild(link);

        window.open(link)

        if (status !== 200) {
            const error = new Error(data.message || 'Error al obtener el listado')
            throw error
        }
    },
    async listarUsuariosMovil(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`configuration/user/get_mobile_users`, {
            },{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }

            context.commit('setListaUsuarioMovil', data)

        } catch (error) {
            console.error(error)
            throw error
        } 
    },
    async obtenPreguntasTiket(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/query-points/get-tiket-activity',
            {
                activityName: payload.nombreDeActividad
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }
    
            context.commit('setPreguntasTiket', data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async obtenLayoutTikets(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/query-points/get-tiket-layout',
            {
                activityName: payload.nombreDeActividad
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }
    
            descargaBaseSesentaycuatro(data, 'Layout Tikets', 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }
    },
    async guardaTiket(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/query-points/save-tiket',
            {
                questions: payload.preguntas,
                captureId: payload.idCaptura,
                status: payload.estatus,
                activity: payload.actividad
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }

            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async obtenPuntosDetallesWebyTabla(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/query-points/get-point-details-web-and-table',{},
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }
            context.commit('setPuntosDetalleWebyTabla', data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async guardaXlsTickets(context, payload) {
        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            const { status, data } = await gitCoreApi.post('/query-points/upload-file-ticket-insertion',solicitud,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }

            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async obtenEstatusTicket(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/query-points/get-status-ticket',{
                pointId: payload.puntoId
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el estatus')
                throw error
            }

            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async descargaHorizontalConsultaPuntos(context, { token, datos }) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get('/reports/export/horizontal',
            {
                params: {
                    startDate: datos.fechaInicio,
                    endDate: datos.fechaFin,
                    idUsers: datos.usuarios || [],
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el estatus')
                throw error
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    async consultaPdfGeneral(context, { token, datos }) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get('/reports/pdf/general',
            {
                params: {
                    data: datos
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el estatus')
                throw error
            }
            
            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    
}