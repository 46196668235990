/**
 * Crea un arreglo con N elementos, donde N corresponde al número de visitas semanales indicado
 * como argumento, y cada elemento es el tiempo de servicio base, también indicado. El propósito
 * es que se tenga la misma cantidad de tiempos de servicio que de número de visitas.
 * @param {Number} numeroVisitasSemanales 
 * @param {Number | String} tiempoServicioBase 
 * @returns 
 */
const generarTiemposServicio = (numeroVisitasSemanales, tiempoServicioBase) => {
    let i = 0
    const tiemposServicio = []
    while (i < numeroVisitasSemanales) {
        tiemposServicio.push(parseInt(tiempoServicioBase) * 60)
        i++
    }
    return tiemposServicio
}

const generarSemana = (visita) => {
    const lunes = Boolean(visita.lunes)
    const martes = Boolean(visita.martes)
    const miercoles = Boolean(visita.miercoles)
    const jueves = Boolean(visita.jueves)
    const viernes = Boolean(visita.viernes)
    const sabado = Boolean(visita.sabado)
    const domingo = Boolean(visita.domingo)

    if(!lunes && !martes && !miercoles && !jueves && !viernes && !sabado && !domingo) return null

    return [{
        monday: lunes,
        tuesday: martes,
        wednesday: miercoles,
        thursday: jueves,
        friday: viernes,
        saturday: sabado,
        sunday: domingo
    }]
}

export default class VisitOrder {
    /**
     * Representa a una orden para el caso de uso de planeación de visitas
     * @param { Object } visita - Objeto con la información de la visita
     * @param { Array } diasProhibidos - Array con los dias que hay prohibidos para la visita
     */
    constructor( visita, diasProhibidos ) {
        const { id, ritmo_por_semana: ritmoSemanalVisitas, frecuencia_a_la_semana: numeroVisitasPorSemana, tiempo_de_servicio: tiempoServicio, semana_de_la_primera_visita: semanaPrimeraVisita, semana_de_la_ultima_visita: semanaUltimaVisita } = visita
        this.id = id
        this.locationId = id
        this.visitRhythmInWeeks = ritmoSemanalVisitas
        this.numberOfVisitsPerWeek = numeroVisitasPorSemana
        this.serviceTimes = generarTiemposServicio(numeroVisitasPorSemana, tiempoServicio)
        if (semanaPrimeraVisita > 0 && semanaUltimaVisita > 0) {
            this.weekOfPossibleFirstVisit = semanaPrimeraVisita
            this.weekOfPossibleLastVisit = semanaUltimaVisita
        }
        this.visitPatternOptions = {
            forbiddenVisitDays: diasProhibidos
        }
        const semana = generarSemana(visita)
        if(semana) {
            this.visitPatternOptions.visitPatterns = semana
        }
    }
}