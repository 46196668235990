import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import gitCoreApi from '@/api/gitCoreApi'

export default {
    async subirArchivo(context, payload) {
        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('tipoSubida', payload.tipoSubida)
            solicitud.append('tipoCarga', payload.tipoCarga)

            const { data } = await gitCoreApi.post('/gitRoute/upload_file', solicitud, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${payload.token}` } })

            if (data.isError) {
                return { tipo: 'error', titulo: data.message }
            }

            return { tipo: 'success', titulo: data.message, data: data.data }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            console.error(error)
            return { tipo: 'error', titulo: 'Error', data: 'Algo salió mal' }
        }
    },

    async historialCarga(context, payload) {
        try {
            context.commit('setLoading', true, { root: true })
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('tipoSubida', payload.tipoSubida)
            solicitud.append('tipoCarga', payload.tipoCarga)

            const { data } = await gitCoreApi.get('/gitRoute/upload_file/history', { headers: { 'Authorization': `Bearer ${payload.token}` } })
            context.commit('setLoading', false, { root: true })

            context.commit('setHistorial', data)
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            console.error(error)
        }
    },

    async descargarArchivo(context, payload) {
        try {

            const { data } = await gitCoreApi.get(`/gitRoute/upload_file/history/${payload.id}`, { headers: { 'Authorization': `Bearer ${payload.token}` } })

            if (data.isError) {
                return { tipo: 'error', titulo: data.message }
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

            return { tipo: 'success', titulo: data.message, }
        } catch (error) {
            console.error(error)
        }
    },

    async descargarHistorial(context, payload) {
        try {

            const { data } = await gitCoreApi.get(`/gitRoute/upload_file/history/create_xls`, { headers: { 'Authorization': `Bearer ${payload.token}` } })

            if (data.isError) {
                return { tipo: 'error', titulo: data.message }
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)

            return { tipo: 'success', titulo: data.message, }
        } catch (error) {
            console.error(error)
        }
    },

    async buscarTareaProgramada(context, payload) {
        try {
            const { data } = await gitCoreApi.get(`/process/task`, {
                params: {
                    id: payload.id
                },
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            return data
        } catch (error) {
            console.error(error)
        }
    }
}