import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    async buscarConfPtvPaquetes(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { status, data } = await gitCoreApi.get(
                `/configuration/ptv_paquete`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setPaquetes', data)
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            throw new Error(error)
        } finally {
            context.commit('setLoading', false, { root: true })
        }
    },

    async nuevoPaquete(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { nombre, repCliente, repGitCloud, estatus } = payload.data
            const { data } = await gitCoreApi.post(
                `/configuration/ptv_paquete`,{
                    nombre: nombre,
                    rep_cliente: repCliente,
                    rep_git_cloud: repGitCloud,
                    status: estatus 
                }, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            return {
                tipo: 'success',
                titulo: data.message
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }

            throw new Error(error)
        }
    },

    async actualizarPaquete(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { nombre, repCliente, repGitCloud, estatus } = payload.data
            const { data } = await gitCoreApi.patch(
                `/configuration/ptv_paquete/${payload.id}`,{
                    nombre: nombre,
                    rep_cliente: repCliente,
                    rep_git_cloud: repGitCloud,
                    status: estatus 
                }, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            // Se valida si se requiere un mensaje de estatus, para los casos de activar, desactivar o eliminar
            if (payload.getMensajeEstatus) {
                switch (estatus) {
                    case 0:
                        return { tipo: 'success', titulo: 'El Paquete se ha desactivado correctamente' }
                    case 1:
                        return { tipo: 'success', titulo: 'El Paquete se ha activado correctamente' }
                    case 2:
                        return { tipo: 'warn', titulo: 'El Paquete se ha eliminado' }
                    default:
                        break
                }
            }

            return {
                tipo: 'success',
                titulo: data.message || ''
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }

            throw new Error(error)
        }
    },

    async descargarXls(context, payload) {
        try {
            const { data } = await gitCoreApi.get(
                `/configuration/ptv_paquete/create_xls`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                    params: { getEmpty: payload.getEmpty }
                }
            )

            const { data_base64, type } = data

            const nombreArchivo = payload.getEmpty
                ? 'Template_paquetes'
                : `Exporta_paquetes_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data_base64, nombreArchivo, type)
        } catch (error) {
            throw new Error(error)
        }
    },
    async importXls(context, payload) {
        try {
            context.commit('setLoading', true, { root: true })
            const solicitud = new FormData()
            solicitud.append('file', payload.file)

            const { data } = await gitCoreApi.post('/configuration/ptv_paquete/import_xls', solicitud ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            return {
                tipo: 'success',
                titulo: data.message
            }

        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }
            throw error
        }
    },
}