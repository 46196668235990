/** Configuración en diversos idiomas de las diferentes palabras usadas en los componentes de primevue */

/** Español, Spanish */
const es = {
    startsWith: 'Empieza con',
    contains: 'Contiene',
    notContains: 'No contiene',
    endsWith: 'Termina con',
    equals: 'Igual a',
    notEquals: 'No es igual a',
    noFilter: 'Sin filtro',
    lt: 'Menor que',
    lte: 'Menor o igual que',
    gt: 'Mayor que',
    gte: 'Mayor o igual que',
    dateIs: 'Fecha es',
    dateIsNot: 'Fecha no es',
    dateBefore: 'Fecha antes de',
    dateAfter: 'Fecha después de',
    clear: 'Limpiar',
    apply: 'Aplicar',
    matchAll: 'Buscar todos',
    matchAny: 'Buscar alguno',
    addRule: 'Agregar regla',
    removeRule: 'Remover regla',
    accept: 'Sí',
    reject: 'No',
    choose: 'Selecciona',
    upload: 'Carga',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
    monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: 'Hoy',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Débil',
    medium: 'Medio',
    strong: 'Fuerte',
    passwordPrompt: 'Ingresa una contraseña',
    emptyFilterMessage: 'No se encontraron resultados',
    emptyMessage: 'No hay datos disponibles',
    aria: { 
        trueLabel: 'Verdadero',
        falseLabel: 'Falso',
        nullLabel: 'No seleccionado',
        star: '1 estrella',
        stars: '{estrella} estrellas',
        selectAll: 'Todos los elementos seleccionados',
        unselectAll: 'Todos los elementos deseleccionados',
        close: 'Cerrar',
        previous: 'Anterior',
        next: 'Siguiente',
        navigation: 'Navegación',
        scrollTop: 'Desplazamiento hacia arriba',
        moveUp: 'Mover hacia arriba',
        moveTop: 'Mover arriba',
        moveDown: 'Mover hacia abajo',
        moveBottom: 'Mover hacia abajo',
        moveToTarget: 'Mover al destino',
        moveToSource: 'Mover al origen',
        moveAllToTarget: 'Mover todo al destino',
        moveAllToSource: 'Mover todo al origen',
        pageLabel: '{página}',
        firstPageLabel: 'Primera página',
        lastPageLabel: 'Última página',
        nextPageLabel: 'Página siguiente',
        prevPageLabel: 'Página anterior',
        rowsPerPageLabel: 'Filas por página',
        jumpToPageDropdownLabel: 'Desplegable para ir a la página',
        jumpToPageInputLabel: 'Entrada para ir a la página',
        selectRow: 'Fila seleccionada',
        unselectRow: 'Fila deseleccionada',
        expandRow: 'Fila expandida',
        collapseRow: 'Fila colapsada',
        showFilterMenu: 'Mostrar menú de filtros',
        hideFilterMenu: 'Ocultar menú de filtros',
        filterOperator: 'Operador de filtro',
        filterConstraint: 'Restricción de filtro',
        editRow: 'Edición de fila',
        saveEdit: 'Guardar edición',
        cancelEdit: 'Cancelar edición',
        listView: 'Vista de lista',
        gridView: 'Vista de cuadrícula',
        slide: 'Diapositiva',
        slideNumber: '{número de diapositiva}',
        zoomImage: 'Zoom de imagen',
        zoomIn: 'Acercar',
        zoomOut: 'Alejar',
        rotateRight: 'Rotar a la derecha',
        rotateLeft: 'Rotar a la izquierda'
    }
}

export { es }