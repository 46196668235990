export default {
    getListaTareasApp(state) {
        return state.listaTareasApp
    },
    getListaTareasDetails(state) {
        return state.listaTareasDetails
    },
    getListaTareasUsuarios(state) { 
        return state.listaTareasUsuarios
    },
    getListaTareasActividades(state) { 
        return state.listaTareasActividades
    },
    getListaTareasPuntos(state) { 
        return state.listaTareasPuntos
    },
}