export default {
    async setLoadingNoPlanificados(state, payload) {
        state.loadingNoPlanificados = payload
    },
    async setListaAreasReasignacion(state, payload) {
        state.listaAreasReasignacion = payload
    },
    async setIndexListaAreasReasignacion(state, payload) {
        state.listaAreasReasignacion[payload.index] = payload.valor
    },
    async setListaNoPlanificadosReasignacion(state, payload) {
        state.listaNoPlanificadosReasignacion = payload
    },
    async setIndexListaNoPlanificadosReasignacion(state, payload) {
        state.listaNoPlanificadosReasignacion[payload.index] = payload.valor
    },
    async setListaRutasReasignacion(state, payload) {
        state.listaRutasReasignacion = payload
    },
    async setListaVehiculosReasignacion(state, payload) {
        state.listaVehiculosReasignacion = payload
    },
    async setCargasReasignacion(state, payload) {
        state.cargasReasignacion = payload
    },
    async setVehiculoReasignacion(state, payload) {
        state.vehiculoReasignacion = payload
    },
    async setDiasModificadosReasignacion(state, payload) {
        state.diasModificadosReasignacion = payload
    },
    async setSolicitudPlanTourReasignacion(state, payload) {
        state.solicitudPlanTourReasignacion = payload
    },
    async setRespuestaPlanTourReasignacion(state, payload) {
        state.respuestaPlanTourReasignacion = payload
    },
    async addRespuestaPlanTourReasignacion(state, payload) {
        state.respuestaPlanTourReasignacion.push(payload)
    },
    async setIsNecesitaGuardarInformacion(state, payload) {
        state.isNecesitaGuardarInformacion = payload
    },
    async setPuntosMarcadosReasignacion(state, payload) {
        state.puntosMarcadosReasignacion = payload
    },
    async addPuntosMarcadosReasignacion(state, payload) {
        state.puntosMarcadosReasignacion.push(payload)
    },
    async setErroresReasignacion(state, payload) {
        state.erroresReasignacion = payload
    },
    async addErroresReasignacion(state, payload) {
        state.erroresReasignacion.push(payload)
    },
    async setListaCodigosPuntoNoPlanificables(state, payload) {
        state.listaCodigosPuntoNoPlanificables = payload
    },
    setReportesCostoTareas(state, payload) {
        state.reportesCostoTareas = [...payload]
    },
    addReporteCostoTarea(state, payload) {
        const existingReportIndex = state.reportesCostoTareas.findIndex(
            report => parseInt(report.vehicleId) === parseInt(payload.vehicleId)
        )
        if (existingReportIndex > -1) state.reportesCostoTareas.splice(existingReportIndex, 1)
        state.reportesCostoTareas.push(payload)
    },
    setAlmacenesReasignacion(state, payload) {
        state.almacenesReasignacion = [...payload]
    },
    setListaViajesReasignacion(state, payload) {
        state.listaViajesReasignacion = payload
    },
    setFechaSinDia(state, payload) {
        state.fechaSinDia = payload
    },
    setListaBreak(state, payload) {
        state.listaBreak = payload
    },
    setInsertaBreak(state, payload) {
        state.insertaBreak = payload
    },
    setEliminarBreak(state, payload) {
        state.eliminarBreak = payload
    },
    setActualizarBreak(state, payload) {
        state.actualizarBreak = payload
    },
    setIdEliminarBreak(state, payload) {
        state.idEliminarBreak = payload
    },
    setIdVehiculoSeleccionadoReasignacion(state, payload) {
        state.idVehiculoSeleccionadoReasignacion = payload
    },
    setTerminoAplicarCambios(state, payload) {
        state.terminoAplicarCambios = payload
    },
    setSemaforoConfiguracion(state, payload) {
        state.semaforoConfiguracion = payload
    }
}