import { Zona, ZonaPFA } from "../models/Zona.model"

export default {
    getListaZonas(state, getters) {
        return state.listaZonas.map( zona => {
                if (getters.esPFA) return new ZonaPFA(zona)
                return new Zona(zona)
            })
    },

    getSugerenciasTipoZona(state) {
        return [...new Set(state.listaZonas
            .map(zona => zona?.opciones?.tipoZona)
            .filter(zona => zona)
        )]
    }

}