import gitCoreApi from '@/api/gitCoreApi'
import { hereMapsAutoSuggest } from '@/api/hereMapsApis'

export default {
    async getListaPuntos(context, payload) {
        context.commit('setLoading', true, {root: true})
        try {
            const { data } = await gitCoreApi.get('/gitRoute/preview/points_of_day', {
                headers: {'Authorization': `Bearer ${payload.token}`},
                params: { warehouseName: payload.warehouseName, shift: payload.shift }
            })
            context.commit('setListaPuntos', data)

        } catch (error) {
            context.commit('setLoading', false, {root: true})
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false, {root: true})
        }
    },

    async getSugerencias(context, payload) {
        try {
            const { data } = await hereMapsAutoSuggest.get('/autosuggest', {
                params: { q: payload.q, at: payload.at }
            })
            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async actualizarPunto(context, payload) {
        try {
            const { data } = await gitCoreApi.put(`/gitRoute/preview/points`, {
                punto: payload.punto,
            }, {headers: {'Authorization': `Bearer ${payload.token}`}})
            return {
                tipo: data.isError ? 'error' : 'success',
                mensaje: data.message,
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async getHistoricoModificaciones(context, payload) {
        try {
            const { data } = await gitCoreApi.get(`/gitRoute/preview/points_modifications`, {
                headers: {'Authorization': `Bearer ${payload.token}`},
                params: { date: payload.fecha }
            })
            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    },

    async eliminarPunto(context, payload) {
        try {
            const { data } = await gitCoreApi.delete(`/gitRoute/preview/points/${payload.id}`, {
                headers: {'Authorization': `Bearer ${payload.token}`},
            })
            return {
                tipo: data.isError ? 'error' : 'success',
                mensaje: data.message,
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    },
}