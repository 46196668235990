import gitCoreApi from '@/api/gitCoreApi'

export default {
    async getCalendarEvents(context, payload) {
        const { status, data } = await gitCoreApi.get("/calendar/view?companyId=192",
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            }
        )

        if (status === 200) {
            context.commit("setCalendarEvents", data.data)
            context.commit("setWorkloadName", data.workload_name)
            context.commit("setUsername", data.users)
        } else {
            const error = new Error(data.message || 'Error al obtener el listado')
            throw error
        }

        return data
    }
}