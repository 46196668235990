export default {
    getPuntosMatriz(state) {
        return state.puntosMatriz
    },
    getPuntosNeo4jMatriz(state) {
        return state.puntosNeo4jMatriz
    },
    getRelacionesNeo4jMatriz(state) {
        return state.relacionesNeo4jMatriz
    },
    getClustersXcaret(state) {
        return state.clustersXcaret
    },
    getZonasXcaret(state) {
        return state.zonasXcaret
    },
    getHotelesXcaret(state) {
        return state.hotelesXcaret
    },
    getConfiguracionZonasXcaret(state) {
        return state.configuracionZonasXcaret
    },
}
