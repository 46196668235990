export default {
    setCargasActivasResumen(state, payload) {
        state.cargasActivasResumen = payload
    },
    setCargaSeleccionadaResumen(state, payload) {
        state.cargaSeleccionadaResumen = payload
    },
    setVehiculoResumen(state, payload) {
        state.vehiculoResumen = payload
    },
    setFechaResumen(state, payload) {
        state.fechaResumen = payload
    },
    setListaVehiculosResumen(state, payload) {
        state.listaVehiculosResumen = payload
    },
    setListaPuntoNuevo(state,payload) {
        state.listaPuntoNuevo = payload
    },
    setIdsTarea(state,payload) {
        state.idsTarea = payload
    },
    setCargandoCargasActivas(state, payload) {
        state.cargandoCargasActivas = payload
    }
}