import gitCoreApi from '@/api/gitCoreApi'

export default {
    async fecha(context, payload){
        context.commit('setFecha', payload.fecha)
    },
    async getConfiguraciongITXcaret(context, payload){
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('xcaret/general/configuration',
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            context.commit('setConfigurationActiveXcaret', data)
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            await context.dispatch('obtenerConfiguraciongITXcaretRuteo', payload)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    }
}