import { MonitoreoUsuarioData } from "../classes/MonitoreoUsuarioData"

export default {
    setUsuariosUbicacion(state, payload) {
        state.usuariosUbicacion = payload.map(usuario => new MonitoreoUsuarioData(usuario))
    },
    setUsuariosSeguimiento(state, payload) {
        state.usuariosSeguimiento = payload
    },
    asignaUbicaciones(state, payload) {
        state.usuariosUbicacion = state.usuariosUbicacion.map(usuario => {
            const ubicacion = payload.find(ubicacion => ubicacion.fkusuario === usuario.id)
            if (ubicacion) {
                usuario.defineUbicacion(ubicacion)
                usuario.defineFechaHora(ubicacion)
            }
            return usuario
        })
    },
    limpiarDatos(state) {
        state.usuariosUbicacion = []
        state.usuariosSeguimiento = []
        state.loadingSeguimiento = false
        state.loadingUbicacion = false
        state.loadingUsuarios = false
        state.filtros = {
            verRutas: false,
            verSeguimiento: false,
            tipoUsuario: [],
            usuariosMostrar: 'Hoy',
        }
    },
    setLoadingUsuarios(state, payload) { state.loadingUsuarios = payload },
    setLoadingUbicacion(state, payload) { state.loadingUbicacion = payload },
    setLoadingSeguimiento(state, payload) { state.loadingSeguimiento = payload },
    // Filtros generales { tipoUsuario: [], usuariosMostrar: 'Todos' }
    setFiltros(state, payload) { state.filtros = payload }
}