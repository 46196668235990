import actions from './actions'
import mutations from './mutations'
import getters from './getters'
export default {
    state() {
        return {
            regionsList: [],
            regionSelected: {},
            vehiclesList: [],
            hotelsList: [],
            parksList: [],
            loadingVehicles: false
        }
    },
    mutations,
    actions,
    getters
}
