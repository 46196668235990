export default {
    infoPuntos(state) {
        return state.infoPuntos
    },
    infoVehiculos(state) {
        return state.infoVehiculos
    },
    infoOpciones(state) {
        return state.infoOpciones
    },
    infoAlmacenes(state) {
        return state.infoAlmacenes
    },
    infoNoPlanificados(state) {
        return state.infoNoPlanificados
    },
    getConfiguracionPlaneacion(state){
        return state.configuracionPlaneacion
    },
    getInfoPuntosConFrecuenciasNoPlanificables(state) {
        return state.infoPuntosConFrecuenciasNoPlanificables
    }
}