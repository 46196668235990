
/* eslint-disable */
import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '../../../../api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    /**
     * Devuelve la lista de Cps
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoColoniasCpsListar(context, payload) {
        try {
            context.commit('setLoading', true)
            const promises = [
                gitCoreApi.get('/configuration/zip/get_zips', {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaCps', response.data)
                }),
                gitLogisticsApi.post('/configuracion/colonias/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaColonias', response.data.response)
                })]
            await Promise.all(promises)

            context.commit('setLoading', false)
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crea un nuevo Cp
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoCpsNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/cps/nuevo',
                {
                    "cp": payload.cp,
                    "estatus": payload.estatus
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Cp')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita un nuevo Cp
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoCpsEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/cps/editar',
                {
                    "id": payload.id,
                    "cp": payload.cp,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Cp')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Cambia el estatus de un codigo postal
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoCpsCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitLogisticsApi.post('/configuracion/cps/cambiarEstatus',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Cp se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Cp se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El Cp se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crea un nuevo Cp
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoColoniasNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/colonias/nuevo',
                {
                    "clave": payload.clave,
                    "colonia": payload.colonia,
                    "estatus": payload.estatus
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el listado')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async catalogoColoniasEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/colonias/editar',
                {
                    "id": payload.id,
                    "clave": payload.clave,
                    "colonia": payload.colonia,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el listado')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async catalogoColoniasCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/colonias/cambiarEstatus',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Colonia se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Colonia se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La Colonia se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoColoniasCpsListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importColonia(context, payload) {
        try {
            console.log(payload)
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/suburb/import_xls_suburb', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoColoniasCpsListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importCP(context, payload) {
        try {
            console.log(payload)
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/zip/import_xls_zip', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoColoniasCpsListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async creaExcelColonias(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/suburb/export_xls_suburb', {} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            descargaBaseSesentaycuatro(data, 'Colonias', 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },
    async creaExcelCodigosPostales(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/zip/export_xls_zip', {} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            descargaBaseSesentaycuatro(data, 'Codigos_Postales', 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    }
}