import gitCoreApi from '@/api/gitCoreApi'

export default {

    /**
     * Devuelve las lista de permisos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoListarPermisos(context, payload) {
        try{
            context.commit('setLoading', true)
            context.dispatch('listarPermisosAplicacion', payload)
            context.dispatch('catalogoListarMenuSubMenus', payload)
            const { status, data } = await gitCoreApi.post(`/configuration/menu/get_permisos_rol_menus`, {
                "rolId": payload.rolId
            },{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaPermisos', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
//___________________________________________________________________________________________________________________________________
    /**
     * Devuelve las lista de permisos de aplicacion
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async ListarPermisosAplicacionByRol(context, payload) {
        try{
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`/configuration/permissions_aplication/get_list_permissions_aplication_by_child_rol`, {
                isActive:true,
                "rolId": payload.rolId
            },{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaPermisosAplicacion', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    
//__________________________________________________________________________________________________

    /**
     * Crear una nuevo Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoPermisoNuevo(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/menu/insert_permissions_menu_by_rol',
                {
                    "alias": payload.alias,
                    "fk_sub_menu": payload.fk_sub_menu,
                    "estatus": payload.estatus,
                    "rolId": payload.rolId
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el nuevo Permiso')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarPermisos', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
//_____________________________________________________________________________________________________________________________
        /**
     * Crear una nuevo Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
        async NuevoPermisoAplicacionByRol(context, payload) {
            try {
                context.commit('setLoading', true)
                const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/insert_rol_permissions_aplication',
                    {
                        "fk_permisos_aplicacion": payload.fk_permisos_aplicacion,
                        "estatus": payload.estatus,
                        "rolId": payload.rolId
                    }, {
    
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al guardar el nuevo Permiso')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null
    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('ListarPermisosAplicacionByRol', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },

//_________________________________________________________________________________________

    /**
     * Edita un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoPermisoEditar(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/menu/update_permissions_menu',
                {
                    "id": payload.id,
                    "alias": payload.alias,
                    "fk_sub_menu": payload.fk_sub_menu,
                    "estatus": payload.estatus,
                    "rolId": payload.rolId
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Permiso')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarPermisos', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

//__________________________________________________________________________________________________________________________

        /**
     * Edita un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
        async EditarPermisoAplicacion(context, payload) {
            try {
                context.commit('setLoading', true)
                const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/update_rol_permissions_aplication',
                    {
                        "id": payload.id,
                        "fk_permisos_aplicacion": payload.fk_permisos_aplicacion,
                        "estatus": payload.estatus,
                        "rolId": payload.rolId
                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al editar Permiso')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null
    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('ListarPermisosAplicacionByRol', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },
    

//_________________________________________________________________________________________________

    /**
     * Cambia el estatus de un Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */

    async catalogoPermisosCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/menu/update_permissions_menu',{ 
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El permiso se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El permiso se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El permiso se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoListarPermisos', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    
//_______________________________________________________________________________________________________________________________________

    async permisosAplicacionCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/update_rol_permissions_aplication',{ 
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El permiso se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El permiso se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El permiso se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('ListarPermisosAplicacionByRol', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
//_________________________________________________________________________________________________________
    async catalogoListarMenuSubMenus(context, payload) {

        try{

            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(`configuration/menu/get_submenu_menu`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaMenuSubMenus', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

}