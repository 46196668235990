import gitCoreApi from '@/api/gitCoreApi' 

/* eslint-disable */
export default {
    async obtenerAllInformaciongITXcaretRuteo(context, payload) {
        context.commit('setLoading',true)
        await context.dispatch('obtenerInformaciongITXcaretRuteo', payload)
        await context.dispatch('obtenerResumengITXcaretRuteo', payload)
        await context.dispatch('dataStepsgITXcaretRuteo', payload)
        await context.dispatch('obtenerConfiguraciongITXcaretRuteo', payload)
        context.commit('setLoading',false)
    },
    async obtenerInformaciongITXcaretRuteo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get('xcaret/route/information', {
                params: {
                    date: payload.fecha,
                    idProcess: payload.idProceso
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setInformationgITXcaretRuteo', data)

        } catch (error) {
            throw new Error(error)
        }
    },

    async obtenerResumengITXcaretRuteo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get('xcaret/route/summary', {
                params: {
                    date: payload.fecha,
                    idProcess: payload.idProceso
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setResumengITXcaretRuteo', data)

        } catch (error) {
            throw new Error(error)
        }
    },

    async dataStepsgITXcaretRuteo(context, payload){
        try {
            const { status, data } = await gitCoreApi.get('xcaret/route/status_route', {
                params: {
                    date: payload.fecha,
                    nameProcess: payload.nombreProceso
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            context.commit('setDataSteps', data)

        } catch (error) {
            throw new Error(error)
        }
    },
    
    async ruteoParquegITXcaretRuteo(context, payload){
        try {
            const { status } = await gitCoreApi.post('xcaret/route/create_route', 
            {
                date: payload.fecha,
                type: payload.type,
                nameProcess: payload.nombreProceso
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
        } catch (error) {
            throw new Error(error)
        }
    },
    async ruteoParquegITXcaretRuteoIndividual(context, payload){
        context.commit('setLoading',true)
        await  context.dispatch('ruteoParquegITXcaretRuteo', payload)
        await context.dispatch('obtenerAllInformaciongITXcaretRuteo',  payload )
        context.commit('setLoading',false)

    },
    async ruteoParquegITXcaretRuteoCorrido(context, payload){
        context.commit('setLoading',true)
        payload['type'] = 'Uniparques'
        await context.dispatch('ruteoParquegITXcaretRuteo', payload)
        await context.dispatch('dataStepsgITXcaretRuteo', payload)
        payload['type'] = 'Biparques'
        await context.dispatch('ruteoParquegITXcaretRuteo', payload)
        await context.dispatch('dataStepsgITXcaretRuteo', payload)
        payload['type'] = 'Triparques'
        await context.dispatch('ruteoParquegITXcaretRuteo', payload)
        await context.dispatch('dataStepsgITXcaretRuteo', payload)
        payload['type'] = 'Multiparques'
        await context.dispatch('ruteoParquegITXcaretRuteo', payload)
        await context.dispatch('obtenerAllInformaciongITXcaretRuteo',  payload )
        await context.dispatch('listaProcesosgITXcaret',  payload )
        context.commit('setLoading',false)
    },
    async obtenerConfiguraciongITXcaretRuteo(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('xcaret/route/options', {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            const { configurations, warehouses } = data

            context.commit('setWarehousesgITXcaretRuteo', warehouses)
            context.commit('setOpcionesgITXcaretRuteo', configurations)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async actualizarConfiguraciongITXcaretRuteo(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.put('xcaret/route/options', 
                {
                    options: payload.opciones,
                    nameConfiguration: payload.nombreConfiguracion,
                    idConfiguration: payload.idConfiguracionRuteo
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            await context.dispatch('obtenerConfiguraciongITXcaretRuteo', payload)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async guardarConfiguraciongITXcaretRuteo(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.post('xcaret/route/options', 
                {
                    nameConfiguration: payload.nombreConfiguracion,
                    options: payload.opciones
                },
                {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            
            await context.dispatch('obtenerConfiguraciongITXcaretRuteo', payload)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async eliminarRuteoITXcaretRuteo(context, payload) {
        console.log("🚀 ~ eliminarRuteoITXcaretRuteo ~ payload:", payload)
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.delete('xcaret/route', {
                params: {
                    date: payload.fecha,
                    idProcess: payload.idProceso
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            await context.dispatch('obtenerAllInformaciongITXcaretRuteo', payload)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async obtenConfiguraciones(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('xcaret/route/configurations', {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setListaConfiguraciones', data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async eliminarConfiguracion(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.delete('xcaret/route/configurations', {
                params: {
                    id: payload.id
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            await context.dispatch('obtenerConfiguraciongITXcaretRuteo', payload)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async obtenInfoConfiguracion(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('xcaret/route/configuration', {
                params: {
                    id: payload.id
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.commit('setInfoConfiguracion', data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async listaProcesosgITXcaret(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status, data } = await gitCoreApi.get('xcaret/route/processes_complete', {
                params: {
                    date: payload.fecha
                },
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
            context.commit('setListaProcesos', data)

        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async cambioProcesogITXcaretRuteo(context, payload) {
        try {
            context.commit('setLoading',true)
            const { status } = await gitCoreApi.post('xcaret/route/change_process', {
                date: payload.fecha,
                idProcess: payload.idProceso
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading',false)
        }
    },
    async nombreConfiguracionActivaXcaret(context, payload) {
        context.commit('setConfigurationActiveXcaret', payload.nombreConfiguracion)
    }
}