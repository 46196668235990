export default {
    planVisitsRequest (state) {
        return state.planVisitsRequest
    },
    planVisitsResponse (state) {
        return state.planVisitsResponse
    },
    mostrarGitVisit(state){
        return state.mostrarGitVisit
    },
    getErroresPlanVisit(state){
        return state.erroresPlanVisit
    },
    getIsConfiguracion(state){
        return state.isConfiguracion
    },
    getIsGitVisits(state) {
        return state.isGitVisits
    },
    getGitVisitResponse(state) {
        return state.gitVisitResponse
    }
}