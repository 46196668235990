
import xServerApi from '@/api/xServerApi'
import xServerJob from '@/models/xServerJob'
import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'
import { removerElementoArray } from '@/helpers/helpers'
import { format } from 'date-fns'
import { obtenerReporteCostoVehiculo } from "@/helpers/xServer"
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { Buffer } from 'buffer'


let peticionesProceso = 0
let peticionesTotales = 0
let peticionesCompletadas = 0
/* eslint-disable*/
export default {
    async obtenerInformacionReasignacion(context, payload) {
        try {
            context.commit('setLoading', true)
            context.commit('setLoadingNoPlanificados', true)

            const { status, data } = await gitCoreApi.get(`/gitlogistics/reassignment`,
                {
                    params: {
                        idProcess: payload.fkProceso
                    },
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                console.error(error)
            }

            const { listaAreas, listaNoPlanificados, listaRutas, vehiculos, listaViajes, almacenes } = data
    
            listaNoPlanificados.forEach(element => {
                if (element.cantidad > 1) {
                    for (let index = 0; index < element.cantidad - 1; index++) {
                        listaNoPlanificados.push(element)
                    }
                }
            })
            context.commit('setListaAreasReasignacion', listaAreas)
            context.commit('setListaNoPlanificadosReasignacion', listaNoPlanificados)
            context.commit('setListaRutasReasignacion', listaRutas)
            context.commit('setListaVehiculosReasignacion', vehiculos)
            context.commit('setAlmacenesReasignacion', almacenes)
            context.commit('setListaViajesReasignacion', listaViajes)
            
        } catch (error) {
            console.error(error)
            throw error
        }finally{
            context.commit('setLoading', false)
            context.commit('setLoadingNoPlanificados', false)
        }
    },
    async obtenerListaReasignacion(context, payload) {
        try {

            const { status, data } = await gitLogisticsApi.post('/reasignacion/obtenerProcesos', { gruposActivos: payload.gruposActivos }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setCargasReasignacion', data)

        } catch (error) {
            console.error(error)
            throw error
        } finally {
        }
    },
    async guardarVehiculoReasignacion(context, payload) {
        context.commit('setVehiculoReasignacion', payload)
    },
    async cambiarAreaReasignacion(context, payload) {
        const { idPunto, fkTareaAnterior, fkTareaNuevo, nombreTareaNuevo, descripcionTareaNuevo } = payload
        const listaAreaIndex = context.state.listaAreasReasignacion.findIndex(({ fk_punto, fk_tarea }) => fk_punto === idPunto && fk_tarea === fkTareaAnterior)
        let listaArea = JSON.parse(JSON.stringify(context.state.listaAreasReasignacion[listaAreaIndex]))
        listaArea.fk_tarea = fkTareaNuevo
        listaArea.nombre_tarea = nombreTareaNuevo
        listaArea.descripcion_tarea = descripcionTareaNuevo
        context.commit('setIndexListaAreasReasignacion', {
            index: listaAreaIndex,
            valor: listaArea
        })
    },
    cambiarNoPlanificadosReasignacion(context, payload) {
        const { idPunto, fkTareaAnterior, fkTareaNuevo, nombreTareaNuevo, descripcionTareaNuevo } = payload
        const listaRutas = JSON.parse(JSON.stringify(context.state.listaRutasReasignacion))
        const listaNoPlanificados = JSON.parse(JSON.stringify(context.state.listaNoPlanificadosReasignacion))
        const puntosACambiar = listaNoPlanificados.filter(({ fk_punto, fk_tarea }) => fk_punto === idPunto && fk_tarea === fkTareaAnterior)
        const puntosModificados = puntosACambiar.map(punto => {
            const indexPunto = listaNoPlanificados.findIndex(noPlan => noPlan.fk_punto === idPunto && noPlan.fk_tarea === fkTareaAnterior && noPlan.id === punto.id)
            punto.fk_tarea = fkTareaNuevo
            punto.nombre_tarea = nombreTareaNuevo
            punto.descripcion_tarea = descripcionTareaNuevo
            listaNoPlanificados.splice(indexPunto, 1)
            return punto
        })
        // Actualización a los no planificados
        context.commit('setListaNoPlanificadosReasignacion', [...listaNoPlanificados, ...puntosModificados])
        // Actualización a las rutas o puntos planificados
        const rutasAEliminar = listaRutas.map((ruta, indexRuta) => ruta.fk_tarea === fkTareaAnterior && ruta.fk_punto === idPunto ? indexRuta : '').filter(String)
        if (rutasAEliminar.length > 0) {
            rutasAEliminar.forEach(indexRuta => listaRutas.splice(indexRuta, 1))
            context.commit('setListaRutasReasignacion', listaRutas)
        }
    },
    async cambiarRutasReasignacion(context, payload) {
        const { codigoPunto, fkTarea, diaAnterior, diaActual } = payload
        let listaRutasInfo = JSON.parse(JSON.stringify(context.state.listaRutasReasignacion))
        const rutaIndex = listaRutasInfo.findIndex(({ codigo_punto, fk_tarea, dia }) => codigo_punto === codigoPunto && fk_tarea === fkTarea && dia === diaAnterior)
        listaRutasInfo[rutaIndex].dia = diaActual
        context.commit('setListaRutasReasignacion', listaRutasInfo)
    },
    async eliminarNoPlanificadosReasignacion(context, payload) {
        const { codigoPunto, fkTarea } = payload
        let listaNoPlanificadosInfo = JSON.parse(JSON.stringify(context.state.listaNoPlanificadosReasignacion))
        const indexEvento = listaNoPlanificadosInfo.findIndex(({ codigo_punto, fk_tarea }) => codigo_punto === codigoPunto && fk_tarea === fkTarea)
        removerElementoArray(listaNoPlanificadosInfo, listaNoPlanificadosInfo[indexEvento])
        context.commit('setListaNoPlanificadosReasignacion', listaNoPlanificadosInfo)
    },
    async agregarNoPlanificadosReasignacion(context, payload) {
        const { listaRutas, fkTareaNuevo, nombreTareaNuevo, descripcionTareaNuevo } = payload
        const noPlanificados = listaRutas.map(ruta => {
            const { codigo_punto, descripcion_punto, fk_punto, latitud, longitud, detalles_punto } = ruta
            return {
                id: 0,
                codigo_punto,
                descripcion_punto,
                fk_punto,
                fk_tarea: fkTareaNuevo,
                latitud,
                longitud,
                nombre_tarea: nombreTareaNuevo,
                descripcion_tarea: descripcionTareaNuevo,
                detalles_punto
            }
        })
        const infoNoPlanificados = JSON.parse(JSON.stringify(context.state.listaNoPlanificadosReasignacion))
        context.commit('setListaNoPlanificadosReasignacion', infoNoPlanificados.concat(noPlanificados))
    },
    async agregarRutasDeNoPlanificadoReasignacion(context, { noPlanificado, dia }) {
        const { servicio_detalle, tipo } = noPlanificado
        const ruta = {
            ...noPlanificado,
            id: 0,
            consecutivo: 0,
            servicio: servicio_detalle,
            servicio_detalle: servicio_detalle,
            dia,
            hora_inicio: '00:00',
            hora_fin: '00:00',
            tipo: tipo ? tipo : 'Punto'
        }
        const listaRutasInfo = JSON.parse(JSON.stringify(context.state.listaRutasReasignacion))
        listaRutasInfo.push(ruta)
        context.commit('setListaRutasReasignacion', listaRutasInfo)
    },
    async agregarDiasModificadosReasignacion(context, payload) {
        const diasReplanificar = JSON.parse(JSON.stringify(context.state.diasModificadosReasignacion))
        if (!diasReplanificar.some(({ dia, vehiculo }) => dia === payload.dia && vehiculo === payload.vehiculo)) {
            context.commit('setDiasModificadosReasignacion', diasReplanificar.concat(payload))
        }
    },
    async aplicarCambiosReasignacion(context, payload) {
        context.commit('setLoading', true)
        context.commit('setTerminoAplicarCambios', false)
        const { informacionReasignacion, diasReplanificacion } = payload
        const { listaRutas, vehiculos, extenderHorario } = informacionReasignacion
        const peticiones = []

        const modoDistancia = {
            "$type": "DirectDistance"
        }
        let opcionesSol = {
            "restrictions": {
                "maximumTravelTimePerTour": 39600,
                "singleTripPerTour": true
            },
            "orderGroupConsideration": {
                "oneGroupPerTrip": true,
                "oneTripPerGroup": true
            },
            "calculationMode": "STANDARD"
        }
        
        diasReplanificacion.forEach(replanificacion => {
            const fecha = replanificacion.dia
            const formatoFecha = new Date(fecha + "T12:00:00.000Z")
            const diaSemana = formatoFecha.getDay()
            const rutasReplanificar = listaRutas.filter(ruta => ruta.dia === fecha && ruta.fk_tarea === replanificacion.vehiculo && ruta.hasOwnProperty('codigo_punto'))
            const infoVehiculo = obtenerInformacionVehiculo(vehiculos,replanificacion.vehiculo)
            const { idTarea, horaInicio, horaFin, horaInicioSabado, horaFinSabado, horaInicioDomingo, horaFinDomingo, toleranciaVisita, almacenComienzo, almacenDestino } = infoVehiculo

            if (rutasReplanificar.length === 0) return

            const conductoresSol = []
            const vehiculosSol = []
            const ubicacionesSol = []
            const ordenesSol = []

            const almacenes = context.getters.almacenesReasignacion
            if (almacenes.length > 0) {
                almacenes.forEach(almacen => {
                    ubicacionesSol.push({
                        "$type": "DepotSite",
                        "id": almacen.nombre,
                        "routeLocation": {
                            "$type": "OffRoadRouteLocation",
                            "offRoadCoordinate": {
                                "y": almacen.latitud,
                                "x": almacen.longitud
                            }
                        }
                    })
                })
            }
            rutasReplanificar.forEach(ruta => {
                const { codigoPunto, dia, latitud, longitud, fkTarea, servicio, horaInicioPunto, horaFinPunto } = obtenerInformacionRuta(ruta)


                let ubicacionSol = {
                    "$type": "CustomerSite",
                    "id": codigoPunto,
                    "routeLocation": {
                        "$type": "OffRoadRouteLocation",
                        "offRoadCoordinate": {
                            "y": latitud,
                            "x": longitud
                        }
                    }
                }

                if (horaInicioPunto && horaFinPunto) {
                    ubicacionSol["openingIntervals"] = [{
                        "$type": "StartEndInterval",
                        "start": dia + "T" + horaInicioPunto + ":00-05:00",
                        "end": dia + "T" + horaFinPunto + ":00-05:00"
                    }]
                }

                ubicacionesSol.push(ubicacionSol)

                const ordenSol = {
                    "$type": "VisitOrder",
                    "id": codigoPunto,
                    "locationId": codigoPunto,
                    "serviceTime": parseInt(servicio) * 60,
                    "groupId": fkTarea
                }
                ordenesSol.push(ordenSol)

            })

            let vehiculoSol = {
                "ids": [idTarea],
                "orderGroupIds": [idTarea]
            }
            if (toleranciaVisita != "" && toleranciaVisita != null) {
                vehiculoSol["serviceTimePerStop"] = parseInt(toleranciaVisita) * 60
            }

            if (almacenComienzo && almacenes.length > 0) {
                vehiculoSol["startLocationId"] = almacenComienzo
            }
            if (almacenDestino && almacenes.length > 0) {
                vehiculoSol["endLocationId"] = almacenDestino
            }

            let horaInicioVehiculo = horaInicio
            let horaFinVehiculo = horaFin

            if (diaSemana == 6 && horaInicioSabado != null && horaFinSabado != null && horaInicioSabado != "" && horaFinSabado != "") {
                horaInicioVehiculo = horaInicioSabado
                horaFinVehiculo = horaFinSabado
            }
            if (diaSemana == 0 && horaInicioDomingo != null && horaFinDomingo != null && horaInicioDomingo != "" && horaFinDomingo != "") {
                horaInicioVehiculo = horaInicioDomingo
                horaFinVehiculo = horaFinDomingo
            }
            if (extenderHorario) {
                opcionesSol.restrictions.maximumTravelTimePerTour = 72000
                horaFinVehiculo = "23:59"
            }

            const conductorSol = {
                "id": idTarea,
                "vehicleId": idTarea,
                "operatingIntervals": [{
                    "$type": "StartEndInterval",
                    "start": fecha + "T" + horaInicioVehiculo + ":00-05:00",
                    "end": fecha + "T" + horaFinVehiculo + ":00-05:00"
                }]
            }
            
            
            vehiculosSol.push(vehiculoSol)
            conductoresSol.push(conductorSol)

            const peticion = {
                "locations": ubicacionesSol,
                "orders": ordenesSol,
                "fleet": {
                    "vehicles": vehiculosSol,
                    "drivers": conductoresSol
                },
                "planToursOptions": opcionesSol,
                "distanceMode": modoDistancia
            }
            if (ordenesSol.length > 0) {
                peticiones.push({ fecha, peticion })
            }

        })
        context.commit('setErroresReasignacion', [])
        context.dispatch('startPlanToursReasignacion', peticiones)
    },
    /** 
    * Inicia la peticion hacia los XServer
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async startPlanToursReasignacion(context, payload) {
        try {
            //limpiar estado peticiones y respuestas
            context.commit('setSolicitudPlanTourReasignacion', payload)
            context.commit('setRespuestaPlanTourReasignacion', [])
            //Iteramos todas la peticiones
            peticionesTotales = payload.length
            context.dispatch('eachPeticionesToursReasignacion', { index: 0, peticiones: payload })

        } catch (error) {
            console.error(error)
        }

    },
    /**
    * Itera las peticiones hacia los XServer
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async eachPeticionesToursReasignacion(context, payload) {
        const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
        let { index, peticiones } = payload

        if (peticionesProceso >= 15) {
            setTimeout(function () {
                context.dispatch('eachPeticionesToursReasignacion', { index: index, peticiones: peticiones })
            },
                2000
            )
            return
        }
        try {
            if (index < peticiones.length) {
                const { peticion, fecha } = peticiones[index]
                const { status, data } = await xServerApi.post('XTour/2.31/startPlanTours', peticion, { headers: {
                        'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
                        'Content-Type': 'application/json'
                    }
                })
                if (status !== 200) {
                    console.log('error en el status', status);
                    throw new Error(data.error || data)
                }
                //Revisar status
                const job = new xServerJob('tourReasignacion', fecha)
                job.statusJob(data)

                //Iteramos las peticiones restantes
                index++
                peticionesProceso++
                await context.dispatch('eachPeticionesToursReasignacion', { index: index, peticiones: peticiones })
            } else if (index == peticiones.length && index == 0) {
                context.commit('setDiasModificadosReasignacion', [])
                context.commit('setIsNecesitaGuardarInformacion', true)
                context.commit('setLoading', false)
            }
        } catch (error) {
            console.error(error)
        }
    },
    /**
    * Se guarda la informacion en el store
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async setPlanTourRequestReasignacion(context, payload) {
        peticionesProceso--
        context.commit('addRespuestaPlanTourReasignacion', payload)
        if (payload.response.hasOwnProperty('tourReports')) {
            const reporteCosto = obtenerReporteCostoVehiculo(payload)
            context.commit('addReporteCostoTarea', reporteCosto)
        }
        const solicitudPlanTour = context.getters.solicitudPlanTourReasignacion
        const respuestaPlanTour = context.getters.respuestaPlanTourReasignacion
        const erroresPlanTour = context.getters.getErroresReasignacion
        if (solicitudPlanTour.length === (respuestaPlanTour.length + erroresPlanTour.length)) {
            context.commit('setLoading', false)
            context.dispatch('destructurarInformacionPTVReasignacion', { solicitudPlanTour, respuestaPlanTour })
        }else {
            peticionesCompletadas++
            if (peticionesCompletadas == peticionesTotales) context.commit('setTerminoAplicarCambios', true)
        }
    },
    async setPlanTourRequestReasignacionError(context, payload) {
        context.commit('addErroresReasignacion', payload)
    },
    async destructurarInformacionPTVReasignacion(context, payload) {
        let listaViajesInfo = JSON.parse(JSON.stringify(context.state.listaViajesReasignacion))
        let listaRutasInfo = JSON.parse(JSON.stringify(context.state.listaRutasReasignacion))
        let listaNoPlanificadosInfo = JSON.parse(JSON.stringify(context.state.listaNoPlanificadosReasignacion))
        const diasModificadosReasignacion = JSON.parse(JSON.stringify(context.state.diasModificadosReasignacion))
        const almacenesCompania = context.getters.almacenesReasignacion
        const { respuestaPlanTour } = payload
        const codigosPuntoNoPlanificables = []

        diasModificadosReasignacion.forEach(({ dia, vehiculo }) => {

            const respuestaPeticion = respuestaPlanTour.find(({ id, response }) => {
                const isFecha = id === dia

                const indexViaje = listaViajesInfo.findIndex(({ fkTarea, fechaComienzo, fechaFin }) => {
                    const fechaC = new Date(fechaComienzo * 1000)
                    const fechaF = new Date(fechaFin * 1000)
                    return fkTarea === vehiculo && format(fechaC, "yyyy-MM-dd") === dia && format(fechaF, "yyyy-MM-dd") === dia
                })

                const { distance, drivingTime, serviceTime } = response.costReport

                if(indexViaje != -1){
                    listaViajesInfo[indexViaje].distancia = distance
                    listaViajesInfo[indexViaje].servicio = serviceTime
                    listaViajesInfo[indexViaje].conduccion = drivingTime
                }else{
                    const fechaC = new Date(dia + " 00:00:00")
                    const fechaF = new Date(dia + " 23:59:59")
                    listaViajesInfo.push({
                        id: 0,
                        fkTarea: vehiculo,
                        fechaComienzo: fechaC.getTime() / 1000,
                        fechaFin: fechaF.getTime() / 1000,
                        distancia: distance,
                        servicio: serviceTime,
                        conduccion: drivingTime
                    })
                }

                if (!response.hasOwnProperty('tourReports')) {
                    if (response.hasOwnProperty('orderIdsNotPlanned')) {
                        response.orderIdsNotPlanned.forEach(orden => {
                            const indexRuta = listaRutasInfo.findIndex(({ codigo_punto, dia: diaRuta }) => codigo_punto == orden && diaRuta === dia)
                            if (indexRuta === -1) {
                                return
                            }
                            const ruta = listaRutasInfo[indexRuta]
                            const noPlanificado = {
                                id: 0,
                                fk_punto: ruta.fk_punto,
                                codigo_punto: ruta.codigo_punto,
                                descripcion_punto: ruta.descripcion_punto,
                                detalles_punto: ruta.detalles_punto,
                                fk_tarea: ruta.fk_tarea,
                                nombre_tarea: ruta.nombre_tarea,
                                latitud: ruta.latitud,
                                longitud: ruta.longitud,
                                tipo: ruta.tipo
                            }
                            listaNoPlanificadosInfo.push(noPlanificado)
                            removerElementoArray(listaRutasInfo, ruta)
                            codigosPuntoNoPlanificables.push(ruta.codigo_punto)
                        })
                    }
                    return false
                }
                const isVehiculo = response.tourReports[0].vehicleId == vehiculo

                return isFecha && isVehiculo
            })
            if (!respuestaPeticion) {
                return
            }

            if (respuestaPeticion.response.hasOwnProperty('orderIdsNotPlanned')) {
                respuestaPeticion.response.orderIdsNotPlanned.forEach(orden => {
                    const indexRuta = listaRutasInfo.findIndex(({ codigo_punto, dia: diaRuta }) => codigo_punto == orden && diaRuta === dia)
                    if (indexRuta === -1) {
                        return
                    }
                    const ruta = listaRutasInfo[indexRuta]

                    const noPlanificado = {
                        id: 0,
                        fk_punto: ruta.fk_punto,
                        codigo_punto: ruta.codigo_punto,
                        descripcion_punto: ruta.descripcion_punto,
                        detalles_punto: ruta.detalles_punto,
                        fk_tarea: ruta.fk_tarea,
                        nombre_tarea: ruta.nombre_tarea,
                        latitud: ruta.latitud,
                        longitud: ruta.longitud,
                        tipo: ruta.tipo
                    }
                    listaNoPlanificadosInfo.push(noPlanificado)
                    removerElementoArray(listaRutasInfo, ruta)
                    codigosPuntoNoPlanificables.push(ruta.codigo_punto)
                })
            }
            let consecutivo = 0
            const tourReports = respuestaPeticion.response.tourReports[0]
            const vehicleId = tourReports.vehicleId
            tourReports.tourEvents.forEach(({duration,eventTypes, startTime, orderId, locationId}, index) => {
                if(eventTypes[0] !== 'SERVICE' && eventTypes[0] !== 'TOUR_START' && eventTypes[0] !== 'TOUR_END') return
                const fecha = new Date(startTime.substring(0, 19))
                const horaInicio = format(fecha, "HH:mm")
                fecha.setSeconds(fecha.getSeconds() + duration)
                const horaFin = format(fecha, "HH:mm")

                let distancia = 0
                let tiempoConduccion = 0
                if(tourReports.legReports && consecutivo > 0){
                    const legReport = tourReports.legReports.find(obj => obj.endTourEventIndex === index - 1)
                    if(legReport){
                        distancia = legReport.distance
                        tiempoConduccion = legReport.drivingTime
                    }
                }
                
                if(eventTypes[0] === 'SERVICE'){
                    const indexRuta = listaRutasInfo.findIndex(({ codigo_punto, dia: diaRuta, fk_tarea }) => codigo_punto == orderId && diaRuta === dia && fk_tarea === parseInt(vehicleId))
                    listaRutasInfo[indexRuta].servicio = duration
                    listaRutasInfo[indexRuta].hora_inicio = horaInicio
                    listaRutasInfo[indexRuta].hora_fin = horaFin
                    listaRutasInfo[indexRuta].consecutivo = consecutivo
                    listaRutasInfo[indexRuta]['tiempo_conduccion'] = tiempoConduccion
                    listaRutasInfo[indexRuta]['distancia'] = distancia
                }else{
                    const almacenEncontrado = almacenesCompania.find(({ nombre }) => nombre === locationId)
                    if(almacenEncontrado){
                        const indexAlmacenExistenteEnDia = listaRutasInfo.findIndex(ruta => ruta.dia === fecha && ruta.fk_tarea === vehiculo && ruta?.fk_almacen === almacenEncontrado.id)
                        if (indexAlmacenExistenteEnDia !== -1) listaRutasInfo.splice(indexAlmacenExistenteEnDia, 1)
                        listaRutasInfo.push({
                            tipo: 'Almacen',
                            dia: dia,
                            consecutivo,
                            servicio: duration,
                            hora_inicio: horaInicio,
                            hora_fin: horaInicio,
                            fk_tarea: vehiculo,
                            fk_almacen: almacenEncontrado.id,
                            tiempo_conduccion: tiempoConduccion,
                            distancia: distancia
                        })
                    }
                }

                consecutivo++
            })
        })
        context.commit('setListaCodigosPuntoNoPlanificables', codigosPuntoNoPlanificables)
        context.commit('setListaRutasReasignacion', listaRutasInfo)
        context.commit('setListaViajesReasignacion', listaViajesInfo)
        context.commit('setListaNoPlanificadosReasignacion', listaNoPlanificadosInfo)
        context.commit('setSolicitudPlanTourReasignacion', [])
        context.commit('setRespuestaPlanTourReasignacion', [])
        context.commit('setDiasModificadosReasignacion', [])
        context.commit('setIsNecesitaGuardarInformacion', true)
        context.commit('setLoading', false)
        peticionesCompletadas++
        if (peticionesCompletadas == peticionesTotales) context.commit('setTerminoAplicarCambios', true)
    },
    async agregarMarcadorReasignacion(context, payload) {
        context.commit('addPuntosMarcadosReasignacion', payload)
    },
    async limpiarMarcadorReasignacion(context) {
        context.commit('setPuntosMarcadosReasignacion', [])
    },

    async reasignarListaDesasignar(context, lista) {
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))
        let rutasDesasignar = []
        let rutasDesasignarTotales = ""
        lista.forEach(id => {
            rutasDesasignar = rutasDesasignar.concat(rutas.filter(ruta => ruta.codigo_punto == id))
        })
        rutasDesasignar.forEach(rutaDesasignar => {
            let desasignar = { ...rutaDesasignar }
            rutasDesasignarTotales += rutaDesasignar.codigo_punto + "\n"
            desasignar.id = 0
            delete desasignar.dia
            delete desasignar.hora_inicio
            delete desasignar.hora_fin
            desasignar.isNuevo = true
            noPlanificados.push(desasignar)
            context.dispatch('agregarDiasModificadosReasignacion', { dia: rutaDesasignar.dia, vehiculo: rutaDesasignar.fk_tarea })

            removerElementoArray(rutas, rutaDesasignar)
        })
        context.commit('setListaRutasReasignacion', rutas)
        context.commit('setListaNoPlanificadosReasignacion', noPlanificados)
        const mensaje = {
            contadorDesasignar: rutasDesasignar.length,
            rutasDesasignar: rutasDesasignarTotales
        }
        return mensaje
    },

    async reasignarListaEliminar(context, lista) {
        const areas = JSON.parse(JSON.stringify(context.getters.getListaAreasReasignacion))
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))

        let areasEliminar = []
        let areasEliminarTodas = ""
        let rutasEliminar = []
        let rutasEliminarTodas = ""
        let noPlanificadosEliminar = []
        let noPlanificadosEliminarTodas = ""
        lista.forEach(id => {
            areasEliminar = areasEliminar.concat(areas.filter(ruta => ruta.codigo_punto == id))
            rutasEliminar = rutasEliminar.concat(rutas.filter(ruta => ruta.codigo_punto == id))
            noPlanificadosEliminar = noPlanificadosEliminar.concat(noPlanificados.filter(ruta => ruta.codigo_punto == id))
        })
        areasEliminar.forEach(eliminar => {
            areasEliminarTodas += eliminar.codigo_punto + "\n"
            removerElementoArray(areas, eliminar)
        })
        rutasEliminar.forEach(eliminar => {
            rutasEliminarTodas += eliminar.codigo_punto + "\n"
            context.dispatch('agregarDiasModificadosReasignacion', { dia: eliminar.dia, vehiculo: eliminar.fk_tarea })
            removerElementoArray(rutas, eliminar)
        })
        noPlanificadosEliminar.forEach(eliminar => {
            noPlanificadosEliminarTodas += eliminar.codigo_punto + "\n"
            removerElementoArray(noPlanificados, eliminar)
        })

        context.commit('setListaAreasReasignacion', areas)
        context.commit('setListaRutasReasignacion', rutas)
        context.commit('setListaNoPlanificadosReasignacion', noPlanificados)
        const mensaje = {
            contadorAreasEliminadas: areasEliminar.length,
            contadorRutasEliminadas: rutasEliminar.length,
            areasEliminadas: areasEliminarTodas,
            rutasEliminadas: rutasEliminarTodas
        }
        return mensaje
    },

    async reasignarListaAgregar(context, lista) {
        const listaVehiculos = JSON.parse(JSON.stringify(context.getters.getListaVehiculosReasignacion))
        const areas = JSON.parse(JSON.stringify(context.getters.getListaAreasReasignacion))
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))
        let contadorPlanificados = 0
        let contadorNoPlanificados = 0
        let planificadosTotales = ""
        let noPlanificadosTotales = ""
        //Agregar areas
        lista.forEach(elementoAgregar => {
            const { id, descripcion, vehiculo, latitud, longitud, dia, detalles_punto, cantidad } = elementoAgregar
            const isExiste = rutas.some(ruta => ruta.dia == dia && ruta.codigo_punto == id && ruta.nombre_tarea == vehiculo )
            if (isExiste) {
                return
            }
            const tarea = listaVehiculos.find(({ nombre }) => vehiculo == nombre)
            if (!tarea) {
                return
            }
            const { idtarea } = tarea

            const tiempoServicioObj = detalles_punto.find(detalle => detalle.nombre_detalle === 'Tiempo de servicio')
            const servicio = parseInt(tiempoServicioObj.detalle_punto) * 60
            areas.push({
                id: 0,
                codigo_punto: id,
                descripcion_punto: descripcion,
                descripcion_tarea: vehiculo,
                fk_punto: 0,
                fk_tarea: idtarea,
                latitud,
                longitud,
                nombre_tarea: vehiculo,
                tipo: 'Punto nuevo',
                detalles_punto: detalles_punto
            })

            if (dia) {
                context.dispatch('agregarDiasModificadosReasignacion', { dia: dia, vehiculo: idtarea })
                contadorPlanificados++
                planificadosTotales += `${id} \n`
                rutas.push({
                    id: 0,
                    codigo_punto: id,
                    consecutivo: 0,
                    descripcion_punto: descripcion,
                    descripcion_tarea: vehiculo,
                    dia,
                    fk_punto: 0,
                    fk_tarea: idtarea,
                    hora_fin: '00:00',
                    hora_inicio: '00:00',
                    latitud,
                    longitud,
                    nombre_tarea: vehiculo,
                    servicio,
                    tipo: 'Punto nuevo',
                    detalles_punto: detalles_punto
                })
            } else {
                contadorNoPlanificados++
                noPlanificadosTotales += `${id} \n`
                let contador = 0
                while (contador < cantidad) {
                    noPlanificados.push({
                        id: 0,
                        codigo_punto: id,
                        descripcion_punto: descripcion,
                        fk_punto: 0,
                        detalles_punto: detalles_punto,
                        fk_tarea: idtarea,
                        latitud,
                        longitud,
                        nombre_tarea: vehiculo,
                        servicio,
                        tipo: 'Punto nuevo',
                        detalles_punto: detalles_punto
                    })
                    contador++
                }
            }
        })
        context.commit('setListaAreasReasignacion', areas)
        context.commit('setListaRutasReasignacion', rutas)
        context.commit('setListaNoPlanificadosReasignacion', noPlanificados)
        const mensaje = {
            contadorPlanificados,
            contadorNoPlanificados,
            planificadosTotales,
            noPlanificadosTotales
        }
        return mensaje
    },

    //Importar Reasignacion
    async importaReasignacion(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('planningId', payload.idPlaneacion)
            const { status, data } = await gitCoreApi.post('/gitlogistics/reassignment/import_xls_reassignment', solicitud,
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {

                const { desasignarInformacion, eliminarInformacion, agregarInformacion } = data

                const respuestaDesasignar = await context.dispatch('reasignarListaDesasignar', desasignarInformacion)

                const respuestaEliminar = await context.dispatch('reasignarListaEliminar', eliminarInformacion)

                const respuestaAgregar = await context.dispatch('reasignarListaAgregar', agregarInformacion)
                mensaje = {
                    contadorAreasEliminadas: respuestaEliminar.contadorAreasEliminadas,
                    contadorRutasEliminadas: respuestaEliminar.contadorRutasEliminadas,
                    areasEliminadas: respuestaEliminar.areasEliminadas,
                    rutasEliminadas: respuestaEliminar.rutasEliminadas,
                    rutaDesasignar: respuestaDesasignar.rutasDesasignar,
                    contadorDesasignar: respuestaDesasignar.contadorDesasignar,
                    contadorPlanificados: respuestaAgregar.contadorPlanificados,
                    contadorNoPlanificados: respuestaAgregar.contadorNoPlanificados,
                    planificadosTotales: respuestaAgregar.planificadosTotales,
                    noPlanificadosTotales: respuestaAgregar.noPlanificadosTotales
                }
            }
            return mensaje

        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

    async guardaReasignacion(context, payload) {
        context.commit('setTerminoAplicarCambios', false)
        try {
            const { token, idProceso, listaAreasReasignacion, listaNoPlanificadosReasignacion, listaRutasReasignacion} = payload

            let listAreasReassignment = listaAreasReasignacion.map(item => {
                const { codigo_punto, fk_punto, fk_tarea, descripcion_punto, latitud, longitud, tipo, distancia, tiempo_conduccion} = item
                return {
                    codigo_punto,
                    fk_punto,
                    fk_tarea,
                    descripcion_punto,
                    latitud,
                    longitud,
                    tipo,
                    tiempoConduccion: tiempo_conduccion,
                    distancia
                }
            })
            let listUnPlaningReassignment = listaNoPlanificadosReasignacion.map(item => {
                const { codigo_punto, fk_punto, fk_tarea, cantidad, tipo, detalles_punto } = item
                let servicioDetalle = ''
                detalles_punto.forEach(detalle => {
                    if (detalle.nombre_detalle == 'Tiempo de servicio') {
                        servicioDetalle = detalle.detalle_punto
                    }
                })
                return {
                    codigo_punto,
                    fk_punto,
                    fk_tarea,
                    cantidad,
                    tipo,
                    detalles_punto,
                    servicio_detalle: servicioDetalle
                }
            })
            let listRoutesReassignment = listaRutasReasignacion.map(item => {
                const {
                    codigo_punto,
                    fk_punto,
                    fk_tarea,
                    tipo,
                    dia,
                    hora_inicio,
                    hora_fin,
                    servicio,
                    minutos,
                    consecutivo,
                    distancia,
                    tiempo_conduccion,
                    fk_almacen,
                    detalles_punto
                } = item

                const obj = {
                    codigo_punto,
                    fk_punto,
                    fk_tarea,
                    tipo,
                    dia,
                    hora_inicio,
                    hora_fin,
                    servicio: !servicio && tipo == 'Break' ? minutos * 60 : servicio,
                    consecutivo,
                    distancia,
                    detalles_punto,
                    tiempoConduccion: tiempo_conduccion
                }
                if (fk_almacen) obj.fk_almacen = fk_almacen
                return obj
            })

            context.commit('setLoading', true)
            const { status } = await gitCoreApi.post('/gitlogistics/reassignment/save',
                {
                    fkProcess: idProceso,
                    listAreasReassignment,
                    listUnPlaningReassignment,
                    listRoutesReassignment,
                    vehiclesCostReports: payload.reportesCostoTareas, 
                    reportTravel: payload.reporteViaje
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json'
                    }
                })

            if (status !== 200) {
                const error = new Error('Error al guardar')
                throw error
            }
            context.dispatch('obtenerInformacionReasignacion', { fkProceso: idProceso, token })
            context.dispatch('actividadGuardar',payload)
            context.commit('setLoading', false)
            return status
        } catch (error) {
            context.commit('setLoading', false)
            throw error
        }
    },
    async limpiaListaCodigosPuntoNoPlanificables(context, _payload) {
        context.commit('setListaCodigosPuntoNoPlanificables', [])
    },
    modificarHorariosRuta(context, payload) {
        const { codigo, diaPunto, horaInicio, horaFin, tiempoServicio } = payload
        const isHoras = horaInicio !== '' && horaFin !== ''
        if (!codigo || !diaPunto || !(isHoras || tiempoServicio !== '')) return
        const listaRutas = JSON.parse(JSON.stringify(context.state.listaRutasReasignacion))
        const indexRuta = listaRutas.findIndex(({ codigo_punto, dia }) => codigo_punto === codigo && dia === diaPunto)
        if (indexRuta === -1) return

        const punto = listaRutas[indexRuta]
        if(horaInicio !== '' && horaFin !== ''){
            let detalleIndex = punto.detalles_punto.findIndex(detalle => detalle.nombre_detalle === 'Hora inicio')
            if(detalleIndex !== -1){
                punto.detalles_punto[detalleIndex].detalle_punto = horaInicio
            }else{
                punto.detalles_punto.push({nombre_detalle: 'Hora inicio', detalle_punto: horaInicio})
            }

            detalleIndex = punto.detalles_punto.findIndex(detalle => detalle.nombre_detalle === 'Hora fin')
            if(detalleIndex !== -1){
                punto.detalles_punto[detalleIndex].detalle_punto = horaFin
            }else{
                punto.detalles_punto.push({nombre_detalle: 'Hora fin', detalle_punto: horaFin})
            }
        }
        if(!tiempoServicio !== ''){
            const detalleIndex = punto.detalles_punto.findIndex(detalle => detalle.nombre_detalle === 'Tiempo de servicio')
            if(detalleIndex !== -1){
                punto.detalles_punto[detalleIndex].detalle_punto = tiempoServicio
            }else{
                punto.detalles_punto.push({nombre_detalle: 'Tiempo de servicio', detalle_punto: tiempoServicio})
            }
        }
        console.log(listaRutas)
        context.commit('setListaRutasReasignacion', listaRutas)
    },
    guardaFechaSinDia(context, payload) {
        context.commit('setFechaSinDia', payload)
    },
    desplanificarTodos(context, punto) {
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))

        const rutasDesasignar = rutas.filter(ruta => ruta.codigo_punto == punto.punto)
        const contadorDesasignarMasiva = rutasDesasignar.length
        rutasDesasignar.forEach(rutaDesasignar => {
            let desasignar = { ...rutaDesasignar }
            // rutasDesasignarTotales+= rutaDesasignar.codigo_punto + "\n"
            desasignar.id = 0
            delete desasignar.dia
            delete desasignar.hora_inicio
            delete desasignar.hora_fin
            desasignar.servicio_detalle = desasignar.servicio
            noPlanificados.push(desasignar)
            context.dispatch('agregarDiasModificadosReasignacion', { dia: rutaDesasignar.dia, vehiculo: rutaDesasignar.fk_tarea })

            removerElementoArray(rutas, rutaDesasignar)
        })
        context.commit('setListaRutasReasignacion', rutas)
        context.commit('setListaNoPlanificadosReasignacion', noPlanificados)

        const mensaje = {
            contadorDesasignarMasiva: contadorDesasignarMasiva
        }
        return mensaje
    },
    desplanificarPunto(context, punto) {
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))
        let rutaDesasignar = rutas.find(ruta => ruta.codigo_punto == punto.punto && ruta.dia == punto.dia)
        if (rutaDesasignar) {
            context.dispatch('agregarDiasModificadosReasignacion', { dia: rutaDesasignar.dia, vehiculo: rutaDesasignar.fk_tarea })
            removerElementoArray(rutas, rutaDesasignar)
            rutaDesasignar.id = 0
            delete rutaDesasignar.dia
            delete rutaDesasignar.hora_inicio
            delete rutaDesasignar.hora_fin
            delete rutaDesasignar.consecutivo
            delete rutaDesasignar.servicio
            noPlanificados.push(rutaDesasignar)
        }
        context.commit('setListaRutasReasignacion', rutas)
        context.commit('setListaNoPlanificadosReasignacion', noPlanificados)

    },
    async editarPuntoGPSReasignacion(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/gitlogistics/reassignment/update_gps_reassignment',
                {
                    "idPoint": payload.id,
                    "latitude": payload.latitud,
                    "longitude": payload.longitud
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la orden')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                const areas = JSON.parse(JSON.stringify(context.getters.getListaAreasReasignacion))
                const areaIndex = areas.findIndex(item => item.fk_punto == payload.id)
                areas[areaIndex]["latitud"] = payload.latitud
                areas[areaIndex]["longitud"] = payload.longitud
                context.commit('setListaAreasReasignacion', areas)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
                context.commit('setLoading', false)
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    async guardarConfiguracionSemaforo(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/reassignment/semaphore', 
            {
                semaphore: payload.semaforo,
                fkCompany: payload.fkCompany,
                fkUser: payload.fkUser
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        
            context.dispatch('mortarConfiguracionSemaforo', payload)

        } catch (error) {
            throw new Error(error)
        }
    },
    async mortarConfiguracionSemaforo(context, payload){
        try {
            const { status, data  } = await gitCoreApi.get('/gitlogistics/reassignment/semaphore', {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
 
            context.commit('setSemaforoConfiguracion', data)
        
        } catch (error) {
            throw new Error(error)
        }
    },
    extenderHorario(context, payload) {
        localStorage.setItem('extenderHorario', JSON.stringify(payload))
    },
    vehiculoSeleccionado(context, payload) {
        context.commit('setIdVehiculoSeleccionadoReasignacion', payload)
    },
    async getDetallesPunto(context, payload) {
      try {
        const { modulo, token, } = payload
        const { status, data } = await gitCoreApi.get(`/gitlogistics/reassignment/points_details?access=${modulo}`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            const error = new Error('Error al guardar')
            throw error
        }

        return data
      } catch (error) {
        throw error
      }  
    },
    async cancelarReasignacion(context, payload){
        context.commit('setLoading', true)
        context.commit('setDiasModificadosReasignacion', [])
        await context.dispatch('obtenerInformacionReasignacion',payload)
        context.dispatch('actividadCancelar',payload)
        context.commit('setLoading', false)
    },
    async loader(context, payload) {
        context.commit('setLoadingTwo', payload)
    },
    async agregarBreak(context, payload) {
        let dia = 0
        let fk_tarea = ""
        const datos = payload.puntosDia.map(element => {
            dia = element.dia
            fk_tarea = element.fk_tarea
            return element
        })
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const rutasFecha = rutas.filter(ruta => ruta.dia == dia && ruta.fk_tarea == fk_tarea)
        let agregarBreak = []
        let actualizarHorario = []
        let consecutivo = 0
        datos.forEach(element => {
            if (element.idGlobal) {
                consecutivo++
                let horario = rutasFecha.some(item => element.idGlobal == item.id)
                if(horario) {
                    const { idGlobal, hora_inicio, hora_fin} = element
                    actualizarHorario.push({
                        id: idGlobal,
                        hora_inicio,
                        hora_fin
                    })
                }
            } else {
                const { id, codigo_punto, dia, minutos, nombre_tarea, hora_inicio, hora_fin, tipo = 'Break', fk_tarea } = element
                if(hora_inicio){
                    agregarBreak.push({ 
                        idBreak: id, 
                        codigo_punto, 
                        tipo,
                        fk_tarea,
                        dia, 
                        minutos, 
                        nombre_tarea,
                        hora_inicio,
                        hora_fin,
                        consecutivo: `${consecutivo}B`
                    })
                }
            }
        })
        actualizarHorario.forEach(item => {
            let index = rutas.findIndex(element => element.id == item.id )
            rutas[index].hora_inicio = item.hora_inicio
            rutas[index].hora_fin = item.hora_fin
        })
        agregarBreak.forEach(item => {
            let index = rutas.findIndex( element => element.idBreak && element.dia == item.dia && element.fk_tarea == item.fk_tarea )
            if (index != -1) {
                rutas.splice(index, 1)
            } 
        })
        agregarBreak.forEach(item => {
            rutas.push(item)
        })
        context.commit('setListaRutasReasignacion', rutas)
    },
    async listadoEliminar(context, payload) {
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const insertaBreak = JSON.parse(JSON.stringify(context.getters.getInsertaBreak))
        const idEliminar = JSON.parse(JSON.stringify(context.getters.getIdEliminarBreak))
        const eliminarBreak = JSON.parse(JSON.stringify(context.getters.getEliminarBreak))
        const { listadoEliminar } = payload
        listadoEliminar.forEach(item => {
            if (item['idGlobal'] == undefined) {
                let index = rutas.findIndex(ruta => ruta.codigo_punto == 'Break' && ruta.dia == item.dia && ruta.idBreak == item.id)
                rutas.splice(index, 1)
                let index2 = insertaBreak.findIndex(inserta => inserta.codigo_punto == 'Break' && inserta.dia == item.dia && inserta.idBreak == item.id)
                insertaBreak.splice(index2, 1)
            }else{
                let index2 = rutas.findIndex(ruta => item.idGlobal == ruta.id)
                idEliminar.push(rutas[index2])
                rutas.splice(index2, 1)
            }
         })
        eliminarBreak.push(listadoEliminar)
        context.commit('setListaRutasReasignacion', rutas)
    },
    async listadoActualizar(context, payload) {
        const rutas = JSON.parse(JSON.stringify(context.getters.getListaRutasReasignacion))
        const actualizarBreak = JSON.parse(JSON.stringify(context.getters.getActualizarBreak))
        const { listaActualizar } = payload
        listaActualizar.forEach(item => {
            let index = rutas.findIndex(ruta => item.id == ruta.id)
            rutas[index].hora_inicio = item.hora_inicio
            rutas[index].hora_fin = item.hora_fin
        })
        actualizarBreak.push(listaActualizar)
        context.commit('setListaRutasReasignacion', rutas)
    },

    async descargarPlantilla(context, payload){
        try {
            const { status, data } = await gitCoreApi.post('/gitlogistics/reassignment/getPlantilla', {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            descargaBaseSesentaycuatro(data, 'Agregar_Eliminar', 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }
    },
    
    async agregarFrecuencia(context, payload){
        const datosPunto = payload.datosPunto
        const noPlanificados = JSON.parse(JSON.stringify(context.getters.getListaNoPlanificadosReasignacion))
        let newArray = []
            noPlanificados.forEach(function(objeto) {
            let id = objeto.codigo_punto;
                if (id != datosPunto.codigo_punto) {
                    newArray.push(objeto);
                }
            })
            let noPlanificadosFiltrados = newArray
        for (let contador = 0; contador < payload.frecuencia ; contador++) {
            noPlanificadosFiltrados.push(payload.datosPunto)
        }
        context.commit('setListaNoPlanificadosReasignacion', noPlanificadosFiltrados)
    },
    async subeArchivoPlaneacionManual(context, payload) {
        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.archivo)
            const { status, data } = await gitCoreApi.post('/manual-planning/upload-file-manual-planning',solicitud,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al subir archivo')
            }

            return data
        } catch (error) {
            context.commit('setLoading', false)
            throw new Error(error)
        }
    },
    async descargaLayoutPlaneacionManual(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('/manual-planning/get-layout',
            {},
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }
    
            descargaBaseSesentaycuatro(data, 'Layout planeación manual', 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }
    },
    async obtenBarraProgreso(context, payload) {
        const barraProgresoId = localStorage.getItem('barraProgresoId')
        if (!barraProgresoId) return 0
        const { status, data } = await gitCoreApi.post('/manual-planning/get-progress-bar',{ progress_bar_id: barraProgresoId },
        {
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        return data.progress
    },
    //*Actividades
    async actividadBreak(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_break', 
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadAgregarEliminar(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_agregar_eliminar', 
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadConfiguracionSemaforo(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_configuracion_semaforo', 
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadDesplanificar(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_desplanificar', 
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadPlanificar(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_planificar', 
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadPlanificarMasiva(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_planificar_masiva',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadHoraInicio(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_hora_inicio',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadHoraFin(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_hora_fin',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadTiempoServicio(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_tiempo_servicio',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadAplicarCambios(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_aplicar_cambios_reasignacion',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadGuardar(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_guardar_reasignacion',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadCancelar(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_cancelar_cambios_reasignacion',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadDesplanificarTodos(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_desplanificar_todos',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadCambiarArea(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_cambiar_area',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadCambiarGPS(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_cambiar_gps',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
    async actividadCambiarFrecuencia(context, payload){
        try {
            const { status } = await gitCoreApi.post('/gitlogistics/actividad_cambiar_frecuencia',
            {},
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

        } catch (error) {
            throw new Error(error)
        }
    },
}

const obtenerInformacionVehiculo = (listaVehiculos, vehiculo) => {
    const { idtarea: idTarea, details } = listaVehiculos.find(({ idtarea }) => idtarea === vehiculo)
    const horaInicio = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora inicio')
    const horaFin = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora fin')
    const horaInicioSabado = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora inicio sabado')
    const horaFinSabado = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora fin sabado')
    const horaInicioDomingo = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora inicio domingo')
    const horaFinDomingo = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Hora fin domingo')
    const toleranciaVisita = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Tolerancia entre visita')
    const almacenComienzo = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Almacen comienzo')
    const almacenDestino = obtenerInformacionDetalle(details, 'nombre_detalle', 'detalle', 'Almacen destino')
    return {
        idTarea: idTarea,
        horaInicio,
        horaFin,
        horaInicioSabado,
        horaFinSabado,
        horaInicioDomingo,
        horaFinDomingo,
        toleranciaVisita,
        almacenComienzo,
        almacenDestino
    }
}
const obtenerInformacionRuta = (ruta) => {
    const { dia, latitud, longitud, codigo_punto: codigoPunto, fk_tarea: fkTarea, detalles_punto } = ruta
    const horaInicioPunto = obtenerInformacionDetalle(detalles_punto, 'nombre_detalle', 'detalle_punto', 'Hora inicio')
    const horaFinPunto = obtenerInformacionDetalle(detalles_punto, 'nombre_detalle', 'detalle_punto', 'Hora fin')
    const servicio = obtenerInformacionDetalle(detalles_punto, 'nombre_detalle', 'detalle_punto', 'Tiempo de servicio')

    return { 
        dia,
        latitud,
        longitud,
        codigoPunto,
        fkTarea,
        servicio: servicio || '',
        horaInicioPunto: horaInicioPunto || '',
        horaFinPunto: horaFinPunto || ''
    }
}

const obtenerInformacionDetalle = (detalles, propiedadBuscar, propiedadValor, buscar) => {
    const detalleObj = detalles.find(detalle => detalle[propiedadBuscar] === buscar)
    if(!detalleObj) return null
    return detalleObj[propiedadValor]
}