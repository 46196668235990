import gitCoreApi from '@/api/gitCoreApi'

import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

/* eslint-disable */
export default {
    async listarRastreoUsuarios(context, payload) {
        context.commit('setLoadingListaUsuariosRol', true)
        try{
            const { status, data } = await gitCoreApi.post(`configuration/movil_gps/get_track_usuarios`,{
                'idRol':payload.idRol,
                'date': payload.date,
                'usuario':payload.usuario,
                'usuario2': payload.usuario2
            },
            {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setListaRatreoUsuarios', data) 
            
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoadingListaUsuariosRol', false)
        }
    },

    async listarUsuariosByRol(context, payload) {
        try{
            const { status, data } = await gitCoreApi.post(`configuration/user/get_list_users_by_rol`, {
                'idRol':payload.idRol
            },{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }

            context.commit('setListaUsuariosByRol', data)

        } catch (error) {
            console.error(error)
            throw error
        } 
    },

    async createRastreoUsuarioXls(context, payload) {
        context.commit('setLoadingXlsListaUsuariosRol', true)
        try{
            const { status, data } = await gitCoreApi.post(`configuration/movil_gps/create_track_usuarios_xls`,{
                'idRol':payload.idRol,
                'date': payload.date,
                'usuario':payload.usuario,
                'usuario2': payload.usuario2
            },
            {
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
            
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el xls')
                throw error
            }

            const { data_base64, file_name, type } = data
            descargaBaseSesentaycuatro(data_base64, file_name, type)
            
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoadingXlsListaUsuariosRol', false)
        }
    },

    async obtenerPuntoPorId(context, payload) {
        const { idPunto } = payload
        try {
            const { status, data } = await gitCoreApi.get(`/points/${idPunto}`,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error al obtener el listado')
                throw error
            }
    
            return data
        } catch (error) {
            throw new Error(error)
        }
    }
}