import gitCoreApi from '@/api/gitCoreApi'

/* eslint-disable */
export default {
    async setRouteInfo(context, payload) {
        context.commit('setRouteInfo', payload)
    },
    async getVehiclesUnplanned(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.get(
                `xcaret/adjustment/unplanned?date=${payload.date}${payload.region ? `?cluster=${payload.region}` : ''}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setUnplanned', data)
        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    async saveReservationPlanning(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.put(`xcaret/adjustment/routes`, payload.body, {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }
        } catch (error) {
            throw new Error(error)
        } finally {
            context.commit('setLoading', false)
        }
    },
    setSelectedReservations(context, reservaId) {
        const exsitingId = context.getters.getSelectedReservations.find(id => id === reservaId)
        if (!exsitingId) context.commit('setSingleSelectedReservations', reservaId)
    },
    deleteSelectedReservation(context, reservaId) {
        const reservationsSelected = context.getters.getSelectedReservations.filter(id => id !== reservaId)
        context.commit('setSelectedReservations', reservationsSelected)
    }
}