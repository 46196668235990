import gitCoreApi from '@/api/gitCoreApi'

/* eslint-disable */
export default {
    async buscarTareaProgramada(context, payload) {

        const { status, data } = await gitCoreApi.get(`/process/task`, {
            params: {
                id: payload.id
            },
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-type': 'application/json'
            }
        })

        // Se maneja el caso de error en la petición
        if (status !== 200) {
            const error = new Error(data.message || 'Error de comunicacion')
            console.error(error)
            context.commit('setLoading', false)
        }

        if(!data){
            return
        }

        context.commit('setProgresoTareaProgramada', data)

        if(data.porcent === 100){
            context.commit('setLoading', false)
            return
        }
        
        setTimeout(() => {
            context.dispatch('buscarTareaProgramada', payload)
        }, 3000)
    }
}