import gitCoreApi from '@/api/gitCoreApi'
export default {
    /**
     * Devuelve las lista de permisos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarPermisosAplicacion(context, payload) {

        try{

            context.commit('setLoading', true)
            //context.dispatch('listarPermisosAplicacion', payload)
            const { status, data } = await gitCoreApi.post(`configuration/permissions_aplication/get_list_permissions_aplication`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaPermisosAplicaciones', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },
    /**
     * Crear una nuevo Permiso
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
        async nuevoPermisoAplicacion(context, payload) {
            try {
                context.commit('setLoading', true)
    
                const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/insert_permissions_aplication',
                    {
                        "nombre": payload.nombre,
                        "estatus": payload.estatus,
                    }, {
    
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al guardar el nuevo Usuario')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null
    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarPermisosAplicacion', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },
        /**
         * Edita un Permiso
         * @param {*} context - Métodos y propiedades del store
         * @param {*} payload - Datos para usarse dentro de la función
         */
        async editarPermisoAplicacion(context, payload) {
            try {
                context.commit('setLoading', true)
    
                const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/update_permissions_aplication',
                    {
                        "id": payload.id,
                        "fkestatus": payload.fkestatus,
                        "nombre_user": payload.nombre_user,
                        "rol_id": payload.rol_id,
                        "password": payload.password,
                        "apellidos": payload.apellidos,
                        "nombre":payload.nombre,

                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al editar Usuario')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null


    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarPermisosAplicacion', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },
    
    
        /**
         * Cambia el estatus de un Permiso
         * @param {*} context - Métodos y propiedades del store
         * @param {*} payload - Datos para usarse dentro de la función
         */
    
    
        async cambiarEstatusPermisosAplicacion(context, payload) {
            try {
                context.commit('setLoading', true)
                const { status, data } = await gitCoreApi.post('configuration/permissions_aplication/update_permissions_aplication',{ 
                        "id": payload.id,
                        "estatus": payload.estatus
                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al cambiar el estatus')
                    context.commit('setLoading', false)
                    throw error
                }
                let mensaje = null
                switch (payload.estatus) {
                    case 1:
                        mensaje = {
                            tipo: 'success',
                            titulo: 'El permiso se ha activado correctamente'
                        }
                        break
                    case 0:
                        mensaje = {
                            tipo: 'success',
                            titulo: 'El permiso se ha desactivado correctamente'
                        }
                        break
                    default:
                        mensaje = {
                            tipo: 'warn',
                            titulo: 'El permiso se ha eliminado'
                        }
                        break
                }
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarPermisosAplicacion', payload)
                }
                return mensaje
    
            } catch (error) {
                console.error(error)
                context.commit('setLoading', false)
                throw error
            }
        }
}