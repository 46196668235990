export default {
  estaCargando(state) {
    return state.estaCargando
  },
  estaCargandoDos(state) {
    return state.estaCargandoDos
  },
  progresoActual(state) {
    return state.progresoActual
  },
  modoProgreso(state) {
    return state.modoProgreso
  },
  mensajeProgreso(state) {
    return state.mensajeProgreso
  },
  rutaActual(state) {
    return state.rutaActual
  },
  getProgresoTareaProgramada(state) {
    return state.progresoTareaProgramada
  }
}