export default {
    getUsuariosUbicacion(state) {
        return state.usuariosUbicacion
    },
    getUsuariosSeguimiento(state) {
        return state.usuariosSeguimiento
    },
    getUsuariosUbicacionFiltradosTabla (state) {
        const { filtros } = state
        // Filtrar usariosUbicacion usando los valores de filtros
        return state.usuariosUbicacion.filter(usuario => {
            if (filtros.tipoUsuario.length && !filtros.tipoUsuario.includes(usuario.tipoUsuario)) return false
            if (filtros.usuariosMostrar === 'Hoy' && !usuario.esDeHoy) return false
            return true
        })
    },
    getUsuariosUbicacionFiltradosMapa (state, getters) {
        const { filtros } = state
        // Filtrar usariosUbicacion usando los valores de filtros
        if (filtros.verSeguimiento && state.usuariosSeguimiento.length > 0) {
            return state.usuariosUbicacion.filter(usuario => state.usuariosSeguimiento.includes(usuario.id))
        } else {
            return getters.getUsuariosUbicacionFiltradosTabla
        }
    },
    getLoadingUsuarios(state) { return state.loadingUsuarios },
    getLoadingUbicacion(state) { return state.loadingUbicacion },
    getLoadingSeguimiento(state) { return state.loadingSeguimiento },
    getFiltros(state) { return state.filtros }
}