
export default class Location {
    constructor (id, longitud, latitud, tipoUbicacionRuta = 'OffRoadRouteLocation') {
        this.id = id
        const coordinate = tipoUbicacionRuta === 'OffRoadRouteLocation' 
        ? {
            offRoadCoordinate: {
                x: longitud,
                y: latitud,
            },
        } 
        : {
            coordinate: {
                x: longitud,
                y: latitud,
            },
        }
        this.routeLocation = {
            $type: tipoUbicacionRuta,
            ...coordinate
        }
    }
}