export default {
    getZonasMonitoreo(state) {
        return state.zonasMonitoreo
    },
    getRutasMonitoreo(state) {
        return state.rutasMonitoreo
    },
    getTareasMonitoreo(state) {
        return state.tareasMonitoreo
    },
    getZonaSeleccionadaMonitoreo(state) {
        return state.zonaSeleccionadaMonitoreo
    },
    getResumenMonitoreo(state) {
        return state.resumenMonitoreo
    },
    getTiposTrabajoMonitoreo(state) {
        return state.tiposTrabajoMonitoreo
    },
    getOrdenesMonitoreo(state){
        return state.ordenesMonitoreo
    },
    getListarLog(state){
        return state.listarLog
    },
    getDataConfirmacion(state){
        return state.dataConfirmacion
    }
}