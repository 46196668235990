export default {
    async setFecha(state, payload) {
        state.fecha = payload
    },
    async setConfigurationActiveXcaret(state, payload) {
        state.configurationActiveXcaret = payload
    },
    async setCargaSeleccionadaXcaret(state, payload) {
        state.setCargaSeleccionadaXcaret = payload
    },
}

