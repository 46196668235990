import { createStore, createLogger } from 'vuex'
// Módulos
import rootModule from './root/index.js'
import authModule from './auth/index.js'
import obtenerConfModule from './obtenerConf/index.js'
import subirArchivo from '@/modules/gitLogistics/cargaArchivo/store/index.js'
import xDima from '@/modules/gitLogistics/xDima/store/index.js'
import gitCluster from '@/modules/gitLogistics/gitCluster/store/index.js'
import gitTourGeneral from '@/modules/gitLogistics/gitTour/store/general/index.js'
import gitTourModificado from '@/modules/gitLogistics/gitTour/store/modificado/index.js'
import gitVisits from '@/modules/gitLogistics/gitVisits/store/index.js'
import eventosLogisticos from '@/modules/seguimientoEventosLogisticos/store/index.js'
import planeacionGitLogistics from './planeacionGitLogistics/index.js'
import dashboardGitLogistics from '@/modules/dashboardGitLogistics/store/index.js'
import configuracionAlmacen from '@/modules/configuracion/almacenes/store/index.js'
import configuracionColoniasCps from '@/modules/configuracion/coloniasCps/store/index.js'
import configuracionActividadesTrabajo from '@/modules/configuracion/tiposTrabajo/store/index.js'
import configuracionZonas from '@/modules/configuracion/zonas/store/index.js'
import configuracionTareas from '@/modules/configuracion/tareas/store/index.js'
import configuracionTareasApp from '@/modules/configuracion/tareasApp/store/index.js'
import monitorRuteo from '@/modules/monitorRuteo/store/index.js'
import reasignacion from '@/modules/reasignacionGitLogistics/store/index.js'
import resumen from '@/modules/resumenGitLogistics/store/index.js'
import permisosMenu from '@/modules/configuracion/rolesPermisos/permisosMenu/store/index.js'
import permisosApliacion from '@/modules/configuracion/permisosAplicaciones/store/index.js'
import menusSubMenus from '@/modules/configuracion/menusSubMenus/store/index.js'
import usuariosGlobal from '@/modules/configuracion/usuariosGlobal/store/index.js'
import roles from '@/modules/configuracion/rolesPermisos/roles/store/index.js'
import puntos from '@/modules/configuracion/puntos/store/index.js'
import actividades from '@/modules/configuracion/actividades/store/index.js'
import matrizDistancia from '@/modules/matrizDistancia/store/index.js'
import gITXcaretCarga from '@/modules/gITXcaret/carga/store/index.js'
import gITXcaretMonitoreo from '@/modules/gITXcaret/monitoreo/store/index.js'
import gITXcaretRuteo from '@/modules/gITXcaret/ruteo/store/index.js'
import gITXcaret from '@/modules/gITXcaret/store/index.js'
import gITXcaretAjustes from '@/modules/gITXcaret/ajustes/store/index.js'
import gitLogistics from '@/modules/gitLogistics/store/index.js'
import rastreoUsuarios from '@/modules/localizacion/rastreoUsuarios/store/index.js'
import galeria from '../modules/galeria/store/index.js'
import consultaPuntos from '@/modules/consultaPuntos/store/index.js'
import dashboard from '@/modules/gITXcaret/dashboard/store/index.js'
import seguimientoEstatus from '@/modules/seguimientoEstatus/store/index.js'
import seguimientoEstatusUsuarios from '@/modules/seguimientoEstatus/SeguimientoUsuarios/store/index.js'
import seguimientoEstatusPuntos from '@/modules/seguimientoEstatus/SeguimientoPuntos/store/index.js'
import reportes from '@/modules/gitLogistics/reportes/store'
import geocercas from '@/modules/configuracion/geocercas/store/index'
import monitoreoUsuarios from '../modules/MonitoreoUsuarios/store'
import consultaRespuestas from '@/modules/consultaRespuestas/store/index.js'
import empresasTelematics from '@/modules/empresasTelematics/store/index.js'
import sucursales from '@/modules/configuracion/sucursales/store/index.js'
import ptvPaquetes from '@/modules/configuracion/ptvPaquetes/store/index.js'
import ptvTipoServicio from '../modules/configuracion/ptvTipoServicio/store/index.js'
import kpisAlertas from '@/modules/kpisAlertas/store/index.js'
import gitRouteVehiculos from '@/modules/gitLogistics/gitRoute/vehiculos/store/index.js'
import gitRouteCargaArchivo from '@/modules/gitLogistics/gitRoute/cargaArchivo/store/index.js'
import gitRouteVistaPrevia from '@/modules/gitLogistics/gitRoute/vistaPrevia/store/index.js'
import auditoriaUsuarios from '@/modules/usuarios/store/index.js'
import calendarView from '@/modules/calendar/store/index.js'
import gitRouteRuteo from '@/modules/gitLogistics/gitRoute/ruteo/store/index.js'
import limiteContenido from '@/modules/configuracion/limiteContenido/store/index.js'
import preguntas from '@/modules/configuracion/preguntas/store/index.js'
import respuestas from '@/modules/configuracion/respuestas/store/index.js'
import gitRoute from '@/modules/gitLogistics/gitRoute/store/index.js'

const debug = false

const store = createStore({
    modules: {
        root: rootModule,
        auth: authModule,
        obtenerConf: obtenerConfModule,
        subirArchivo: subirArchivo,
        xDima: xDima,
        gitCluster: gitCluster,
        gitTourGeneral: gitTourGeneral,
        gitTourModificado: gitTourModificado,
        gitVisits: gitVisits,
        eventosLogisticos: eventosLogisticos,
        planeacionGitLogistics: planeacionGitLogistics,
        dashboardGitLogistics: dashboardGitLogistics,
        monitorRuteo: monitorRuteo,
        configuracionAlmacen: configuracionAlmacen,
        configuracionColoniasCps: configuracionColoniasCps,
        configuracionActividadesTrabajo: configuracionActividadesTrabajo,
        configuracionZonas: configuracionZonas,
        configuracionTareas: configuracionTareas,
        configuracionTareasApp: configuracionTareasApp,
        reasignacion: reasignacion,
        resumen: resumen,
        permisosMenu: permisosMenu,
        permisosApliacion: permisosApliacion,
        menusSubMenus: menusSubMenus,
        usuariosglobal: usuariosGlobal,
        roles: roles,
        puntos: puntos,
        actividades:actividades,
        matrizDistancia,
        gITXcaretCarga,
        gITXcaretMonitoreo,
        gITXcaretRuteo: gITXcaretRuteo,
        gitLogistics,
        gITXcaret,
        gITXcaretAjustes,
        rastreoUsuarios: rastreoUsuarios,
        consultaPuntos,
        dashboard,
        seguimientoEstatus,
        reportes,
        seguimientoEstatusUsuarios,
        seguimientoEstatusPuntos,
        galeria: galeria,
        geocercas,
        monitoreoUsuarios,
        consultaRespuestas,
        empresasTelematics,
        sucursales,
        ptvPaquetes,
        ptvTipoServicio,
        kpisAlertas,
        gitRouteVehiculos,
        gitRouteCargaArchivo,
        auditoriaUsuarios,
        calendar: calendarView,
        gitRouteVistaPrevia,
        gitRouteRuteo,
        limiteContenido,
        preguntas,
        respuestas,
        gitRoute,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

export default store