<template>
    <!-- <LoaderEngine v-show="estaCargando"/> -->
    <!-- <BlockUI :blocked="isLoading" fullScreen /> -->

    <teleport to="body">
        <div v-if="estaCargando" class="custom-blockui-overlay">
            <LoaderEngine v-show="estaCargando"/>
        </div>
    </teleport>

    <BlockUI :blocked="estaCargandoDos" fullScreen /> 
    <Toast />
    <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Toast from 'primevue/toast'
import BlockUI from 'primevue/blockui'
import { io } from "socket.io-client"
export default {
    name: 'App',
    data() {
        return {
            isLoading: false
        }
    },
    components: {
        Toast,
        BlockUI,
        LoaderEngine: defineAsyncComponent(() => import('./components/loaders/LoaderEngine'))
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'estaCargando', 'geotabDatabase', 'userEmail', 'estaCargandoDos']),
        socket () {
            return io('https://gl-geotab-gateway.herokuapp.com/', { autoConnect: false })
        }
    },
    methods: {
        ...mapActions(['changeUserDataAccess'])
    },
    watch: {
        isAuthenticated(newStatus) {
            if (newStatus === false) {
                this.$toast.add({severity:'info', summary: 'Cerrando sesión...', detail:'Serás redirigido al login', life: 1500})
                this.socket.disconnect()
                setTimeout(() => this.$router.replace({ name: 'login'}), 1500)
            }
        },
        estaCargando(val){
            if(this.isLoading !== val){
                this.isLoading = val
            }
        }
    },
    created() {
        if (this.isAuthenticated) {
          // this.socket.connect();
        }

        this.socket.on("connect", () => {
            console.log(this.socket.id)
            this.socket.emit('pairRequest', { clientId: this.socket.id, database: this.geotabDatabase, user: this.userEmail })
        })

        this.socket.on("filter", data => {
            this.changeUserDataAccess(data)
        })

        this.socket.on("disconnect", (reason) => {
            console.log("You've been disconnected from ws server... \n", reason)
        })
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
    .custom-blockui-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
</style>

