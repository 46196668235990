export default {
    getListaPermisos(state) {
        return state.listaPermisos
    },
    getListaPermisosAplicacion(state) {
        return state.listaPermisosAplicacion
    },
    getListaMenuSubMenus(state) {
        return state.listaMenuSubMenus
    },

}