export default {
    setListaPermisos(state, payload) {
        state.listaPermisos = payload
    },
    setListaPermisosAplicacion(state, payload) {
        state.listaPermisosAplicacion = payload
    },
    setListaMenuSubMenus(state, payload) {
        state.listaMenuSubMenus = payload
    },
    
}

