
/* eslint-disable */
import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    /**
     * Devuelve las listas de zonas
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoZonasListar(context, payload) {
        try {
            if (!payload.ocultarLoading) context.commit('setLoading', true)
            const promises = [
                gitCoreApi.post('/configuration/zone/get_zone', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaZonas', response.data.data)
                }),
                gitLogisticsApi.post('/configuracion/cps/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaCps', response.data.response)
                }),
                gitLogisticsApi.post('/configuracion/colonias/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaColonias', response.data.response)
                }),
                gitLogisticsApi.post('/configuracion/almacen/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaAlmacenes', response.data.response)
                }),
                context.dispatch('geocercas/buscarGeocercas', { token: payload.token })
            ]
            await Promise.all(promises)

            if (!payload.ocultarLoading) context.commit('setLoading', false)
        } catch (error) {
            console.error(error)
            if (!payload.ocultarLoading) context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crear una nueva Zona
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoZonasNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.post('/configuration/zone/set_update',
                {
                    id: payload.id,
                    clave: payload.clave,
                    nombre: payload.nombre,
                    almacen: payload.fk_almacen,
                    cp: payload.fk_cp,
                    colonia: payload.fk_colonia,
                    geocerca: payload.fk_geocerca,
                    estatus: payload.estatus,
                    fechaejecucion: payload.fechaejecucion,
                    opciones: payload.opciones,
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Zona')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.message
                }
            } else {
                context.dispatch('catalogoZonasListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.message
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita una Zona
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async eliminarZonaEditar(context,payload){
        try {
            const {status, data} = await gitCoreApi.post('/configuration/zone/eliminarDetalles',{ "id":payload.id},{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Zona')
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    async catalogoZonasEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/zona/editar',
                {
                    "id": payload.id,
                    "clave": payload.clave,
                    "nombre": payload.nombre,
                    "fk_almacen": payload.fk_almacen,
                    "fk_cp": payload.fk_cp,
                    "fk_colonia": payload.fk_colonia,
                    "estatus": payload.estatus,
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Zona')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoZonasListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Cambia el estatus de una Zona
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoZonasCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/zone/delZone',
                {
                    "id": payload.id,
                    "idCompania": payload.fk_compania,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Zona se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Zona se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La Zona se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoZonasListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importZona(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/zone/import_xls_zone', solicitud, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoZonasListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async creaExcelZonas(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/zone/export_xls_zone', {
                getEmpty: payload.getEmpty
            } ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }

            const nombreArchivo = payload.getEmpty
                ? 'Template_zonas'
                : `Exporta_zonas_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data, nombreArchivo, 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },
    async enviarDatosShp(context, payload) {
        try {
            const jsonZonas = JSON.stringify(payload.data)
            const blob = new Blob([jsonZonas], { type: 'application/json' })

            const formdata = new FormData()
            formdata.append('file', blob, 'zonas.json')

            const { data } = await gitCoreApi.post('/configuration/zone/import_json', formdata, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }

            return mensaje
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    },
}