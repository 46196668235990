import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

export default {
  state() {
    return {
      estaCargando: false,
      estaCargandoDos: false,
      progresoActual: 0,
      modoProgreso: 'indeterminate',
      mensajeProgreso: '',
      rutaActual: '',
      progresoTareaProgramada: null
    }
  },
  mutations,
  actions,
  getters,
}
