import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'
import { format } from 'date-fns'

export default {
    async buscarConfPtvTipoServicios(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { status, data } = await gitCoreApi.get(
                `/configuration/ptv_type_service`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            context.commit('setTiposServicios', data)
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            throw new Error(error)
        } finally {
            context.commit('setLoading', false, { root: true })
        }
    },

    async nuevoTipoServicio(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { tipo, tiempoMin, identificador, estatus } = payload.data
            const { data } = await gitCoreApi.post(
                `/configuration/ptv_type_service`,{
                    tipo: tipo,
                    tiempo_min: tiempoMin,
                    identificador: identificador,
                    status: estatus 
                }, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            return {
                tipo: 'success',
                titulo: data.message
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }

            throw new Error(error)
        }
    },

    async actualizarTipoServicio(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const { tipo, tiempoMin, identificador, estatus } = payload.data
            const { data } = await gitCoreApi.patch(
                `/configuration/ptv_type_service/${payload.id}`,{
                    tipo: tipo,
                    tiempo_min: tiempoMin,
                    identificador: identificador,
                    status: estatus 
                }, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )

            // Se valida si se requiere un mensaje de estatus, para los casos de activar, desactivar o eliminar
            if (payload.getMensajeEstatus) {
                switch (estatus) {
                    case 0:
                        return { tipo: 'success', titulo: 'El Tipo de Servicio se ha desactivado correctamente' }
                    case 1:
                        return { tipo: 'success', titulo: 'El Tipo de Servicio se ha activado correctamente' }
                    case 2:
                        return { tipo: 'warn', titulo: 'El Tipo de Servicio se ha eliminado' }
                    default:
                        break
                }
            }

            return {
                tipo: 'success',
                titulo: data.message
            }
        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }

            throw new Error(error)
        }
    },

    async descargarXls(context, payload) {
        try {
            const { data } = await gitCoreApi.get(
                `/configuration/ptv_type_service/create_xls`, {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                    params: { getEmpty: payload.getEmpty }
                }
            )

            const { data_base64, type } = data

            const nombreArchivo = payload.getEmpty
                ? 'Template_Tipos_de_servicio'
                : `Exporta_Tipos_de_servicio_${format(new Date(), 'dd-MM-yyyy')}`

            descargaBaseSesentaycuatro(data_base64, nombreArchivo, type)
        } catch (error) {
            throw new Error(error)
        }
    },
    async importXls(context, payload) {
        context.commit('setLoading', true, { root: true })
        try {
            const solicitud = new FormData()
            solicitud.append('file', payload.file)

            const { data } = await gitCoreApi.post('/configuration/ptv_type_service/import_xls', solicitud ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            return {
                tipo: 'success',
                titulo: data.message
            }

        } catch (error) {
            context.commit('setLoading', false, { root: true })
            const { response } = error
            if (response && response.status === 400) {
                return { tipo: 'error', titulo: response.data.message }
            }
            throw error
        }
    },
}