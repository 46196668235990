import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            userName: null,
            userEmail: null,
            userId: 0,
            companyId: 0,
            token: null,
            tokenExpirationDate: null,
            authState: 'unauthenticated',
            activeGroups: [],
            geotabDatabase: null,
            obtenerMenu:[],
            obtenerPermisos:[],
            obtenerRutaInicio:'resumen',
            obtenerCompanias: [],
            companiaIdSel: 0,
            theme: ''
        }
    },
    mutations,
    actions,
    getters
}