import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            listaZonas: [],
            listaCps:[],
            listaColonias:[],
            listaAlmacenes: [],
        }
    },
    mutations,
    actions,
    getters
}