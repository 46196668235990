export default {
    getListaRoles(state) {
        return state.listaRoles
    },
    getListaRolesTipos(state) {
        return state.listaTipoRoles
    },
    getInfoRol(state) {
        return state.infoRol
    },
    getIsRol(state) {
        return state.isRol
    },

}