import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '../../../helpers/helpers'

export default {
    async obtenDatosKpisAlertas(context, payload) {
        context.commit('setLoading', true)
        const { status, data } = await gitCoreApi.post(
            '/dashboards/kpis_alert',
            {
                dates: payload.dates
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            }
        )

        if (status !== 200) {
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading', false)
            throw error
        }
        context.commit('setLoading', false)
        return data
    },
    async obtenAlertasPorUsuario(context, payload) {
        context.commit('setLoading', true)
        const { status, data } = await gitCoreApi.post(
            '/dashboards/alerts_by_user',
            {
                id: payload.id,
                dates: payload.dates
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            }
        )

        if (status !== 200) {
            const error = new Error(data.message || 'Error de comunicacion')
            context.commit('setLoading', false)
            throw error
        }
        context.commit('setLoading', false)
        return data
    },
    async obtenAlertasPorDia(context, payload) {
        const { status, data } = await gitCoreApi.post(
            '/dashboards/alerts_by_day',
            {
                id: payload.id,
                dates: payload.dates
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            }
        )

        if (status !== 200) {
            const error = new Error(data.message || 'Error de comunicacion')
            // context.commit('setLoading', false)
            throw error
        }
        // context.commit('setLoading', false)
        return data
    },
    async generaExcel(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post(
                '/dashboards/create_excel',
                {
                    dates: payload.dates
                },
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
    
            if (status !== 200) {
                const error = new Error(data.message || 'Error de comunicacion')
                context.commit('setLoading', false)
                throw error
            }
            context.commit('setLoading', false)
            descargaBaseSesentaycuatro(data, 'Reporte_Alertas', 'xlsx')
            return data
        } catch (error) {
            context.commit('setLoading', false)
            throw new Error(error)
        }
    }
}