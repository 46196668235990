import { calcularDistanciaEntreDosPuntos } from '@/helpers/helpers'
/* eslint-disable */
const almacenesUtilizadosEnCarga = (vehiculos) => {
    return [...new Set(vehiculos.map(vehiculo => vehiculo.almacen_comienzo))]
}

const organizarVehiculosPorAlmacen = (vehiculos, almacenes) => {
    const vehiculosOrganizados = {}
    almacenes.map(almacen => (
        vehiculosOrganizados[almacen] = vehiculos.filter(vehiculo => vehiculo.almacen_comienzo === almacen)
    ))
    return vehiculosOrganizados
}

const almacenMasCercanoACluster = async (ubicacionCluster, almacenes) => {
    const coordenadasCluster = { lat: ubicacionCluster.offRoadCoordinate.y, lng: ubicacionCluster.offRoadCoordinate.x }
    const almacenesSinOrdenar = []
    for (const almacen of almacenes) {
        const coordenadasAlmacen = { lat: almacen.latitud, lng: almacen.longitud }
        const distancia = await calcularDistanciaEntreDosPuntos(coordenadasCluster, coordenadasAlmacen)
        almacenesSinOrdenar.push({
            id: almacen.id,
            distancia,
        })
    }
    return almacenesSinOrdenar.sort((a, b) => a.distancia - b.distancia)
    // const almacenesOrdenados = almacenesSinOrdenar.sort((a, b) => a.distancia - b.distancia)
    // const almacenCercano = almacenes.find(almacen => almacen.id == almacenesOrdenados[0].id)
    // return almacenCercano
}

const clusterCercanoAAlmacen = async (almacen, clusters) => {
    if(!almacen){
        return {
            id: clusters[0].id,
            distancia: 0,
            cluster: clusters[0]
        }
    }
    const coordenadasAlmacen = { lat: almacen.latitud, lng: almacen.longitud }
    const clustersSinOrdenar = []
    for(const cluster of clusters) {
        const coordenadasCluster = { lat: cluster.referenceLocation.offRoadCoordinate.y, lng: cluster.referenceLocation.offRoadCoordinate.x }
        const distancia = await calcularDistanciaEntreDosPuntos(coordenadasAlmacen, coordenadasCluster)
        clustersSinOrdenar.push({
            id: cluster.id,
            distancia,
            cluster
        })
    }
    const clustersOrdenados = clustersSinOrdenar.sort((a, b) => a.distancia - b.distancia)
    return clustersOrdenados[0]
}

const asignarVehiculoBasadoEnDistancia = (vehiculosOrganizados, almacenesOrdenados) => {
    for (const almacen of almacenesOrdenados) {
        if (vehiculosOrganizados[almacen.id].length > 0) {
            return vehiculosOrganizados[almacen.id].shift()
        }
    }
}

export {
    almacenesUtilizadosEnCarga,
    organizarVehiculosPorAlmacen,
    almacenMasCercanoACluster,
    asignarVehiculoBasadoEnDistancia,
    clusterCercanoAAlmacen
}