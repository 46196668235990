export default {
  solicitudPlanTour(state){
    return state.solicitudPlanTour
  },
  respuestaPlanTour(state){
    return state.respuestaPlanTour
  },
  mostrarGitTour(state){
    return state.mostrarGitTour
  },
  getErroresgITTour(state){
    return state.erroresgITTour
  }
}