
import xServerApi from '@/api/xServerApi'
import xServerJob from '@/models/xServerJob'
import {format} from 'date-fns'
import {removerElementoArray} from "@/helpers/helpers.js"
import { Buffer } from 'buffer'


let peticionesProceso = 0
/* eslint-disable */
export default {

 
  /**
   * Prepara la peticion hacia los XServer para agregar los puntos que no fueron planificados
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async prepararDatosNoPlanPlanVisitGitTour(context,payload) {
    context.commit('setLoading', true)

    const {puntos,vehiculos,opciones,planVisitsResponse,puntosModificados,vehiculo} = payload

    const dias = opciones.numero_de_semanas * 7
    const fechaInicio = opciones.fecha_inicio
    
    /** Procesado para obtener las visitas a planificar */
    const visitasPlan = []
    const diaActual = Date.parse(fechaInicio)
    planVisitsResponse.forEach(({id,response}) => {
      const clusterId = id
      if(clusterId !== vehiculo){
        return
      }
      /** Iterar por cada dia */
      for(let i = 1; i <= dias; i++){
        const fechaA = format(new Date(diaActual + (i * 24 * 60 * 60 * 1000)), "yyyy-MM-dd")
        const buscarFecha = puntosModificados.find(({fecha}) => fecha === fechaA)
        if(!buscarFecha){
          continue
        }
        const indexFecha = visitasPlan.findIndex(({fecha}) => fecha === fechaA)
        const ordenes = response.visits.map(({visitDate,orderId}) => visitDate === fechaA ? orderId : undefined).filter(Boolean)
        if(indexFecha === -1){
          visitasPlan.push({ fecha: fechaA, visitas: [{ vehiculoId: clusterId , ordenes: ordenes }] })    
        }else{
          visitasPlan[indexFecha].visitas.push({ vehiculoId: clusterId , ordenes: ordenes })
        }
      }
    })

    /** Cambios en la estructura de las visitas */
    puntosModificados.forEach( ({punto,fecha})=>{
      let indexFecha = visitasPlan.findIndex(planVisita => planVisita.fecha === fecha)
      if(indexFecha === -1){
        visitasPlan.push({ fecha: fecha, visitas: [{ vehiculoId: vehiculo , ordenes: [] }] })
        indexFecha = visitasPlan.length - 1
      }
      visitasPlan[indexFecha].visitas[0].ordenes.push(punto)
      /** Quitar orderIdsNotPlanned */
      context.dispatch('eliminarNoPlanificados',{punto:punto})
    })
    
    const peticiones = []

    const modoDistancia = {
      "$type": "DirectDistance"
    }
    const opcionesSol = {
      "restrictions": {
        "maximumTravelTimePerTour": 39600,
        "singleTripPerTour": true
      },
      "orderGroupConsideration": {
        "oneGroupPerTrip": true,
        "oneTripPerGroup": true
      },
      "calculationMode": "STANDARD"
    }

    /** Iterar cada fecha para armar la peticion */
    visitasPlan.forEach(({fecha,visitas}) => {
      const ubicacionesSol = []
      const ordenesSol = []
      const vehiculosSol = []
      const conductoresSol = []

      visitas.forEach(({ vehiculoId, ordenes }) => {
        const {hora_inicio,hora_fin,tolerancia_entre_visita} = vehiculos.find(({id}) => id === vehiculoId)
  
        ordenes.forEach(orden => {
          const {latitud,longitud,tiempo_de_servicio} = puntos.find(punto => punto.id == orden)
          
          const ubicacionSol = {
            "$type": "CustomerSite",
            "id": orden,
            "routeLocation": {
              "$type": "OffRoadRouteLocation",
              "offRoadCoordinate": {
                "y": latitud,
                "x": longitud
              }
            }
          }
          ubicacionesSol.push(ubicacionSol)
    
          const ordenSol = {
            "$type": "VisitOrder",
            "id": orden,
            "locationId": orden,
            "serviceTime": parseInt(tiempo_de_servicio) * 60,
            "groupId": vehiculoId
          }
          ordenesSol.push(ordenSol)
        })
  
        let vehiculoSol = {
          "ids": [vehiculoId],
          "orderGroupIds": [vehiculoId]
        }
        if(tolerancia_entre_visita != ""){
          vehiculoSol["serviceTimePerStop"] = parseInt(tolerancia_entre_visita) * 60
        }
        vehiculosSol.push(vehiculoSol)
  
        const conductorSol = {
          "id": vehiculoId,
          "vehicleId": vehiculoId,
          "operatingIntervals": [{
            "$type": "StartEndInterval",
            "start": fecha + "T" + hora_inicio + ":00-05:00",
            "end": fecha + "T" + hora_fin + ":00-05:00"
          }]
        }
        conductoresSol.push(conductorSol)
      })
  
      const peticion = {
        "locations": ubicacionesSol,
        "orders": ordenesSol,
        "fleet": {
          "vehicles": vehiculosSol,
          "drivers": conductoresSol
        },
        "planToursOptions": opcionesSol,
        "distanceMode": modoDistancia
      }

      if(ordenesSol.length > 0){
        peticiones.push({fecha:fecha,peticion:peticion})
      }
    })

    context.dispatch('startPlanToursMod', peticiones)
  },
  /**
   * Prepara la peticion hacia los XServer para replanificar los dias de la planificacion
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async replanificarDatosNoPlanPlanVisitGitTour(context, payload) {
    context.commit('setLoading', true)

    const { puntos, vehiculos, opciones, planVisitsResponse, diasReplan, vehiculo } = payload

    const dias = opciones.numero_de_semanas * 7
    const fechaInicio = opciones.fecha_inicio

    /** Procesado para obtener las visitas a planificar */
    const visitasPlan = []
    const diaActual = Date.parse(fechaInicio)
    planVisitsResponse.forEach(({ id, response }) => {
      const clusterId = id
      if (clusterId !== vehiculo) {
        return
      }
      /** Iterar por cada dia */
      for (let i = 1; i <= dias; i++) {
        const fechaA = format(new Date(diaActual + (i * 24 * 60 * 60 * 1000)), "yyyy-MM-dd")
        const buscarFecha = diasReplan.find(fecha => fecha === fechaA)
        if (!buscarFecha) {
          continue
        }
        const indexFecha = visitasPlan.findIndex(({ fecha }) => fecha === fechaA)
        const ordenes = response.visits.map(({ visitDate, orderId }) => visitDate === fechaA ? orderId : undefined).filter(Boolean)
        if (indexFecha === -1) {
          visitasPlan.push({ fecha: fechaA, visitas: [{ vehiculoId: clusterId, ordenes: ordenes }] })
        } else {
          visitasPlan[indexFecha].visitas.push({ vehiculoId: clusterId, ordenes: ordenes })
        }
      }
    })
    
    const peticiones = []

    const modoDistancia = {
      "$type": "DirectDistance"
    }
    const opcionesSol = {
      "restrictions": {
        "maximumTravelTimePerTour": 39600,
        "singleTripPerTour": true
      },
      "orderGroupConsideration": {
        "oneGroupPerTrip": true,
        "oneTripPerGroup": true
      },
      "calculationMode": "STANDARD"
    }

    /** Iterar cada fecha para armar la peticion */
    visitasPlan.forEach(({ fecha, visitas }) => {
      const ubicacionesSol = []
      const ordenesSol = []
      const vehiculosSol = []
      const conductoresSol = []

      visitas.forEach(({ vehiculoId, ordenes }) => {
        const { hora_inicio, hora_fin, tolerancia_entre_visita } = vehiculos.find(({ id }) => id === vehiculoId)

        ordenes.forEach(orden => {
          const { latitud, longitud, tiempo_de_servicio } = puntos.find(punto => punto.id == orden)

          const ubicacionSol = {
            "$type": "CustomerSite",
            "id": orden,
            "routeLocation": {
              "$type": "OffRoadRouteLocation",
              "offRoadCoordinate": {
                "y": latitud,
                "x": longitud
              }
            }
          }
          ubicacionesSol.push(ubicacionSol)

          const ordenSol = {
            "$type": "VisitOrder",
            "id": orden,
            "locationId": orden,
            "serviceTime": parseInt(tiempo_de_servicio) * 60,
            "groupId": vehiculoId
          }
          ordenesSol.push(ordenSol)
        })

        let vehiculoSol = {
          "ids": [vehiculoId],
          "orderGroupIds": [vehiculoId]
        }
        if (tolerancia_entre_visita != "") {
          vehiculoSol["serviceTimePerStop"] = parseInt(tolerancia_entre_visita) * 60
        }
        vehiculosSol.push(vehiculoSol)

        const conductorSol = {
          "id": vehiculoId,
          "vehicleId": vehiculoId,
          "operatingIntervals": [{
            "$type": "StartEndInterval",
            "start": fecha + "T" + hora_inicio + ":00-05:00",
            "end": fecha + "T" + hora_fin + ":00-05:00"
          }]
        }
        conductoresSol.push(conductorSol)
      })

      const peticion = {
        "locations": ubicacionesSol,
        "orders": ordenesSol,
        "fleet": {
          "vehicles": vehiculosSol,
          "drivers": conductoresSol
        },
        "planToursOptions": opcionesSol,
        "distanceMode": modoDistancia
      }

      if (ordenesSol.length > 0) {
        peticiones.push({ fecha: fecha, peticion: peticion })
      }
    })
    
    context.dispatch('startPlanToursMod', peticiones)
  },
  /** 
   * Inicia la peticion hacia los XServer
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
   */
  async startPlanToursMod( context, payload) {
    try {
      //limpiar estado peticiones y respuestas
      context.commit('setMostrarGitTourMod', false)
      context.commit('setRequestPlanTourMod', payload)
      context.commit('setResponsePlanTourMod', [])
      //Iteramos todas la peticiones
      context.dispatch('eachPeticionesToursMod', {index: 0, peticiones: payload})
      
    } catch (error) {
      console.error(error);
    }
  },
  /**
   * Itera las peticiones hacia los XServer
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async eachPeticionesToursMod(context, payload) {
    const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
    let {index,peticiones} = payload
    if(peticionesProceso >= 15 ) {
      setTimeout( function(){
          context.dispatch('eachPeticionesToursMod', { index: index, peticiones: peticiones })
        },
        2000
      )
      return
    }
    try {
      if(index < peticiones.length){
        const {peticion,fecha} = peticiones[index]
        const { status, data } = await xServerApi.post('XTour/2.31/startPlanTours', peticion, {
          headers: { 
            'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
            'Content-Type': 'application/json'
          }
        })

        if (status !== 200) {
          throw new Error(data.error || data)
        }
        
        //Revisar status
        const job = new xServerJob('tourMod', fecha)
        job.statusJob(data)

        //Iteramos las peticiones restantes
        index++
        peticionesProceso++
        await context.dispatch('eachPeticionesToursMod',{index: index, peticiones: peticiones})
      }
    } catch (error) {
      console.error(error);
    }
  },
  /**
   * Se guarda la informacion en el store
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async setPlanTourRequestMod(context,payload) {
    peticionesProceso--
    context.commit('setAddResponsePlanTourMod', payload)
    const requestPlanTourMod = context.getters.requestPlanTourMod
    const responsePlanTourMod = context.getters.responsePlanTourMod
    if(requestPlanTourMod.length === responsePlanTourMod.length){
      // context.dispatch('modificarPlanTours',{responsePlanTourMod: responsePlanTourMod})
      
      context.commit('setLoading', false)
      context.commit('setMostrarGitTourMod', true)
    }
  },
  /**
   * Se guarda la informacion modificada en el store
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async modificarPlanTours(context, payload) {
    const {responsePlanTourMod} = payload
    let responsePlanTour = JSON.parse(JSON.stringify(context.getters.respuestaPlanTour))
    responsePlanTourMod.forEach(({id,response}) => {
      if(!Object.prototype.hasOwnProperty.call(response, "tourReports")) return
      const indexPlanTour = responsePlanTour.findIndex(resp => resp.id === id)
      if(indexPlanTour === -1) return

      const indexResponse = responsePlanTour[indexPlanTour].response.tourReports.findIndex(resp => resp.vehicleId === response.tourReports[0].vehicleId)
      if(indexResponse === -1) return

      responsePlanTour[indexPlanTour].response.tourReports[indexResponse] = response.tourReports[0]

      if(Object.prototype.hasOwnProperty.call(response, "orderIdsNotPlanned")){
        if(!Object.prototype.hasOwnProperty.call(responsePlanTour[indexPlanTour].response, "orderIdsNotPlanned")){
          responsePlanTour[indexPlanTour].response["orderIdsNotPlanned"] = []
        }
        response.orderIdsNotPlanned.forEach(orderId => {
          responsePlanTour[indexPlanTour].response["orderIdsNotPlanned"].push(orderId)
        })
      }

      
    })
    context.commit('setRespuestaPlanTour', responsePlanTour)
  },
  /**
   * Limpiar el planTour modificado en el store
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  limpiarPlanTourMod(context) {
    context.commit('setRequestPlanTourMod', [])
    context.commit('setResponsePlanTourMod', [])
    context.commit('setMostrarGitTourMod', false)
  },
  /**
   * Se modifica la respuesta del planTour para eliminar los orderIdNotPlanned que se sustituiran por el nuevo ruteo
   * @param {*} context - Métodos y propiedades del store
   * @param {*} payload - Datos para usarse dentro de la función
  */
  async eliminarNoPlanificados(context, payload) {
    let responsePlanTour = JSON.parse(JSON.stringify(context.getters.respuestaPlanTour))
    const {punto} = payload
    let isEliminado = false
    responsePlanTour.forEach((resp,indexResp) => {
      if(isEliminado){
        return
      }
      if(Object.prototype.hasOwnProperty.call(resp.response, "orderIdsNotPlanned")){
        if(resp.response.orderIdsNotPlanned.includes(punto)){
          removerElementoArray(responsePlanTour[indexResp].response.orderIdsNotPlanned,punto)
          isEliminado = true
        }
      }
    })
    context.commit('setRespuestaPlanTour', responsePlanTour)
  }
}