// usuarios gps[visible - 1] o moviles[fkmobil != null], activos o inactivos [con posicion el dia de hoy]
// hombre, mujer y tecnico/promotor
// gps -> motos, carros y camiones
// movil -> moviles
// default -> icono usuario

export const tiposUsuarios = {
    normal: 'normal',
    movil: 'movil',
    vehiculo: 'vehiculo',
    skypatrol: 'skypatrol',
}

export function tipoUsuarioList () {
    return Object.values(tiposUsuarios)
}

export function definirTipoUsuario(usuario) {
    switch (usuario?.visible) {
        case 0:
            return tiposUsuarios.vehiculo
        case 1:
            return tiposUsuarios.movil
        case 2:
            return tiposUsuarios.skypatrol
        default:
            return tiposUsuarios.normal
    }
}