import actions from './actions'
import mutations from './mutations'
import getters from './getters'
export default {
    state() {
        return {
            routeInfo: {
                ocupation: '-',
                stops: '-',
                distance: '-',
                drivingTime: '-',
                serviceTime: '-'
            },
            stops: [],
            unplanned: [],
            selectedReservations: [],
            selectionMode: false,
            region: ''
        }
    },
    mutations,
    actions,
    getters
}
