export default {
    getActividades(state) {
        return state.actividades
    },
    getPreguntas(state) {
        return state.preguntas
    },
    getRespuestas(state) {
        return state.respuestas
    },
    getListadoRespuestas(state) {
        return state.listadoRespuestas
    },
    getLoadingRespuestas(state) {
        return state.loadingRespuestas
    }
}
