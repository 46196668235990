import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
          requestPlanTourMod: [],
          responsePlanTourMod: [],
          mostrarGitTourMod: false
        }
    },
    mutations,
    actions,
    getters
}