import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    namespaced: true,
    state() {
        return {
            usuariosUbicacion: [],
            usuariosSeguimiento: [],

            loadingUbicacion: false,
            loadingUsuarios: false,
            loadingSeguimiento: false,

            filtros: {
                verRutas: false,
                verSeguimiento: false,
                tipoUsuario: [],
                usuariosMostrar: 'Hoy',
            }
        }
    },
    mutations,
    actions,
    getters
}