import gitCoreApi from '@/api/gitCoreApi'

export default {
    async getRoutesByHotels(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/report/hotels?date=${payload.date}&region=${payload.region}`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async getVehicles(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/report/all_vehicles`,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async updateRoutesVehicle(context, payload) {
        try {
            const { status, data } = await gitCoreApi.put(
                `xcaret/report/routes_vehicle`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async updateVehicleGuide(context, payload) {
        try {
            const { status, data } = await gitCoreApi.put(
                `xcaret/report/vehicle_guide`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async setVehicleGuide(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post(
                `xcaret/report/vehicle_guide`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async saveObservations(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post(
                `xcaret/report/hotel_observation`,
                payload.body,
                {
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    async getLogsByProcess(context, payload) {
        try {
            const { status, data } = await gitCoreApi.get(
                `xcaret/report/logs`,
                {
                    params: { idProcess: payload.idProcess },
                    headers: {
                        Authorization: `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    },
                }
            )
            if (status !== 200) {
                throw new Error('Failed information, try later')
            }

            // context.commit('setHotelsList', data)
            return data
        } catch (error) {
            throw new Error(error)
        }
    }
}