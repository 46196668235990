import gitCoreApi from '@/api/gitCoreApi'
import { creaObjetoConfiguracionPlaneacion, descargaBaseSesentaycuatro } from '../../../helpers/helpers'
export default {
    async guardaConfiguracion(context, payload) {
        const { obj, token, id, nameOfConfiguration } = payload
        const { status } = await gitCoreApi.post('/gitlogistics/configuration/save', {data: obj, id: id, nameOfConfiguration}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })
        if (status !== 200) {
            throw new Error('!Ups hubo un error')
        }
    },
    async obtenConfiguraciones(context, payload) {
        const { token } = payload
        const { status, data } = await gitCoreApi.post('/gitlogistics/configuration/getConfiguration', {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            throw new Error('!Ups hubo un error')
        }

        const { configurationsResponse, clusters } = data
        context.commit('setGitlogisticsConfiguracionPlaneacion', configurationsResponse)
        context.commit('setGitlogisticsConfiguracionClusters', clusters)

        const existeConfiguracionActiva = configurationsResponse.configurations.find(config => config.configuration.status == 1)

        if (existeConfiguracionActiva) {
            const objectConfiguracionPlaneacion = await creaObjetoConfiguracionPlaneacion({configuracion: existeConfiguracionActiva,
            configuracionCompania: Object.prototype.hasOwnProperty.call(data, 'companyProperties') ? data.companyProperties : []})
            context.dispatch('guardarConfiguracionGITLogistics', objectConfiguracionPlaneacion)
        }
    },
    async cambiaEstatusDeLaConfiguracion(context, payload) {
        const { token, id , statusConfiguracion } = payload
        const { status } = await gitCoreApi.post('/gitlogistics/configuration/apply_configuration', {id: id, status: statusConfiguracion}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status !== 200) {
            throw new Error('!Ups hubo un error')
        }
    },
    async plantillaXls(context, payload){
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('gitlogistics/planing/plantilla', 
            {
                confArchivo:"confEficaciaCarga"
            },
            {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
            if (status !== 200) {
                throw new Error('Failed to logout, try later')
            }
            descargaBaseSesentaycuatro(data.data_base64, data.file_name, 'xlsx' )
        } catch (error) {
            throw new Error(error)
        }finally{
            context.commit('setLoading', false)
        }
    }
}