import xServerApi from '@/api/xServerApi'
import gitCoreApi from '@/api/gitCoreApi'

import Location from '@/models/Location'
import PlanVisitsRequest from '@/models/PlanVisitsRequest'
import VisitOrder from '@/models/VisitOrder'
import xServerJob from '@/models/xServerJob'
import { removerElementoArray, obtenerDiasMes } from '@/helpers/helpers.js'
import { Buffer } from 'buffer'
let peticionesProceso = 0
/* eslint-disable */
export default {
    /**
     * Forma objetos de tipo PlanVisitsRequest, con los que se crean peticiones
     * para iniciar el proceso de planificación
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     * @returns { Promise[] } Peticiones iniciales del plan de visitas
     */
    async startPlanVisits(context, payload) {

        peticionesProceso = 0
        context.commit('setLoading', true)
        context.commit('setMostrarGitVisits', false)
        context.commit('setErroresPlanVisit', [])
        try {
            const { puntos, opciones, diasLaborales } = payload
            if ([puntos, opciones, diasLaborales].includes(null)) {
                throw new Error('No hay suficientes datos')
            }

            return context.dispatch('gITVisitRequest',payload)
        } catch (error) {
            throw new Error(error || 'Something went wrong')
        }
    },


    async gITVisitRequest(context,payload){
        try {
            const { puntos, vehiculos, opciones, xDima, configuracion, clusters, almacenes, token } = payload

            let matrizDistanicia = null
            if(xDima){
                matrizDistanicia = xDima.response.summary.id
            }

            const { status, data } = await gitCoreApi.post('/git_visit/create_request', {
                points: puntos, 
                vehicles: vehiculos, 
                options: opciones,
                warehouses: almacenes,
                clusters: clusters,
                xDima: matrizDistanicia, 
                configuration: configuracion 
            },{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al calcular el plan de visitas')
                throw error
            }

            if(data.visitSpecificDays.length > 0){
                context.commit('setGitVisitResponse', data.visitSpecificDays)
            }

            if(data.jobs.length === 0){
                context.commit('setLoading', false)
                context.commit('setMostrarGitVisits', true)
            }

            return data.jobs
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
        }
    },

    async responsegITVisit(context, payload){
        const { jobs, clusters, token } = payload

        const jobsQuering = jobs.filter(({status}) => status !== 'FAILED2') 

        if(jobsQuering.length !== 0){
            const { status, data } = await gitCoreApi.post('/git_visit/create_response', {
                jobs,
                clusters
            },{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al calcular el plan de visitas')
                throw error
            }
    
            return data.concat(jobs.filter(({status}) => status === 'FAILED2'))
        }else{
            return jobs
        }
    },
    async setGitVisitResponse(context, payload){
        context.commit('setGitVisitResponse', payload)
    },
    mostrargITVisit(context){
        context.commit('setLoading', false)
        context.commit('setMostrarGitVisits', true)
    },
    async planVisitsCluster(context, payload) {
        const planVisitRequests = []
        const { puntos, vehiculos, opciones, diasLaborales, xDima, configuracion, diasNoLaborables, clusters, almacenes } = payload
        const centroidesClusters = context.getters.getCentroidesClusters
        const diasProhibidos = !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasNoLaborables.valor : JSON.parse(localStorage.getItem('configuracionPlaneacion')).forbiddenDates
        const diasMesProhibidos = obtenerDiasMes(opciones.fecha_inicio, opciones.numero_de_semanas, diasProhibidos)

        /** Fecha tal y como se extrae del procesamiento del archivo cargado */
        const fechaInicio = opciones.fecha_inicio

        let distanceMode = {
            $type: 'DirectDistance'
        }

        if(xDima){
            distanceMode = {
                "$type":  "ExistingDistanceMatrix",
                "id" : xDima.response.summary.id
            }
        }

        if ([vehiculos].includes(null)) {
            throw new Error('No hay suficientes datos')
        }

        // Por cada cluster se crea una promesa que contiene una petición hacia la API
        vehiculos.forEach(vehiculo => {
            /** Ubicaciones a visitar */
            const locations = []
            /** Ordenes para planificar */
            const visitOrders = []

            const puntosClusterizados = clusters.filter(cluster => cluster.vehiculo == vehiculo.id)
            if(puntosClusterizados.length == 0) return
            puntosClusterizados.forEach(puntoClusterizado => {
                const informacionPunto = puntos.find(punto => punto.id === puntoClusterizado.codigoPunto && !punto.hasOwnProperty("fechas_especificas"))
                if (informacionPunto) {
                    const { id, longitud, latitud } = informacionPunto
                    locations.push(new Location(id, longitud, latitud))
                    visitOrders.push(
                        new VisitOrder(informacionPunto, diasMesProhibidos)
                    )
                }
            })

            const almacen = almacenes.find( almacenEncontrado => almacenEncontrado.id == vehiculo.almacen_comienzo)
            const centroide = centroidesClusters.find( centro => centro.vehiculo == vehiculo.id)
            
            /** Datos del cluster para el que se llevará a cabo la planeación */
            const fieldWorker = {
                id: vehiculo.id,
                location: {
                    $type: "OffRoadRouteLocation",
                    offRoadCoordinate: {
                        y: centroide.coordenadas.latitud,
                        x: centroide.coordenadas.longitud
                    },
                    sideOfStreetRestriction: "ANY_SIDE"
                }
            }

            if(almacen){
                fieldWorker.location = {
                    $type: "OffRoadRouteLocation",
                    offRoadCoordinate: {
                        y: almacen.latitud,
                        x: almacen.longitud
                    },
                    sideOfStreetRestriction: "ANY_SIDE"
                }
            }

            /** Días laborales */
            const isPassLV = vehiculo.hora_inicio != "" && vehiculo.hora_fin != "" && vehiculo.hora_inicio != null && vehiculo.hora_fin != null
            const isPassS = vehiculo.hora_inicio_sabado != "" && vehiculo.hora_fin_sabado != "" && vehiculo.hora_inicio_sabado != null && vehiculo.hora_fin_sabado != null
            const isPassD = vehiculo.hora_inicio_domingo != "" && vehiculo.hora_fin_domingo != "" && vehiculo.hora_inicio_domingo != null && vehiculo.hora_fin_domingo != null

            let lunes       = false
            let martes      = false
            let miercoles   = false 
            let jueves      = false
            let viernes     = false 
            let sabado      = false 
            let domingo     = false
            if (JSON.parse(localStorage.getItem('configuracionPlaneacion'))) {
                const { workDays } = JSON.parse(localStorage.getItem('configuracionPlaneacion'))
                if(workDays.find(workDay => workDay == 'Lunes'))        lunes       = true
                if(workDays.find(workDay => workDay == 'Martes'))       martes      = true
                if(workDays.find(workDay => workDay == 'Miércoles'))    miercoles   = true
                if(workDays.find(workDay => workDay == 'Jueves'))       jueves      = true
                if(workDays.find(workDay => workDay == 'Viernes'))      viernes     = true
                if(workDays.find(workDay => workDay == 'Sabado'))       sabado      = true
                if(workDays.find(workDay => workDay == 'Domingo'))      domingo     = true
            }
            
            const workDays = {
                monday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.lunes.valor === 'true' && isPassLV         : lunes && isPassLV ,
                tuesday:    !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.martes.valor === 'true' && isPassLV        : martes && isPassLV ,
                wednesday:  !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.miercoles.valor === 'true' && isPassLV     : miercoles && isPassLV ,
                thursday:   !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.jueves.valor === 'true' && isPassLV        : jueves && isPassLV ,
                friday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.viernes.valor === 'true' && isPassLV       : viernes && isPassLV ,
                saturday:   !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.sabado.valor === 'true' && isPassS         : sabado && isPassS ,
                sunday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.domingo.valor === 'true' && isPassD        : domingo && isPassD 
            }

            /** Opciones del plan de visitas */
            let planVisitsOptions = {
                planningPeriod: {
                    startDate: fechaInicio,
                    numberOfWeeks: opciones.numero_de_semanas,
                },
                workDays,
                ignoreDailyImbalanceTolerance: true,
                ignoreWeeklyImbalanceTolerance: true
            }

            if(configuracion.desvalanceDiario < 100){
                planVisitsOptions.ignoreDailyImbalanceTolerance = false
                planVisitsOptions.dailyImbalanceTolerance = configuracion.desvalanceDiario
            }
            if(configuracion.desvalanceSemanal < 100){
                planVisitsOptions.ignoreWeeklyImbalanceTolerance = false
                planVisitsOptions.weeklyImbalanceTolerance = configuracion.desvalanceSemanal
            }

            /** Instancia de la clase PlanVisitsRequest */
            const planVisitsRequest = new PlanVisitsRequest(locations, visitOrders, fieldWorker, planVisitsOptions, distanceMode)

            // Se agrega la instancia al arreglo que contiene las peticiones
            if(locations.length === 0) return
            planVisitRequests.push({ vehiculo: vehiculo.id, peticion: planVisitsRequest })
        })
        if(planVisitRequests.length === 0) {
            if(puntos.length === 0){
                context.commit('setLoading', false)
                context.commit('setMostrarGitVisits', true)
                return {
                    isError: true,
                    message: "No hay puntos para planificar"
                }
            }else{
                context.commit('setLoading', false)
                context.commit('setMostrarGitVisits', true)
                return {
                    isError: false,
                    message: "Se pasará a la siguiente etapa de la planeación"
                }
            }
        }
        context.dispatch('startPlanVisit', planVisitRequests)
    },
    async planVisit(context, payload) {
        const planVisitRequests = []
        const { puntos, vehiculos, opciones, diasLaborales, xDima, configuracion, diasNoLaborables } = payload
        const diasProhibidos = !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasNoLaborables.valor : JSON.parse(localStorage.getItem('configuracionPlaneacion')).forbiddenDates
        const diasMesProhibidos = obtenerDiasMes(opciones.fecha_inicio, opciones.numero_de_semanas, diasProhibidos)

        let distanceMode = {
            $type: 'DirectDistance'
        }

        if(xDima){
            distanceMode = {
                "$type":  "ExistingDistanceMatrix",
                "id" : xDima.response.summary.id
            }
        }

        /** Fecha tal y como se extrae del procesamiento del archivo cargado */
        const fechaInicio = opciones.fecha_inicio

        let vehiculoId = "null"
        if (vehiculos.length > 0) {
            vehiculoId = vehiculos[0].id
        }
        // Caso donde se considera únicamente la información proveniente del archivo
        /** Ubicaciones a visitar */
        const locations = []
        /** Ordenes para planificar */
        const visitOrders = []
        // Se crean las ubicaciones y ordenes con base en los puntos obtenidos de la carga del archivo
        puntos.forEach(punto => {
            const { id, longitud, latitud, ritmo_por_semana, frecuencia_a_la_semana, tiempo_de_servicio, semana_de_la_primera_visita, semana_de_la_ultima_visita, fechas_especificas } = punto
            if(fechas_especificas) return

            locations.push(new Location(id, longitud, latitud))
            visitOrders.push(
                new VisitOrder(punto, diasMesProhibidos)
            )
        })

        /** Datos del vehículo para el que se llevará a cabo la planeación */
        const fieldWorker = {
            id: vehiculoId,
            location: {
                $type: 'OffRoadRouteLocation',
                offRoadCoordinate: {
                    x: puntos[0].longitud,
                    y: puntos[0].latitud,
                },
            }
        }

        /** Días laborales */
        const isPassLV = vehiculos[0].hora_inicio != "" && vehiculos[0].hora_fin != "" && !vehiculos[0].hora_inicio && !vehiculos[0].hora_fin
        const isPassS = vehiculos[0].hora_inicio_sabado != "" && vehiculos[0].hora_fin_sabado != "" && !vehiculos[0].hora_inicio_sabado && !vehiculos[0].hora_fin_sabado
        const isPassD = vehiculos[0].hora_inicio_domingo != "" && vehiculos[0].hora_fin_domingo != "" && !vehiculos[0].hora_inicio_domingo && !vehiculos[0].hora_fin_domingo

        let lunes, martes, miercoles, jueves, viernes, sabado, domingo = false
        if (JSON.parse(localStorage.getItem('configuracionPlaneacion'))) {
            const { workDays } = JSON.parse(localStorage.getItem('configuracionPlaneacion'))
            if(workDays.find(workDay => workDay == 'Lunes'))        lunes       = true
            if(workDays.find(workDay => workDay == 'Martes'))       martes      = true
            if(workDays.find(workDay => workDay == 'Miércoles'))    miercoles   = true
            if(workDays.find(workDay => workDay == 'Jueves'))       jueves      = true
            if(workDays.find(workDay => workDay == 'Viernes'))      viernes     = true
            if(workDays.find(workDay => workDay == 'Sabado'))       sabado      = true
            if(workDays.find(workDay => workDay == 'Domingo'))      domingo     = true
        }

        const workDays = {
            monday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.lunes.valor === 'true' && isPassLV         : lunes && isPassLV,
            tuesday:    !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.martes.valor === 'true' && isPassLV        : martes && isPassLV,
            wednesday:  !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.miercoles.valor === 'true' && isPassLV     : miercoles && isPassLV,
            thursday:   !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.jueves.valor === 'true' && isPassLV        : jueves && isPassLV,
            friday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.viernes.valor === 'true' && isPassLV       : viernes && isPassLV,
            saturday:   !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.sabado.valor === 'true' && isPassS         : sabado && isPassS,
            sunday:     !JSON.parse(localStorage.getItem('configuracionPlaneacion')) ? diasLaborales.domingo.valor === 'true' && isPassD        : domingo && isPassD
        }

        /** Opciones del plan de visitas */
        let planVisitsOptions = {
            planningPeriod: {
                startDate: fechaInicio,
                numberOfWeeks: opciones.numero_de_semanas,
            },
            workDays,
            ignoreDailyImbalanceTolerance: true,
            ignoreWeeklyImbalanceTolerance: true
        }
        
        if(configuracion.desvalanceDiario < 100){
            planVisitsOptions.ignoreDailyImbalanceTolerance = false
            planVisitsOptions.dailyImbalanceTolerance = configuracion.desvalanceDiario
        }
        if(configuracion.desvalanceSemanal < 100){
            planVisitsOptions.ignoreWeeklyImbalanceTolerance = false
            planVisitsOptions.weeklyImbalanceTolerance = configuracion.desvalanceSemanal
        }

        const planVisitsRequest = new PlanVisitsRequest(locations, visitOrders, fieldWorker, planVisitsOptions, distanceMode)
        planVisitRequests.push({ vehiculo: vehiculoId, peticion: planVisitsRequest })
        context.dispatch('startPlanVisit', planVisitRequests)
    },
    /** 
    * Inicia la peticion hacia los XServer
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async startPlanVisit(context, payload) {
        try {
            context.commit('setPlanVisitRequest', payload)
            context.commit('setPlanVisitsResponse', [])
            //Iteramos todas la peticiones
            context.dispatch('eachPeticionesVisit', { index: 0, peticiones: payload })

        } catch (error) {
            console.error(error);
        }
    },
    /**
    * Itera las peticiones hacia los XServer
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async eachPeticionesVisit(context, payload) {
        const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
        let { index, peticiones } = payload
        if (peticionesProceso >= 5) {
            setTimeout(function () {
                context.dispatch('eachPeticionesVisit', { index: index, peticiones: peticiones })
            },
                2000)
            return
        }
        try {
            if (index < peticiones.length) {
                const { peticion, vehiculo } = peticiones[index]
                const { status, data } = await xServerApi.post('XCluster/2.31/startPlanVisits', peticion, {
                    headers: {
                        'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
                        'Content-Type': 'application/json'
                    }
                })

                if (status !== 200) {
                    throw new Error(data.error || data)
                }

                //Revisar status
                const job = new xServerJob('visits', vehiculo)
                await job.statusJob(data)

                //Iteramos las peticiones restantes
                index++
                peticionesProceso++
                await context.dispatch('eachPeticionesVisit', { index: index, peticiones: peticiones })
            }
        } catch (error) {
            console.error(error);
        }
    },
    /**
    * Se guarda la informacion en el store
    * @param {*} context - Métodos y propiedades del store
    * @param {*} payload - Datos para usarse dentro de la función
    */
    async guardarRespuestaPlanVisits(context, payload) {
        peticionesProceso--
        const requestPlanVisits = context.getters.planVisitsRequest
        const erroresPlanVisits = context.getters.getErroresPlanVisit
        const responsePlanVisits = [...context.getters.planVisitsResponse]
        responsePlanVisits.push(payload)
        context.commit('setPlanVisitsResponse', responsePlanVisits)
        if (requestPlanVisits.length === (responsePlanVisits.length + erroresPlanVisits.length)) {
            context.dispatch('agregarEspecificos', responsePlanVisits)
        }else context.commit('setMostrarGitVisits', true)
    },
    async guardarRespuestaPlanVisitsError(context, payload) {
        context.commit('addErroresPlanVisit', payload)
        const requestPlanVisits = context.getters.planVisitsRequest
        const erroresPlanVisits = context.getters.getErroresPlanVisit
        const responsePlanVisits = context.getters.planVisitsResponse
        if (requestPlanVisits.length === (responsePlanVisits.length + erroresPlanVisits.length)) {
            context.dispatch('agregarEspecificos', responsePlanVisits)
        }else context.commit('setMostrarGitVisits', true)
    },
    async agregarEspecificos(context, payload){
        const especificos = context.getters.infoPuntos.filter(punto => punto.hasOwnProperty("fechas_especificas"))
        if (especificos.length > 0) {
            const vehiculos = context.getters.infoVehiculos
            const clusters = context.getters.getRespuestaClusters
            const responsePlanVisits = JSON.parse(JSON.stringify(payload))
            especificos.forEach(especifico => {

                const vehiculoFind = clusters.find(({codigoPunto}) => codigoPunto == especifico.id)
                const vehiculo = vehiculos.find(({id}) => id == vehiculoFind.vehiculo)

                const indexPlanVisit = responsePlanVisits.findIndex(planVisit => planVisit.id === vehiculo.id)
                if (indexPlanVisit == -1) {
                    return
                }
                const fechas = especifico.fechas_especificas.split(",")
                fechas.forEach(fecha => {
                    responsePlanVisits[indexPlanVisit].response.visits.push({
                        orderId: especifico.id,
                        locationId: especifico.tiempo_de_servicio * 60,
                        visitDate: fecha,
                        visitNumber: 0
                    })
                })

            })
            context.commit('setPlanVisitsResponse', responsePlanVisits)
        }
        context.commit('setLoading', false)
        context.commit('setMostrarGitVisits', true)
    },
    /**
     * Crea una petición en forma de promesa que envía los datos que le son indicados como parámetro
     * @param {*} context - Métodos y propiedades del store
     * @param { PlanVisitsRequest } payload - Objeto con los datos de la petición
     */
    async createRequestPromise(context, payload) {
        const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
        return xServerApi.post('XCluster/2.31/startPlanVisits', payload, {
            headers: {
                'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                const requestBase = {
                    id: result.data.id,
                    request: payload,
                    clusterId: payload.fieldWorker.id
                }
                context.commit('setPlanVisitRequest', requestBase)
                const job = new xServerJob('visits', result.data.id)
                return job.statusJob(result.data)
            })
            .catch(error => { throw new Error(error) })
    },
    /**
     * Guarda la respuesta obtenida de PTV y la guarda en el estado de la app
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la acción
     */
    // guardarRespuestaPlanVisits(context, payload) {
    //     context.commit('setPlanVisitsResponse', payload)
    // },
    async cambiarDia(context, { modificaciones, vehiculo }) {
        let planVisitsResponse = JSON.parse(JSON.stringify(context.getters.planVisitsResponse))
        const puntos = context.getters.infoPuntos

        const indexPlaneacion = planVisitsResponse.findIndex(response => response.id === vehiculo)
        let planeacion = planVisitsResponse[indexPlaneacion].response

        modificaciones.forEach(({ idPunto, fecha, agregar }) => {
            if (agregar) {
                const { tiempo_de_servicio } = puntos.find(punto => punto.id === idPunto)
                planeacion.visits.push({ orderId: idPunto, visitDate: fecha, serviceTime: parseInt(tiempo_de_servicio) * 60, visitNumber: 0 })
            } else {
                const planeacionEliminar = planeacion.visits.find(({ orderId, visitDate }) => orderId == idPunto && visitDate == fecha)
                removerElementoArray(planeacion.visits, planeacionEliminar)
            }
        })
        context.commit('setPlanVisitsResponse', planVisitsResponse)

    },
    async isConfiguracion(context, payload){
        let getIsConfiguracion = JSON.parse(JSON.stringify(context.getters.getIsConfiguracion))
        const { isConfiguracion } = payload
        getIsConfiguracion = isConfiguracion
        context.commit('setIsConfiguracion', getIsConfiguracion)
        if (payload.hasOwnProperty('isGitVisits')) context.commit('setIsGitVisits', true)
        else context.commit('setIsGitVisits', false)
    }
}

