export default {
    async setSolicitudPlanTour(state, payload) {
        state.solicitudPlanTour = payload
    },
    async setRespuestaPlanTour(state, payload) {
        state.respuestaPlanTour = payload
    },
    async setAddRespuestaPlanTour(state, payload) {
        state.respuestaPlanTour.push(payload)
    },
    async setMostrarGitTour(state,payload) {
        state.mostrarGitTour = payload
    },
    async setErroresgITTour(state,payload) {
        state.erroresgITTour = payload
    },
    async addErroresgITTour(state,payload) {
        state.erroresgITTour.push(payload)
    }
}