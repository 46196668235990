import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            rastreoUsuarios: [],
            listaUsuariosRol:[],

            loadingListaUsuariosRol: false,
            loadingXlsListaUsuariosRol: false,
        }
    },
    mutations,
    actions,
    getters
}