export default {
    planClusterRequest(state) {
        return state.planClusterRequest
    },
    planClusterResponse(state) {
        return state.planClusterResponse
    },
    clustersProcesados(state) {
        return state.clustersProcesados
    },
    getErroresgITCluster(state) {
        return state.erroresgITCluster
    },
    getRespuestaClusters(state) {
        return state.respuestaClusters
    },
    getCentroidesClusters(state) {
        return state.centroidesClusters
    }
}