import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            listaUsuario: [ { label: 'Todos', id: 0 } ],
            consultaPuntosDatos: {},
            datosPuntoDetalle: {},
            listaUsuarioMovil: {},
            preguntasTiket: [],
            puntosDetalleWebyTabla: {pointDetailsTableValues: [], pointDetailsWebValues:[], pointDetailsWeb: [], pointDetailsTable: []}
        }
    },
    mutations,
    actions,
    getters
}