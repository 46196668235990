import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

export default {
  state() {
    return {
      resultadoPlaneacion: {
        id: null,
        puntos: null,
        tareas: null,
        planCluster: null,
        planVisits: null,
        planTour: null,
      },
      statusProceso: 'incompleto'
    }
  },
  mutations,
  actions,
  getters,
}
