export default {
    getListaRatreoUsuarios(state) {
        return state.rastreoUsuarios
    },
    getListaUsuariosByRol(state) {
        return state.listaUsuariosRol
    },
    getLoadingListaUsuariosRol(state) {
        return state.loadingListaUsuariosRol
    },
    getLoadingXlsListaUsuariosRol(state) {
        return state.loadingXlsListaUsuariosRol
    },
}