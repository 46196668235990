export default {
    setListaTareasApp(state, payload) {
        state.listaTareasApp = payload
    },
    setListaTareasDetails(state, payload) {
        state.listaTareasDetails = payload
    },
    setListaTareasUsuarios(state, payload) {
        state.listaTareasUsuarios = payload
    },
    setListaTareasActividades(state, payload) {
        state.listaTareasActividades = payload
    },
    setListaTareasPuntos(state, payload) {
        state.listaTareasPuntos = payload
    },
}