export default {
    setListaZonas(state, payload) {
        state.listaZonas = payload
    },
    setListaCps(state, payload) {
        state.listaCps = payload
    },
    setListaColonias(state, payload) {
        state.listaColonias = payload
    },
    setListaAlmacenes(state, payload) {
        state.listaAlmacenes = payload
    }
}

