/* eslint-disable */
import gitCoreApi from '@/api/gitCoreApi'
import xServerApi from '@/api/xServerApi'
import xServerJob from '@/models/xServerJob'
import { Buffer } from 'buffer'
export default {
    /**
     *
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async generarXDima(context, payload) {
        try {
            context.commit('setLoading', true)
            const { puntos, almacenes, token, puntosFueraDelPais, segirConElProceso } = payload

            const puntosMap = puntos.map(({ latitud, longitud }) => { return {latitud: parseFloat(latitud), longitud: parseFloat(longitud)} })
            const almacenesMap = almacenes.map(({ latitud, longitud }) => { return {latitud: parseFloat(latitud), longitud: parseFloat(longitud)} })
            const matriz = JSON.parse(localStorage.getItem('configuracionPlaneacion'))
            const { matrizDistacia } = matriz

            const { status: xDimaStatus , data: xDimaData } = await gitCoreApi.post('/gitlogistics/xdima/compare_xdima', {
                points: puntosMap,
                werehouses: almacenesMap,
                storedProfile: matrizDistacia.storedProfile
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })

            if (xDimaStatus !== 200) {
                throw new Error(data.error || data)
            }

            if(xDimaData != ""){
                const { request, response } = xDimaData
                context.commit('setXDimaRequest', request)
                context.commit('setXDimaResponse', response)
                context.commit('setLoading', false)
                context.commit('setProgresoActual', 100)
                return
            }
            
            const ubicaciones = []

            const forEachPuntos = ({latitud,longitud}) => {
                if(ubicaciones.some(({offRoadCoordinate}) => offRoadCoordinate.y == latitud && offRoadCoordinate.x == longitud)) return
                ubicaciones.push({
                    $type: "OffRoadRouteLocation",
                    offRoadCoordinate: {
                        x: parseFloat(longitud),
                        y: parseFloat(latitud)
                    }
                })
            }

            let codigoPaises = []
            let puntosFiltrados = []
            const configuracionPlaneacion = JSON.parse(localStorage.getItem('configuracionPlaneacion'))
            const validacionPorPaieses = !configuracionPlaneacion ? false : configuracionPlaneacion.countryValidation
            if (validacionPorPaieses) {
                if (puntosFueraDelPais.length > 0) {
                    const latitudes = puntosFueraDelPais.map(punto => { return punto.latitud })
                    const longitudes = puntosFueraDelPais.map(punto => { return punto.longitud })
                    puntosFiltrados = puntos.filter(punto => !latitudes.includes(punto.latitud) && !longitudes.includes(punto.longitud))
                } else if(!segirConElProceso) {
                    const configuracionGeneral = JSON.parse(localStorage.getItem('configuracionGral'))
                    if (configuracionPlaneacion) {
                        codigoPaises = configuracionPlaneacion.countries.map(countrie => { return countrie.code })
                    } else if (configuracionGeneral) {
                        const codigosxdima = configuracionGeneral.find(configuracion => configuracion.propiedad == 'codigosXDima')
                        if (codigosxdima && codigosxdima.valor != null && codigosxdima.valor != undefined) {
                            codigoPaises = codigosxdima.valor.split(',')
                        }
                    }
    
                    return await context.dispatch('validaUbicacionPuntos', { codigoPaises, puntos, token })
                }
            }

            if (puntosFueraDelPais.length > 0 && puntosFiltrados.length == 0) {
                context.commit('setLoading', false)
                return { error: true, message: 'No existen puntos para planificar ya que todos están fura del país' }
            }

            if (puntosFiltrados.length > 0) {
                localStorage.setItem('regresaCargaArchvo', false)
                setTimeout(() => {
                    context.commit('setInfoPuntos', { infoPuntos: puntosFiltrados })
                }, 1000);
            }
            const puntosaRecorrer = puntosFiltrados.length > 0 ? puntosFiltrados : puntos

            puntosaRecorrer.forEach(forEachPuntos)
            almacenes.forEach(forEachPuntos)

            const solicitud = {
                startLocations: ubicaciones,
                distanceMatrixOptions: {
                    geographicRestrictions: {
                        allowedCountries: codigoPaises
                    }
                },
                storedProfile: matrizDistacia.storedProfile
            }

            const TOKEN_XSEVER = localStorage.getItem('TOKEN_XSERVER')
            const { status, data } = await xServerApi.post('XDima/2.31/startCreateDistanceMatrix', solicitud, {
                headers: {
                    'Authorization': `Basic ${Buffer.from(`xtok:${TOKEN_XSEVER}`).toString('base64')}`,
                    'Content-Type': 'application/json'
                }
            })

            if (status !== 200) {
                throw new Error(data.error || data)
            }

            context.commit('setXDimaRequest', {
                id: data.id,
                request: solicitud
            })
            const job = new xServerJob('xDima', data.id)
            return job.statusJob(data)

        } catch (error) {
            console.error(error)
        }
    },
    guardarRespuestaXDima(context,payload){
        context.commit('setXDimaResponse', payload)
        context.commit('setLoading', false)
        context.commit('setProgresoActual', 100)
    },
    async guardarXDimaDetail(context,payload){
        const { solicitud, respuesta, puntos, almacenes, fkProceso, token } = payload
        const matriz = JSON.parse(localStorage.getItem('configuracionPlaneacion'))
        const { matrizDistacia } = matriz
        const puntosMap = puntos.map(({ latitud, longitud }) => { 
            return {
                latitud: parseFloat(latitud), 
                longitud: parseFloat(longitud)
            } 
        })
        const almacenesMap = almacenes.map(({ latitud, longitud }) => { 
            return {
                latitud: parseFloat(latitud), 
                longitud: parseFloat(longitud)
            } 
        })

        const { status, data } = await gitCoreApi.post('/gitlogistics/xdima/create_xdima_detail', {
            request: JSON.stringify(solicitud),
            response: JSON.stringify(respuesta),
            points: puntosMap,
            werehouses: almacenesMap,
            fkProcess: fkProceso, 
            storedProfile: matrizDistacia.storedProfile
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })
    },
    async validaUbicacionPuntos(context, payload) {
        const { puntos, codigoPaises, token } = payload
        const { status, data } = await gitCoreApi.post('/gitlogistics/xdima/valid_point_location', {
            points: puntos,
            countryCodes: codigoPaises
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status != 200) return []

        return data
    },
    async obtenerPorcentajeValidacionPorPaises(context, payload) {
        const { id, token } = payload
        const { status, data } = await gitCoreApi.post('/gitlogistics/xdima/get_percent_validation', {
            id
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        })

        if (status != 200) throw new Error('!Ups Algo salio mal')

        return data
    }

}
