
/* eslint-disable */
import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '../../../../api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    /**
     * Devuelve las listas de actividades y tipos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoActividadesTiposListar(context, payload) {
        try {
            context.commit('setLoading', true)
            const promises = [
                gitLogisticsApi.post('/configuracion/actividadesTrabajo/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaActividades', response.data.response)
                }),
                gitLogisticsApi.post('/configuracion/tipoTrabajo/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaTipos', response.data.response)
                })]
            await Promise.all(promises)

            context.commit('setLoading', false)
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crear una nueva Actividad
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoActividadesNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/actividadesTrabajo/nuevo',
                {
                    "id": payload.id,
                    "clave": payload.clave,
                    "descripcion": payload.descripcion,
                    "servicio": payload.servicio,
                    "fk_tipotrabajo": payload.fk_tipotrabajo,
                    "estatus": payload.estatus,
                    "fechaejecucion": payload.fechaejecucion
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Actividad')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita una Actividad
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoActividadesEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/actividadesTrabajo/editar',
                {
                    "id": payload.id,
                    "clave": payload.clave,
                    "descripcion": payload.descripcion,
                    "servicio": payload.servicio,
                    "fk_tipotrabajo": payload.fk_tipotrabajo,
                    "estatus": payload.estatus,
                    "fechaejecucion": payload.fechaejecucion
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar Actividad')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Cambia el estatus de una Actividad
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoActividadesCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitLogisticsApi.post('/configuracion/actividadesTrabajo/cambiarEstatus',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La actividad se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Actividad se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La Actividad se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crea un nuevo Tipo de trabajo
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTiposNuevo(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/tipoTrabajo/nuevo',
                {
                    "clave": payload.clave,
                    "descripcion": payload.descripcion,
                    "estatus": payload.estatus
                }, {

                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar el Tipo de trabajo')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita un Tipo de trabajo
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTiposEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/tipoTrabajo/editar',
                {
                    "id": payload.id,
                    "clave": payload.clave,
                    "descripcion": payload.descripcion,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar el Tipo de trabajo')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Cambiar el estatus de un Tipo de trabajo
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTiposCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/tipoTrabajo/cambiarEstatus',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Tipo de Trabajo se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'El Tipo de Trabajo se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'El Tipo de Trabajo se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoActividadesTiposListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importActividadTrabajo(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/job_activity/import_xls_job_activity', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoActividadesTiposListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importTipoTrabajo(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/job_type/import_xls_type', solicitud,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoActividadesTiposListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async creaExcelTiposDeTrabajo(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/job_type/export_xls_job_type', {} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            descargaBaseSesentaycuatro(data, 'Tipos_Trabajo', 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },
    async creaExcelActividades(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/job_activity/export_xls_job_activity', {} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            descargaBaseSesentaycuatro(data, 'Actividades_Trabajo', 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    }
}