export default {
    getCalendarEvents(state) {
        return state.calendarEvents
    },
    getWorkloadName(state) {
        return state.workload_name
    },
    getUsername(state) {
        return state.username
    },
    getIsLoading(state) {
        return state.isLoading
    }
}