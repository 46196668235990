import gitCoreApi from '@/api/gitCoreApi'

export default {
    async buscarLimitesContenido(context, payload) {
        try {
            const { data } = await gitCoreApi.get(
                '/content_limit',
                { headers: { Authorization: `Bearer ${payload.token}`, 'Content-Type': 'application/json' } }
            )

            context.commit('setLimitesContenido', data)
        } catch (error) {
            throw new Error(error)
        }
    },
}