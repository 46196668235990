
/* eslint-disable */
import gitLogisticsApi from '@/api/gitLogisticsApi'
import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {
    /**
     * Devuelve las listas de tareas
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTareasListar(context, payload) {
        try {
            context.commit('setLoading', true)
            const promises = [
                gitCoreApi.post('/configuration/zone/get_zones_list', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaZonas', response.data.data)
                }),
                gitLogisticsApi.post('/configuracion/almacen/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaAlmacenes', response.data.response)
                }),
                gitLogisticsApi.post('/configuracion/tipoTrabajo/listado', {}, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                }).then(response => {
                    context.commit('setListaTipos', response.data.response);
                    gitCoreApi.post('/configuration/task/get_task', {token:payload.token}, {
                        headers: {
                            'Authorization': `Bearer ${payload.token}`,
                            'Content-type': 'application/json'
                        }
                    }).then(response => {
                        context.commit('setListaTareas', response.data.data)
                    })
                })
            ]
            await Promise.all(promises)

            context.commit('setLoading', false)
        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    /**
     * Crear una nueva Tareas
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTareasNuevo(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitCoreApi.post('/configuration/task/set_update',
                {
                    acceso: payload.acceso,
                    almacen: payload.almacen,
                    descripcion: payload.descripcion,
                    estatus: payload.estatus,
                    fkCompany: payload.fkCompany,
                    hora_fin: payload.hora_fin,
                    hora_inicio: payload.hora_inicio,
                    id: payload.id,
                    nombre: payload.nombre,
                    tipos_trabajo: payload.tipos_trabajo,
                    zonas: payload.zonas,
                    areas: payload.areas,

                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al guardar la nueva Tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoTareasListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Edita una Tareas
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTareasEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitLogisticsApi.post('/configuracion/tareas/editar',
                {
                    id: payload.id,
                    nombre: payload.nombre,
                    descripcion: payload.descripcion,
                    acceso: payload.acceso,
                    fechacreacion: payload.fechacreacion,
                    estatus: payload.estatus,
                    orden: "",
                    interseccion: "",
                    visible: 1,
                    filtrable: "",
                    desasignacion: "",
                    detalles: [
                        {
                            nombreDetalle: "Hora inicio",
                            detalle: payload.hora_inicio
                        },
                        {
                            nombreDetalle: "Hora fin",
                            detalle: payload.hora_fin
                        },
                        {
                            nombreDetalle: "Zonas",
                            detalle: payload.zonas
                        },
                        {
                            nombreDetalle: "Almacen",
                            detalle: payload.almacen
                        }
                    ]
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar tarea')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoTareasListar', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },
    /**
     * Cambia el estatus de una Tareas
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async catalogoTareasCambiarEstatus(context, payload) {
        try {
            context.commit('setLoading', true)
            const { status, data } = await gitLogisticsApi.post('/configuracion/tareas/cambiarEstatus',
                {
                    "id": payload.id,
                    "estatus": payload.estatus
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al cambiar el estatus')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = null
            switch (payload.estatus) {
                case 0:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Tarea se ha desactivado correctamente'
                    }
                    break
                case 1:
                    mensaje = {
                        tipo: 'success',
                        titulo: 'La Tarea se ha activado correctamente'
                    }
                    break
                default:
                    mensaje = {
                        tipo: 'warn',
                        titulo: 'La Tarea se ha eliminado'
                    }
                    break
            }
            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('catalogoTareasListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async importTarea(context, payload) {
        try {
            context.commit('setLoading', true)
            const solicitud = new FormData()
            solicitud.append('file', payload.file)
            solicitud.append('fkCompany', payload.fkCompany)
            solicitud.append('user', payload.user)
            const { status, data } = await gitCoreApi.post('/configuration/task/import_xls_task', solicitud,{
                headers: {
                'Authorization': `Bearer ${payload.token}`,
                'Content-type': 'multipart/form-data'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al subir archivo')
                context.commit('setLoading', false)
                throw error
            }
            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            }
            if (!data.isError) {
                context.dispatch('catalogoTareasListar', payload)
            }
            return mensaje

        } catch (error) {
            console.error(error)
            context.commit('setLoading', false)
            throw error
        }
    },
    async creaExcelTareas(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/task/export_xls_task', {} ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }
    
            descargaBaseSesentaycuatro(data, 'Tareas', 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    },
    async creaExcelTareasAcceso(context, payload) {
        try {
            const { status, data } = await gitCoreApi.post('configuration/task/export_xls_task_acceso', {
                acceso: payload.acceso
            } ,{
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })
    
            if (status !== 200) {
                throw new Error(data.message || 'Error al descargar archivo')
            }

            let nombreArchivo = ''
            switch (payload.acceso) {
                case 2:
                    nombreArchivo = 'Tareas_acceso_Vehiculo'
                    break;
                case 0:
                    nombreArchivo = 'Tareas_acceso_Punto'
                    break;
                case 1:
                    nombreArchivo = 'Tareas_acceso_Actividad'
                    break;
                default:
                    nombreArchivo = 'Tareas_acceso_Vehiculo'
                    break;
            }
    
            descargaBaseSesentaycuatro(data, nombreArchivo, 'xlsx')
        } catch (error) {
            throw new Error(error)
        }
    }
}