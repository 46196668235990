import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            listaPuntos:[],
            listaDetalles:[],
            listaDetallesCompania:[],
            contadorCuentas:0,
            permisosConfGral: [],
            listaTiposVehiculos:[]
        }
    },
    mutations,
    actions,
    getters
}