import gitCoreApi from '@/api/gitCoreApi';

export default {
    async getCompanies(context, payload) {
        context.commit('setLoading', true);
        try {
            const { status, data } = await gitCoreApi.get('/companies', {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json',
                },
            });

            if (status !== 200) {
                throw new Error('Fallo al obtener información. Intenta más tarde.');
            }

            context.commit('setCompanies', data);
            console.log('Datos de las empresas:', data);
        } catch (error) {
            context.commit('setCompanies', null);
            throw error;
        } finally {
            context.commit('setLoading', false);
        }
    },

    async catalogoEmpresaNueva(context, payload) {
        context.commit('setLoading', true);
        try {
            const { status, data } = await gitCoreApi.post('/companies', {
                "fkestatus": payload.fkestatus,
                "nombre": payload.nombre,
                "direccion": payload.direccion,
                "operacion": payload.operacion,
                "existe_qlik": payload.existe_qlik,
            }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            });

            if (status !== 200) {
                console.error(data);
                const error = new Error(data.message || 'Error al guardar la nueva empresa');
                throw error;
            }

            let mensaje = null;
            let idcompania = null;

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('getCompanies', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
                idcompania = data.idcompania; // Aquí se obtiene el ID de la nueva empresa
            }
            return { mensaje, idcompania }; // Devuelve tanto el mensaje como el ID de la nueva empresa
        } catch (error) {
            context.commit('setLoading', false)
            throw error
        }
    },

    async catalogoEmpresaEditar(context, payload) {
        try {
            context.commit('setLoading', true)

            const { status, data } = await gitCoreApi.put(`/companies/${payload.idcompania}`,
                {
                    "fkestatus": payload.fkestatus,
                    "nombre": payload.nombre,
                    "direccion": payload.direccion,
                    "operacion": payload.operacion,
                    "existe_qlik": payload.existe_qlik,
                }, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            })

            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al editar sub menu')
                context.commit('setLoading', false)
                throw error
            }

            let mensaje = null

            if (data.errores > 0) {
                context.commit('setLoading', false)
                mensaje = {
                    tipo: 'error',
                    titulo: data.mensaje
                }
            } else {
                context.dispatch('getCompanies', payload)
                mensaje = {
                    tipo: 'success',
                    titulo: data.mensaje
                }
            }
            return mensaje

        } catch (error) {
            context.commit('setLoading', false)
            console.error(error)
            throw error
        }
    },

    async eliminarEmpresa(context, payload) {
        try {
            context.commit('setLoading', true);
            const { status } = await gitCoreApi.delete(`/companies/${payload.idcompania}`, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-type': 'application/json'
                }
            });
            if (status >= 200 && status < 300) {
                console.log("Se elimino con éxito")
            }
            await context.dispatch('getCompanies', payload);
        } finally {
            context.commit('setLoading', false);
        }
    },

    async actualizaImagen(context, payload) {
        try {
            context.commit('setLoading', true);

            const formData = new FormData();
            formData.append('image', payload.image);

            //console.log('Store: File:', payload.image);
            //console.log('Store: Payload:', payload);

            const { status, data } = await gitCoreApi.put(`/companies/${payload.companyId}/image`, formData, {
                headers: {
                    'Authorization': `Bearer ${payload.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            //console.log('Response Status:', status);
            //console.log('Response Data:', data);

            if (status !== 200) {
                console.error(data);
                const error = new Error(data.message || 'Error al actualizar la imagen');
                context.commit('setLoading', false);
                throw error;
            }

            let mensaje = {
                tipo: data.isError ? 'error' : 'success',
                titulo: data.message,
                tiempo: data.isError ? 10000 : 1500
            };

            if (!data.isError) {
                await context.dispatch('getCompanies', { token: payload.token });
            }

            context.commit('setLoading', false);
            return mensaje;

        } catch (error) {
            //console.error(error);
            context.commit('setLoading', false);
            //throw error;
        }
    },
    async obtenerInformacionProveedor(context, payload) {
        try {
            console.log("ID recuperado:", payload.companyId);

            const { status, data } = await gitCoreApi.get(`/telematics/company?companyId=${payload.companyId}`, {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json',
                },
            });

            if (status !== 200) {
                throw new Error('Fallo al obtener información. Intenta más tarde.');
            }

            context.commit('setInformationProvider', data);
        } catch (error) {
            //console.log('Hubo un error en la API:', error);
            context.commit('setInformationProvider', null);
            //throw error; 
        }
    },
    async setupCompany(_, payload) {
        try {
            console.log("ID recuperado 2:", payload.companyId);
            const requestData = payload.data
            const { status, data } = await gitCoreApi.post('/telematics/company', requestData, {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-type': 'application/json',
                },
            });
            if (status !== 200) {
                throw new Error('Fallo al obtener información. Intenta más tarde.');
            }
            return data
        } catch (err) {
            console.error(err.message)
        }
    }
};