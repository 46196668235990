import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state() {
        return {
            loadingNoPlanificados: false,
            listaAreasReasignacion: [],
            listaNoPlanificadosReasignacion: [],
            listaRutasReasignacion: [],
            listaVehiculosReasignacion: [],
            cargasReasignacion: [],
            vehiculoReasignacion: null,
            diasModificadosReasignacion: [],
            solicitudPlanTourReasignacion: [],
            respuestaPlanTourReasignacion: [],
            isNecesitaGuardarInformacion: false,
            puntosMarcadosReasignacion: [],
            erroresReasignacion: [],
            listaCodigosPuntoNoPlanificables: [],
            reportesCostoTareas: [],
            almacenesReasignacion: [],
            listaViajesReasignacion: [],
            fechaSinDia: '',
            listaBreak: [],
            insertaBreak: [],
            eliminarBreak: [],
            actualizarBreak: [],
            idEliminarBreak: [],
            idVehiculoSeleccionadoReasignacion: 0,
            terminoAplicarCambios: false,
            semaforoConfiguracion: null
        }
    },
    mutations,
    actions,
    getters
}