import gitCoreApi from '@/api/gitCoreApi'
import { descargaBaseSesentaycuatro } from '@/helpers/helpers.js'

export default {

    /**
     * Devuelve las lista de permisos
     * @param {*} context - Métodos y propiedades del store
     * @param {*} payload - Datos para usarse dentro de la función
     */
    async listarUsuarios(context, payload) {

        try{

            context.commit('setLoading', true)
            context.dispatch('listarRoles', payload)
            const { status, data } = await gitCoreApi.post(`configuration/user/get_list_users`, {},{
                headers: {
                    'Authorization':`Bearer ${payload.token}`,
                    'Content-Type': 'application/json'
                },
            })
             // Se maneja el caso de error en la petición
            if (status !== 200) {
                console.error(data)
                const error = new Error(data.message || 'Error al obtener el listado')
                context.commit('setLoading', false)
                throw error
            }else{
                context.commit('setListaGlobalUsers', data)
            }


        } catch (error) {
            console.error(error)
            throw error
        } finally {
            context.commit('setLoading', false)
        }
    },

    /**
     * Crear una nuevo usuario
     */

        async nuevoUsuario(context, payload) {
            try {
                context.commit('setLoading', true)
    
                const { status, data } = await gitCoreApi.post('configuration/user/insert_user',
                    {
                        "nombre": payload.nombre,
                        "apellidos": payload.apellidos,
                        "nombre_user": payload.nombre_user,
                        "rol_id": payload.rol_id,
                        "fkestatus": payload.fkestatus,
                        "password": payload.nombre_user,
                    }, {
    
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al guardar el nuevo Usuario')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null
    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarUsuarios', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },

        /**
         * Edita un usuario
         */

        async editarUsuario(context, payload) {
            try {
                context.commit('setLoading', true)
    
                const { status, data } = await gitCoreApi.post('configuration/user/update_user',
                    {
                        "id": payload.id,
                        "fkestatus": payload.fkestatus,
                        "nombre_user": payload.nombre_user,
                        "rol_id": payload.rol_id,
                        "password": payload.password,
                        "apellidos": payload.apellidos,
                        "nombre":payload.nombre,

                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al editar Usuario')
                    context.commit('setLoading', false)
                    throw error
                }
    
                let mensaje = null


    
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarUsuarios', payload)
                    mensaje = {
                        tipo: 'success',
                        titulo: data.mensaje
                    }
                }
                return mensaje
    
            } catch (error) {
                context.commit('setLoading', false)
                console.error(error)
                throw error
            }
        },
    
        /**
         * Cambia el estatus de un usuario
         */
    
        async cambiarEstatusUsuario(context, payload) {
            try {
                context.commit('setLoading', true)
                const { status, data } = await gitCoreApi.post('configuration/user/update_user',{ 
                        "id": payload.id,
                        "fkestatus": payload.fkestatus
                    }, {
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al cambiar el estatus')
                    context.commit('setLoading', false)
                    throw error
                }
                let mensaje = null
                switch (payload.fkestatus) {
                    case 1:
                        mensaje = {
                            tipo: 'success',
                            titulo: 'El usuario se ha activado correctamente'
                        }
                        break
                    case 2:
                        mensaje = {
                            tipo: 'success',
                            titulo: 'El usuario se ha desactivado correctamente'
                        }
                        break
                    default:
                        mensaje = {
                            tipo: 'warn',
                            titulo: 'El usuario se ha eliminado'
                        }
                        break
                }
                if (data.errores > 0) {
                    context.commit('setLoading', false)
                    mensaje = {
                        tipo: 'error',
                        titulo: data.mensaje
                    }
                } else {
                    context.dispatch('listarUsuarios', payload)
                }
                return mensaje
    
            } catch (error) {
                console.error(error)
                context.commit('setLoading', false)
                throw error
            }
        },

        async creaExcelUsuarios(context, payload) {
            try {
                const { status, data } = await gitCoreApi.post('configuration/user/export_xls_user', {} ,{
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'application/json'
                    }
                })
        
                if (status !== 200) {
                    throw new Error(data.message || 'Error al subir archivo')
                }
        
                descargaBaseSesentaycuatro(data, 'Usuarios', 'xlsx')
            } catch (error) {
                throw new Error(error)
            }
        },

        async importUsuarios(context, payload) {
            try {
                context.commit('setLoading', true)
                const solicitud = new FormData()
                solicitud.append('file', payload.file)
                solicitud.append('fkCompany', payload.fkCompany)
                solicitud.append('user', payload.user)
    
                const { status, data } = await gitCoreApi.post('/configuration/user/import_xls_user', solicitud,{
                    headers: {
                        'Authorization': `Bearer ${payload.token}`,
                        'Content-type': 'multipart/form-data'
                    }
                })
    
                if (status !== 200) {
                    console.error(data)
                    const error = new Error(data.message || 'Error al subir archivo')
                    context.commit('setLoading', false)
                    throw error
                }
                let mensaje = {
                    tipo: data.isError ? 'error' : 'success',
                    titulo: data.message,
                    tiempo: data.isError ? 10000 : 1500
                }
                return mensaje
    
            } catch (error) {
                console.error(error)
                context.commit('setLoading', false)
                throw error
            }
        },
}
